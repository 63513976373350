import { DateTime } from "luxon"
import RoomMember from "packs/models/RoomMember"
import axios from "axios"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import PossibleDate from "packs/models/PossibleDate"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import NotificationControl from "packs/utils/NotificationControl"
import CalendarTag from "packs/models/CalendarTag"
import RoomSetting from "packs/models/RoomSetting"
import { reactive } from "vue"
import RoomManager from "packs/models/RoomManager"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

interface PossibleDatesManager {
    userInfo: RoomMember
    room: Room
    appo: Appointment
    rs: RoomSetting
    isSuspend: boolean

    possibleDates: PossibleDate[]
    sharedMembers: RoomMember[]
    jpFormats: []
    dailyPossibleDates: any
    mag_dic: { [key: string]: any } // { calendar_names_dic: {string: string}, name: string }
    possible_dates_selectable_mag_dic: { [key: string]: any[] }
    owner_request_num: number
    selectable_mode: boolean
    selectable_mag_dic: { [key: string]: MeetingAttendeesGroup }
    currentPd: PossibleDate
    currentPds: PossibleDate[]
    selectedMag: MeetingAttendeesGroup
    removedPossibleDates: PossibleDate[]
    canFix: boolean

    possibleDatesPerRoom: any

    currentDate: string // 6月29日
    currentDateId: string // "0629"
    currentCalendarDate: PossibleDate
    displayPds: PossibleDate[] // 選択した場合、こちらを表示.
    astag_id: string // このpdmを利用しているastag_id
    months: string[] // 今回取得した年月 ["202103"]
    available_months: string[] // 取得可能な年月 ["202103", "202104"]
    weeks: string[] // 今回取得した週 ["202118"]
    available_weeks: string[] // 取得可能な週 ["202118", "202119"]
    calendarShowType: string // カレンダー表示タイプ monthly / weekly
    conflictsAstag: AvailableScheduleTag
    conflictsCtags: CalendarTag[]
    checkConflicts: boolean
    check_events_info: any
    mouseoverPd: PossibleDate
    displayType: string // suggested: 提案済み, possibles: 候補選択中, fixed: 確定済み, canceled: キャンセル済み
    currentStep: string
    gotoDate: string
    selectedPds: PossibleDate[]
    currentTZDic: any
    rdlog_id: string
    isInstantRoom: boolean
    jpTimezoneValue: string
    sharedCalendarTagsDic: any
    sharedMyAstag: AvailableScheduleTag
    sharedMyAstagId: string
    suggestNow: string
    parg: ParentRoomGroup

    setNew(...args)
    setUserInfo(userInfo: RoomMember)
    resetPdm(): void
    resetInfo(): void
    getPossibleDates(
        room: Room,
        except_self: boolean,
        needRefresh: boolean,
        month?: string,
        checkConflictsAstag?: AvailableScheduleTag,
        checkConflictsCtags?: CalendarTag[],
        guestKey?: string,
        startDate?: string,
        endDate?: string,
        week?: string
    ): Promise<any>
    setParamsFromDic(dic: any, needRefresh: boolean)
    setFromAstagPreview(dic: any, userInfo: RoomMember, needRefresh: boolean)
    getPdsAfterRemoved(): PossibleDate[]
    resetSelectedMag(): void
    checkFixOrNot(): boolean
    checkFixOrNot99flag()
    showSuggestPossibleTable(): boolean
    showCalendarsMenu(): boolean
    didShare(): boolean
    didShareOther(uid: string): boolean
    sharedSelf()
    selectCurrentPd(pd: PossibleDate): void
    selectDate(dateId)
    gotoDateWithId(dateId: string)
    updateDisplayType(type: string)
    updateCurrentStep(step: string)
    getSelectedPossibleDates(astag: AvailableScheduleTag): PossibleDate[]
    updateSelectedPds(pds: PossibleDate[])
    changeTimezone(tzDic: any)
    isJpTimezone(): boolean
    getCalendarShowType(rs: RoomSetting, isSP: boolean): string
    getGettableItems(): any
}
let _PossibleDatesManager: PossibleDatesManager = reactive({
    /**
     * 基本情報
     */

    userInfo: null,
    room: null,
    appo: null,
    rs: null,
    isSuspend: false,

    /**
     * 現在のPDM
     */
    possibleDates: null,
    sharedMembers: null,
    jpFormats: [],
    dailyPossibleDates: null,
    mag_dic: null,
    owner_request_num: -1,
    selectable_mode: false,
    selectable_mag_dic: null,
    possible_dates_selectable_mag_dic: null,

    currentPd: null,
    currentPds: null,
    selectedMag: null,

    // 個別に削除した可能日時.
    removedPossibleDates: [],

    /**
     * モーダルで利用するPDM
     */
    // selectablePossibleDates: null,
    // selectableSharedMembers: null,
    // jpFormats: [],
    // dailyPossibleDates: null,
    // mag_dic: null,

    canFix: false,

    // suggested: 提案済み, possibles: 候補選択中, fixed: 確定済み, canceled: キャンセル済み
    displayType: null,
    suggestNow: null,
    currentStep: null,

    /**
     * Room_idをキーとした、部屋ごとに 以下の情報を持ちます。
     * possibleDates PossibleDateを配列で持ちます,
     * jpFormats 日本語表記の開始~終了時間を記載したものを配列で持ちます,
     * sharedMembers その部屋でシェアしているRoomMemberの情報を配列で持ちます。
     * possibleDatesPerRoom pdm: {possibleDates: PossibleDate[], jpFormats: [], sharedMembers: [RoomMember]}
     */
    possibleDatesPerRoom: {},

    // カレンダーで選択している日付 6月29日（曜日）
    currentDate: null,
    currentDateId: null,
    currentCalendarDate: null,
    // カレンダーで選択している日付の可能時間
    displayPds: null,
    astag_id: null,
    months: [],
    available_months: null,
    weeks: [],
    available_weeks: null,
    calendarShowType: null,

    // カレンダー重ね合わせたときの見せ方
    conflictsAstag: null,
    conflictsCtags: null,
    // 上記のconflictsをチェックするどちらかが入っているときtrue
    checkConflicts: false,
    // 確認しているイベント情報 {astag, ctags, daily_events}
    check_events_info: null,
    mouseoverPd: null,
    gotoDate: null,
    // 提案した日程を保持します.
    selectedPds: null,
    //
    currentTZDic: null,
    // ログID
    rdlog_id: null,
    // すぐインボタンの日程で確定するか.
    isInstantRoom: false,
    jpTimezoneValue: `Japan Standard Time`,
    sharedCalendarTagsDic: {},
    sharedMyAstag: null,
    sharedMyAstagId: null,
    parent_room_group_id: null,
    lp_id: null,
    parg: null,

    setNew(room: Room, userInfo: RoomMember, appo: Appointment, rm: any) {
        Logger(`PossibleDatesManager.setNew room:${room?.id}, userInfo:${userInfo?.user_id}, appo:${appo?.id}`)
        this.room = room
        this.userInfo = userInfo
        this.appo = appo
        this.rs = room.room_setting
        let owner = this.room.owner
        this.isSuspend = owner.group_status == 1100
        if (this.appo.status == 10) {
            this.displayType = `fixed`
        } else if (this.appo.status == -1) {
            this.displayType = `canceled`
        } else if (this.appo.status == 1) {
            this.displayType = `possibles`
        }
        if (rm) {
            this.parent_room_group_id = rm.parent_room_group_id
            this.lp_id = rm.lp_id
            this.parg = rm.parg
        }
    },
    setUserInfo(userInfo: RoomMember): void {
        this.userInfo = userInfo
    },

    resetPdm(): void {
        Logger(`pdm.${funcName()}`)
        this.currentDate = null
        this.currentDateId = null
        // カレンダーで選択している日付の可能時間
        this.displayPds = null
        this.possibleDates = null
        this.sharedMembers = null
        this.jpFormats = []
        this.dailyPossibleDates = null
        this.mag_dic = null
        this.owner_request_num = -1
        this.selectable_mode = false
        this.possible_dates_selectable_mag_dic = null
        this.selectable_mag_dic = null
        this.available_months = null
        this.months = []
        this.available_weeks = null
        this.weeks = []
        this.conflictsAstag = null
        this.conflictsCtags = null
        this.checkConflicts = false
        this.currentPd = null
        this.currentPds = null
        this.currentCalendarDate = null
        this.selectedMag = null
        this.gotoDate = null
        this.sharedMyAstag = null
        this.sharedMyAstagId = null
        this.calendarShowType = null
        this.parent_room_group_id = null
        this.lp_id = null
        this.parg = null
        // this.displayType = null
        // this.room = null
        // this.appo = null
    },
    resetInfo() {
        Logger(`pdm.${funcName()}`)
        this.displayType = null
        this.room = null
        this.appo = null
        this.rs = null
    },

    /**
     * RoomIDを指定してあげて、紐づくastagのpossible_datesなどを取得してきます。
     * @param room [Room.id] ParentRoomのIDを利用します。
     * @param except_self [boolean] 自分を抜いたpdmを取得してきます。
     * @param needRefresh [boolean]
     * @param month [string]
     * @param checkConflictsAstag [AvailableScheduleTag]
     * @param checkConflictsCtags [CalendarTag[]]
     */
    getPossibleDates(
        room: Room,
        except_self: boolean,
        needRefresh: boolean,
        month: string = null,
        checkConflictsAstag: AvailableScheduleTag = null,
        checkConflictsCtags: CalendarTag[] = null,
        guestKey: string = null,
        startDate: string = null,
        endDate: string = null,
        week: string = null
    ): Promise<any> {
        Logger(
            `pdm.getPossibleDates room:${room?.id}, except_self:${except_self}, needRefresh:${needRefresh}, month:${month}, guestKey:${guestKey}, startDate:${startDate}, endDate:${endDate}, week:${week}, checkConflictsAstag:${checkConflictsAstag?.id}`
        )
        let params = { id: room.id, except_self: except_self }
        if (month) {
            params[`months`] = [month]
            params[`calendar_show_type`] = `monthly`
        } else if (week) {
            params[`weeks`] = [week]
            params[`calendar_show_type`] = `weekly`
        }

        if (guestKey) {
            params[`guest_key`] = guestKey
            params[`embeds`] = true
        }

        if (Util.isPresent(startDate) && Util.isPresent(endDate)) {
            params[`start_date`] = startDate
            params[`end_date`] = endDate
        }

        if (checkConflictsAstag) {
            this.conflictsAstag = checkConflictsAstag
        } else if (checkConflictsCtags) {
            this.conflictsCtags = checkConflictsCtags
        }

        if (Util.isPresent(this.conflictsAstag)) {
            params[`check_conflicts_astag_id`] = checkConflictsAstag?.id
            this.checkConflicts = true
        } else if (Util.isPresent(this.conflictsCtags)) {
            params[`check_conflicts_ctags`] = checkConflictsCtags
            this.checkConflicts = true
        }

        Logger(`pdm.getPossibleDates from Remote:::: room.id:${room.id}, room.room_type:${room.room_type}`)
        let path = `rooms`
        if (room.room_type == `public_room`) {
            path = `public_rooms`
        }

        if (this.parent_room_group_id) {
            if (this.parg && this.parg.my_block_type == `block_1`) {
                // params[`my_block_type`] = this.parg.my_block_type
            } else {
                path = `parent_room_groups`
            }

            params[`parent_room_group_id`] = this.parent_room_group_id
            params[`lp_id`] = this.lp_id
        }

        const appo = room.current_appointment
        if (appo.location_tag_id) {
            params[`location_tag_id`] = appo.location_tag_id
        } else if (appo.location_name) {
            params[`location_name`] = appo.location_name
        }

        if (appo.duration) {
            params[`duration`] = appo.duration
        }

        Logger(`pdm.getPossibleDates START params:${Util.output(params)}`)

        return axios
            .post(`${Util.prefixUrl}/${path}/possible_dates`, params)
            .then(result => {
                const possiblesDic = result.data

                if (!possiblesDic) return null
                Logger(
                    `pdm.getPossibleDates ***********get POSSIBLE_DATES currentTZDic:${Util.output(
                        this.currentTZDic
                    )}, this.pdm:${Util.output(possiblesDic)}`
                )

                const { possible_dates, jp_formats } = PossibleDate.fetchFromJson(possiblesDic.possible_dates)
                const mag_dic = possiblesDic.mag_dic as any

                const _members = RoomMember.fetchFromJson(possiblesDic.shared_members)

                this.setParamsFromDic(possiblesDic, needRefresh)
                this.sharedMembers = _members
                this.owner_request_num = possiblesDic.owner_request_num
                this.sharedMyAstag = possiblesDic.shared_my_astag
                if (this.sharedMyAstag) {
                    this.sharedMyAstagId = this.sharedMyAstag.id
                }

                Logger(
                    `pdm.getPossibleDates sharedMyAstag:${this.sharedMyAstag?.id} currentTZDic:${Util.output(this.currentTZDic)}`
                )

                if (this.possibleDates && this.possibleDates.length > 0) {
                    if (this.currentTZDic) {
                        // let tz = { ...this.currentTZDic }
                        // this.currentTZDic = null
                        this.changeTimezone(this.currentTZDic)
                    }
                    // let _first_pd = this.possibleDates[0]
                    this.selectDate(null)
                }

                this.checkFixOrNot()
                Logger(`pdm.getPossibleDates currentTZDic:${Util.output(this.currentTZDic)}`)

                // jp_formatsのみを返します。
                return this.possibleDates
            })
            .catch(err => {
                Logger(`pdm.getPossibleDates err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    },

    /**
     * プレビュー/PossibleDatesで取得したデータをpdm内のフィールドにセットします.
     * @param dic
     * @param needRefresh
     */
    setParamsFromDic(dic: any, needRefresh: boolean) {
        Logger(
            `pdm.setParamsFromDic needRefresh:${needRefresh}, calendar_show_type:${dic?.calendar_show_type}, months:${dic?.months}, weeks:${dic?.weeks}`
        )
        if (needRefresh) {
            this.possibleDates = dic.possible_dates
            this.jpFormats = dic.jp_formats
            this.dailyPossibleDates = dic.daily_possible_dates
            this.months = []
            this.weeks = []
            this.possible_dates_selectable_mag_dic = dic.possible_dates_selectable_mag_dic
        } else {
            let pds = [...(this.possibleDates || [])]
            let starts = pds.map(pd => pd.start_time)
            for (let pd of dic.possible_dates) {
                if (!starts.includes(pd.start_time)) {
                    pds.push(pd)
                }
            }
            // Array.prototype.push.apply(pds, dic.possible_dates || [])
            this.possibleDates = pds

            this.jpFormats = this.jpFormats ? this.jpFormats : []
            Array.prototype.push.apply(this.jpFormats, dic.jp_formats)

            let magDic = { ...this.possible_dates_selectable_mag_dic }
            let newMagDic = dic.possible_dates_selectable_mag_dic

            Object.entries(magDic).forEach(([mag_id, _pds]) => {
                let newPds = _pds || []
                let newMagPds = newMagDic[mag_id] as PossibleDate[]
                Array.prototype.push.apply(newPds, newMagPds)
                magDic[mag_id] = newPds
            })
            this.possible_dates_selectable_mag_dic = magDic

            let _dailyPossibleDates = { ...this.dailyPossibleDates }
            Object.entries(dic.daily_possible_dates).forEach(([datestr, dic]) => {
                _dailyPossibleDates[datestr] = dic
            })
            this.dailyPossibleDates = _dailyPossibleDates
        }

        this.mag_dic = dic.mag_dic
        this.selectable_mode = dic.selectable_mode
        // this.possible_dates_selectable_mag_dic = dic.possible_dates_selectable_mag_dic
        this.selectable_mag_dic = dic.selectable_mag_dic

        if (dic?.calendar_show_type == `weekly`) {
            this.available_weeks = dic.available_weeks
            Array.prototype.push.apply(this.weeks, dic.weeks || [])
        } else {
            this.available_months = dic.available_months
            Array.prototype.push.apply(this.months, dic.months || [])
        }
        Logger(
            `pdm.setParamsFromDic this.possibleDates.length:${(this.possibleDates || []).length}, dic.calendar_show_type:${
                dic.calendar_show_type
            }, this.months:${this.months}, this.weeks:${this.weeks}`
        )

        this.check_events_info = dic.check_events_info
        this.rdlog_id = dic.rdlog_id

        let sharedCtags = dic.shared_calendar_tags || []
        if (Util.isPresent(sharedCtags)) {
            Logger(`${dic.available_time_id} => sharedCtags:${sharedCtags.length}`)
            this.sharedCalendarTagsDic[dic.available_time_id] = sharedCtags
        }
    },
    /**
     * プレビューへ表示用のデータをはめ込みます。
     * @param dic サーバーから取得してきた情報
     * @param userInfo 自分のuserInfoをシェアしたメンバーとして利用
     * @param needRefresh リフレッシュが不要だったら、遅延取得したデータを合算します.リフレッシュする場合はデータを上書きします.
     */
    setFromAstagPreview(dic: any, userInfo: RoomMember, needRefresh: boolean) {
        Logger(`PossibleDatesManager.setFromAstagPreview dic:${Util.output(dic)}, needRefresh:${needRefresh}`)
        this.resetSelectedMag()
        this.sharedMembers = [userInfo]
        this.setParamsFromDic(dic, needRefresh)
        this.astag_id = dic.astag_id

        // オーナーが指定した下限提案数
        this.owner_request_num = dic.owner_request_num as number

        if (this.possibleDates && this.possibleDates.length > 0) {
            // let _pd = this.possibleDates[0]
            let _first_pd = dic.possible_dates[0]
            this.selectDate(_first_pd.date_format)
        }
    },
    resetSelectedMag(): void {
        this.selectedMag = null
    },
    getPdsAfterRemoved(): PossibleDate[] {
        // if (this.currentDate && this.displayPds) {
        //     return this.displayPds
        // }
        let pds = []
        if (this.selectedMag && this.selectedMag.id != `any` && this.possible_dates_selectable_mag_dic) {
            pds = [...(this.possible_dates_selectable_mag_dic[this.selectedMag.id] || [])]
        } else {
            pds = [...(this.possibleDates || [])]
        }

        for (let _pd of this.removedPossibleDates) {
            pds = pds.filter(pd => !PossibleDate.isSame(_pd, pd))
        }
        Logger(
            `${funcName()}: this.selectedMag:${this.selectedMag ? this.selectedMag.id : null}, pds.length:${(pds || []).length}`
        )
        return pds
    },

    /**
     * この調整を確定できるかをチェックします。
     * this.appoにアップデートがあったとき、this.roomに追加されたとき、this.pdmにアップデートがあったときに呼ばれます.
     */
    checkFixOrNot(): boolean {
        /**
         * 状態確認スタート
         * appoとroomがあることが前提です。
         * プレビュー画面の場合と停止中の場合は、状態変更できません。
         */
        if (Util.isBlank(this.appo)) return this.canFix

        if (Util.isPreview()) {
            this.updateDisplayType(`possibles`)
            this.canFix = this.appo.fix_type == 100
            return this.canFix
        }

        if (this.isSuspend) {
            this.canFix = false
            this.updateDisplayType(`suspended`)
            return this.canFix
        }

        if (Util.isBlank(this.room)) return this.canFix
        /**
         * 状態確認ここまで
         */

        /**
         * 公開ページの場合は、ここで決めます.
         */
        if (Util.isPublic()) {
            this.canFix = this.appo.fix_type == 100
            // 組み込みの場合、URLを更新しないため、表示上はPublicRoomとして判断します。
            // そのため、提案後はdisplayTypeは変更しないでおきます.
            if (this.displayType == `suggested` && Util.isEmbeds()) {
                return this.canFix
            }
            this.updateDisplayType(`possibles`)
            return this.canFix
        }

        /**
         * 以下限定公開ページの場合.
         */

        let myMem: RoomMember = null

        if (Util.isBlank(this.room.members)) return this.canFix
        if (!this.userInfo) return this.canFix
        myMem = this.room.members.find(m => m.user_id == (this.userInfo || {}).user_id)

        // リスケが押されている場合は、状態変更せず.
        if (this.displayType == `reschedule`) {
            Logger(`${funcName()} リスケ中のため、状態変更しません`)

            if (this.appo.fix_type == 100 || this.room.is_owner) {
                this.canFix = true
            } else {
                if (myMem) {
                    this.canFix = this.appo.fix_type == 100
                }
                // this.checkFixOrNot99flag()
            }

            return this.canFix
        }

        /**
         * 表示タイプを更新します.
         */
        if (this.appo.status == 10) {
            this.updateDisplayType(`fixed`)
        } else if (this.appo.status == -1) {
            this.updateDisplayType(`canceled`)
        } else if (this.didShare()) {
            if (myMem && !this.room.is_owner && this.appo.fix_type != 100) this.updateDisplayType(`suggested`)
        }

        // 「誰でも」設定の場合 または オーナーの場合 常に確定できる.
        if (this.appo.fix_type == 100 || this.room.is_owner) {
            this.canFix = true
            return true
        }

        Logger(
            `pdm.checkFixOrNot displayType:${this.displayType}, appo.fix_type:${this.appo.fix_type}, myMem.user_id:${myMem?.user_id}, checkFixOrNot1:${this.canFix}, is_owner:${this.room.is_owner}, is_owner_ug:${this.room.is_owner_group}`
        )

        if (myMem) {
            this.canFix = this.appo.fix_type == 100
            return this.canFix
        } else {
            // 同一UGでも、出席者でない場合は、確定できる.
            if (this.room.is_owner_group) {
                this.canFix = true
                this.updateDisplayType(`possibles`)
                return this.canFix
            }
        }

        this.checkFixOrNot99flag()
        Logger(`${funcName()} checkFixOrNot4: ${this.canFix}`)

        this.updateDisplayType(`possibles`)

        return this.canFix
    },
    checkFixOrNot99flag() {
        // 最後のユーザーの場合fix
        if (this.appo.fix_type == 99) {
            Logger(`pdm.${funcName()} fix_type==99`)
            if (!this.possibleDates) {
                this.canFix = false
            }
            const shareNum: number = (this.sharedMembers || []).length
            const allCanShareNum: number = this.room.attendees.length
            // 出席者全員が共有していたら、誰でも確定できる.
            if (allCanShareNum == shareNum) {
                this.canFix = true
            }
            const currentMem = (this.sharedMembers || []).find(m => m.user_id == this.userInfo.user_id)
            Logger(`pdm.${funcName()} attendees:${Util.output(this.room.attendees)}, shareNum:${shareNum}`)
            if (this.room.attendees.length - 1 <= shareNum) {
                // まだシェアしていないユーザーの場合のみ確定できる.
                if (!currentMem) {
                    this.canFix = true
                }
            }
            Logger(`pdm.${funcName()} fix_type 99をでます: canFix:${this.canFix}`)
        }
    },
    showSuggestPossibleTable(): boolean {
        Logger(`pdm.${funcName()} room:${this.room?.id}, roomType:${this.room?.room_type}`)
        if (Util.isPublic() || this.room?.room_type == `public_room`) {
            return this.appo.fix_type != 100
        }

        let myMem: RoomMember = this.room.members.find(m => m.user_id == this.userInfo.user_id)

        // オーナーは確定できます.
        if (this.room?.is_owner) {
            return false
        }

        // 出席メンバーでない人は同一UGまたは確定権限者の可能性があるため、提案モードにせず確定できる状態にしておきます.
        if (!myMem) {
            return false
        }

        // 出席メンバーの場合、fix_typeに従います.
        // return this.appo.fix_type != 100
        this.checkFixOrNot()

        // this.canFix = this.appo.fix_type == 100
        // Logger(`canFix: ${this.canFix}, is_onwer: ${this.room.is_owner}, uf: ${Util.output(this.userInfo)}`)
        // return (
        //     !this.canFix &&
        //     this.appo.status != 10 &&
        //     !this.didShare() &&
        //     !this.room.is_owner &&
        //     this.room.members.some(m => m.user_id == this.userInfo.user_id)
        // )
        // return !this.canFix && !this.room.is_owner && this.room.members.some(m => m.user_id == this.userInfo.user_id)
        return !this.canFix
    },
    showCalendarsMenu(): boolean {
        if (!this.userInfo) return false
        if (!this.appo) return false

        if (Util.isPublic()) return true
        if (![10, -1].includes(this.appo.status)) return false

        if (this.displayType == `reschedule`) return true

        return !this.didShare()
    },
    /**
     * 自分のユーザーが調整カレンダーを共有しているかを返します。
     */
    didShare(): boolean {
        if (!this.userInfo) return false
        if (Util.isPublic()) return false
        // すでに共有済みの場合room情報から確認.
        if (this.room && (this.room.shared_schedule_member_ids || []).includes(this.userInfo.user_id)) {
            Logger(`pdm.${funcName()} PDM:didShare true1`)
            return true
        }
        if (Util.isBlank(this.sharedMembers)) return false
        // if (!pdm) return false
        let mem = this.sharedMembers.find(m => m.user_id == this.userInfo.user_id)
        Logger(`pdm.${funcName()} mem:${mem}`)
        return mem ? true : false
    },
    /**
     * 不参加以外に自分以外の人がカレンダーを共有しているかを返します。
     * @param pdm
     * @param uid
     */
    didShareOther(uid: string): boolean {
        // if (!pdm) return false
        // let sharedMembers: RoomMember[] = this.sharedMembers
        if (!this.sharedMembers || this.sharedMembers.length == 0) return false
        let mem = this.sharedMembers.find(m => m.user_id != uid)
        return mem ? true : false
    },
    /**
     * 自分が提案したときに、リフレッシュせずにsharedMembersに追加して提案済みに変更します。
     */
    sharedSelf() {
        Util.isPresent(this.sharedMembers) ? this.sharedMembers.push(this.userInfo) : (this.sharedMembers = [this.userInfo])
        Logger(`pdm.${funcName()} didShare?:${this.didShare()}`)
    },

    selectCurrentPd(pd: PossibleDate): void {
        this.currentPd = pd
    },
    /**
     * カレンダーのボタンを押下したときに、表示する日付のpdを抽出します。
     * @param dateId [string] 0629
     */
    selectDate(dateId: string) {
        Logger(
            `pdm.${funcName()} dateId:${dateId}, currentDateId:${this.currentDateId}, currentTZDic:${Util.output(
                this.currentTZDic
            )}`
        )
        if (!dateId && !this.currentDateId) return

        if (!dateId && this.currentDateId) dateId = this.currentDateId

        let pds = this.getPdsAfterRemoved()
        Logger(`pdm.${funcName()} 処理前pds(${(pds || []).length}):${Util.output(pds)}`)

        pds = pds.filter(_pd => PossibleDate.isSameDateId(_pd, dateId, this.currentTZDic))

        Logger(`pdm.${funcName()} 処理後pds(${pds.length}):${Util.output(pds)}`)

        // let pds = this.possibleDates.filter(_pd => _pd.date_format == dateId)
        if (pds && pds.length > 0) {
            let pd = pds[0]
            let displayFormat = pd.jp_format.split(`）`)[0] + `）`
            if (Util.isPresent(this.currentTZDic) && pd.tz_format) {
                displayFormat = pd.tz_format.split(`, `)[0]
            }
            this.currentDate = displayFormat
            this.currentDateId = dateId
            this.currentCalendarDate = pd
            for (let pd of pds) {
                let __pd = (this.selectedPds || []).find(selectpd => PossibleDate.isSame(pd, selectpd))
                if (__pd) {
                    pd.selected = true
                } else {
                    pd.selected = false
                }
            }
        }
        Logger(
            `pdm.${funcName()} currentDate:${this.currentDate}, currentDateId:${this.currentDateId}, pds.length:${
                (pds || []).length
            }`
        )
        this.displayPds = pds
    },
    /**
     * カレンダー上の日程まで移動します.
     */
    gotoDateWithId(dateId: string) {
        this.gotoDate = dateId
    },
    /**
     * 表示typeを更新します。
     * @param type suggested: 提案済み, possibles: 候補選択中, fixed: 確定済み, canceled: キャンセル済み
     */
    updateDisplayType(type: string) {
        Logger(`pdm.${funcName()} 画面表示タイプを変更displayType:${type}`)
        this.displayType = type
    },
    updateCurrentStep(step: string) {
        Logger(`pdm.updateCurrentStep 表示ステップを更新CurrentStep: ${step}`)
        this.currentStep = step
    },
    getSelectedPossibleDates(astag: AvailableScheduleTag): PossibleDate[] {
        Logger(
            `pdm.getSelectedPossibleDates astag.optional_schedules(${
                Object.keys(astag?.optional_schedules || []).length
            }):${Util.output(astag?.optional_schedules)} selectedPds(${this.selectedPds?.length}):${Util.output(
                this.selectedPds
            )}`
        )
        if (Util.isPresent(this.selectedPds)) return this.selectedPds

        let pds = []
        let selectedPds = []
        const _pds = this.getPdsAfterRemoved()
        // let sharedMems = this.sharedMembers || []
        // let _shared = sharedMems.find(m => m.user_id == this.userInfo.user_id)
        /**
         * 共有している場合のみ、共有済みの調整カレンダーから optional_add_schedulesを見て事前に追加します。
         */
        if (this.sharedMyAstag && astag.optional_schedules) {
            const selectedSchedules: any = Object.values(astag.optional_schedules) // [Event[], Event[]]
            const ops: PossibleDate[] = selectedSchedules.flat().filter(op => op.type == `optional_add`)
            const selectedStartTimes = ops.map(e => e.start_time)
            Logger(
                `pdm.getSelectedPossibleDates selectedStartTimes(${selectedStartTimes.length}):${Util.output(
                    selectedStartTimes
                )}, _pds(${_pds.length}):${Util.output(_pds)}`
            )
            ops.forEach(op => {
                op.date_format = op.date
            })
            selectedPds = ops
            for (let _pd of _pds) {
                if (selectedStartTimes.includes(_pd.start_time)) {
                    let alreadyAdded = selectedPds.find(p => p.start_time == _pd.start_time)
                    _pd.selected = true
                    if (!alreadyAdded) {
                        selectedPds.push({ ..._pd })
                    }
                }
                pds.push(_pd)
            }
        } else {
            pds = _pds
        }
        Logger(`pdm.getSelectedPossibleDates selectedPds[${(selectedPds || []).length}]:${Util.output(selectedPds)}`)
        this.selectedPds = selectedPds
        return selectedPds
    },
    updateSelectedPds(pds: PossibleDate[]) {
        Logger(`pdm.${funcName()} pds[${(pds || []).length}]:${Util.output(pds)}`)
        this.selectedPds = pds
        if ((this.displayPds || []).length > 0 && (this.selectedPds || []).length > 0) {
            let _pds = [...this.displayPds]
            for (let pd of _pds) {
                let _pd = (this.selectedPds || []).find(selectpd => PossibleDate.isSame(pd, selectpd))
                if (_pd) {
                    pd.selected = true
                } else {
                    pd.selected = false
                }
            }
            this.displayPds = _pds
        }
    },
    changeTimezone(tzDic: any) {
        if (!this.currentTZDic || this.currentTZDic.name != tzDic.name) {
            Logger(`pdm.${funcName()} 現在のTZと異なるため更新します. tzDic:${Util.output(tzDic)}`)
            this.currentTZDic = tzDic
        }
        Logger(
            `pdm.${funcName()} possibleDates.length:${(this.possibleDates || []).length} currentTZDic:${Util.output(
                this.currentTZDic
            )}`
        )

        if (this.possibleDates) {
            if (Util.isPresent(this.possible_dates_selectable_mag_dic)) {
                let magDic = { ...this.possible_dates_selectable_mag_dic }

                Object.entries(magDic).forEach(([mag_id, beforeTzPds]) => {
                    let newPds = []
                    let pds = beforeTzPds as PossibleDate[]
                    for (let _pd of pds) {
                        let pd = PossibleDate.setTimeZoneWithFormat(_pd, tzDic)
                        Logger(`${funcName()} mag_id:${mag_id}, pd.jp_format:${_pd.jp_format}, pd.tz_format:${pd.tz_format}`)
                        newPds.push(pd)
                    }
                    magDic[mag_id] = newPds
                })
                this.possible_dates_selectable_mag_dic = magDic
            }

            let newPds = []

            let pds = [...(this.possibleDates || [])]
            for (let _pd of pds) {
                let pd = PossibleDate.setTimeZoneWithFormat(_pd, tzDic)
                Logger(`${funcName()} pd.jp_format:${_pd.jp_format}, pd.tz_format:${pd.tz_format}`)
                newPds.push(pd)
            }
            this.possibleDates = newPds
        }
    },
    isJpTimezone() {
        if (!this.currentTZDic) return true
        let tzname = this.currentTZDic.name

        if (tzname == this.jpTimezoneValue) return true
        return false
    },
    getCalendarShowType(rs: RoomSetting, isSP: boolean) {
        if (this.calendarShowType) return this.calendarShowType

        let calendarShowType = (rs.calendar_show_type || `monthly_monthly`).split(`_`)
        if (isSP) {
            let type = calendarShowType[1]
            this.calendarShowType = type
            return type
        }
        this.calendarShowType = calendarShowType[0]
        return calendarShowType[0]
    },
    // 月次・週次を切り替えて、取得可能な日程があるかを取得します.
    getGettableItems(): any[] {
        let gettableItems = []
        if (this.calendarShowType == `weekly`) {
            // this.currentPossibleDates = this.pdm.possibleDates
            const avWeeks = this.available_weeks
            let weeks = this.weeks
            gettableItems = Util.arraySabun(avWeeks, weeks)
        } else {
            const avMonths = this.available_months
            let months = this.months
            gettableItems = Util.arraySabun(avMonths, months)
        }
        return gettableItems
    },
})
export default _PossibleDatesManager
