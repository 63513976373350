
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, sleep, getLang, funcName } from "packs/common"
import { gtagLog, gtagClick, gtagPage, gtagEvent, gtagEventOwner, gtagPageOwner } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ModalCancelAppointment from "packs/pages/link/modals/ModalCancelAppointment.vue"
import FixScheduleModal from "packs/pages/link/modals/FixScheduleModal.vue"
import FixScheduleSmallModal from "packs/pages/link/modals/FixScheduleSmallModal.vue"
import ScheduleViewLoader from "packs/components/loader/ScheduleViewLoader.vue"
import SelectableGroups from "packs/pages/link/parts/SelectableGroups.vue"
import SelectableLocations from "packs/pages/link/parts/SelectableLocations.vue"
import PossibleDatesWithCalendarTable from "packs/pages/link/parts/PossibleDatesWithCalendarTable.vue"
import CalendarActionLargeButtons from "packs/pages/link/parts/CalendarActionLargeButtons.vue"
import ModalRequestAdditionalPossibleDates from "packs/pages/link/modals/ModalRequestAdditionalPossibleDates.vue"
import ModalEditAppointment from "packs/pages/link/modals/ModalEditAppointment.vue"
import FormContent from "packs/pages/schedule/FormContent.vue"
import ModalInnerSelectPossibleDates from "packs/pages/link/modals/ModalInnerSelectPossibleDates.vue"
import ThanksPage from "packs/pages/schedule/ThanksPage.vue"
import ScheduleMainFixedAppoContent from "packs/pages/schedule/ScheduleMainFixedAppoContent.vue"
import ScheduleMainEditableCalendarHeaderContent from "packs/pages/schedule/ScheduleMainEditableCalendarHeaderContent.vue"
import ScheduleMainHeaderTitle from "packs/pages/schedule/ScheduleMainHeaderTitle.vue"
import ScheduleMainHeaderButton from "packs/pages/schedule/ScheduleMainHeaderButton.vue"
import InstantRoomContent from "packs/pages/schedule/InstantRoomContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomMember from "packs/models/RoomMember"
import Event from "packs/models/Event"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import PossibleDate from "packs/models/PossibleDate"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import UserFile from "packs/models/UserFile"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import LocationTag from "packs/models/LocationTag"
import FormField from "packs/models/FormField"
import Notice from "packs/models/Notice"
import RoomSetting from "packs/models/RoomSetting"
import FormUtil from "packs/utils/FormUtil"
import CalendarManager from "packs/models/CalendarManager"
import Refresher from "packs/models/Refresher"
import NotificationControl from "packs/utils/NotificationControl"
import CalendarUtil from "packs/utils/CalendarUtil"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FixScheduleModal,
        FixScheduleSmallModal,
        ScheduleViewLoader,
        PossibleDatesWithCalendarTable,
        ModalCancelAppointment,
        SelectableGroups,
        SelectableLocations,
        CalendarActionLargeButtons,
        ModalRequestAdditionalPossibleDates,
        ModalEditAppointment,
        FormContent,
        ModalInnerSelectPossibleDates,
        ThanksPage,
        ScheduleMainFixedAppoContent,
        ScheduleMainEditableCalendarHeaderContent,
        ScheduleMainHeaderTitle,
        ScheduleMainHeaderButton,
        InstantRoomContent,
    },
})
export default class ScheduleMainContent extends Vue {
    // data:
    @Prop()
    appo: Appointment

    @Prop()
    type: string

    @Prop()
    room: Room

    @Prop()
    parentId: string

    @Prop() // func
    getPossibles

    @Prop() // func
    showFilledScheduleModal

    @Prop() // func
    resetPossibleDates

    @Prop()
    loading: boolean

    getPossiblesWithMonth(calId: string) {
        this.getPossibles(false, calId)
    }

    getPossiblesWithWeek(calId: string) {
        this.getPossibles(false, null, null, null, calId)
    }

    rm = RoomManager
    pdm = PossibleDatesManager
    cm = CalendarManager
    isSP = isMobile(window.navigator).phone

    astag: AvailableScheduleTag = null
    href
    currentPossibleDates = null
    Util = Util
    refresher = Refresher
    Appointment = Appointment
    loadingFormSending = false
    exceptSelf = false
    // parentId = null
    stepsDic = {
        open_page: [`form`, `calendar`, `modal_fix`, `complete`],
        with_fix: [`calendar`, `form_with_fix`, `complete`],
        after_fix: [`calendar`, `modal_fix`, `form_with_fix`, `complete`],
    }
    currentStep: string = null
    timingType: string = null // open_page, with_fix, after_fix

    // selectedMessageRoom: Room = null

    userType: string = `` // user or guest
    showPossibleDatesTypes = [`fill`, `overstart`, `overdue`, `confirm`]

    gettingPds: boolean = false
    appoStatus = Appointment.typeAddNew

    isPreview = Util.isPreview() // 調整ページ作成時のプレビュー画面か.

    answeredForm: boolean = false
    answeredFields: FormField[] = null
    // selectingDates: boolean = false // 日程を選択中か.

    additionalParams = null // パラメータを保持.

    // スグINボタンから確定する場合こちらの内容で確定する
    instantRoom: Room = null

    @Ref()
    fixScheduleModal

    @Ref()
    SelectPossibleDatesContent

    @Ref()
    EditableCalendarHeader

    showMainContent() {
        Logger(`${funcName()} currentStep:${this.currentStep}, this.room:${this.room?.id}, this.appo:${this.appo?.id}`)
        if (!this.room || !this.appo || !this.pdm) return false

        Logger(`${funcName()} progress_status:${this.room.progress_status}, appo.status:${this.appo.status}`)

        if (this.room.progress_status == `go`) {
            if ([`calendar`, `modal_fix`, `complete`].includes(this.currentStep)) return true
        }

        if (this.room.progress_status == `stay` && this.appo.status == 10) return true
        if (this.pdm.displayType == `reschedule`) return true

        return false
    }

    public created() {
        let lang = getLang()
        Logger(`${funcName()} lang:${lang}`)
        // if (!Util.isPublic()) this.currentStep = `calendar`
        this.getAdditionalParams()
    }

    public mounted() {
        this.updateFixOrNot2()
        if (this.isSP && !this.currentPossibleDates && this.pdm.possibleDates) {
            this.currentPossibleDates = this.pdm.possibleDates
        }
        this.updateSharedMyAstag()
    }

    get isGuest(): boolean {
        this.userType = Util.checkLoggedInStatus()
        return this.userType == `user` ? false : true
    }

    // すぐインボタンからの場合、カレンダー登録ボタンの表示は不要.
    get isInstantRoom() {
        if (!this.room) return false
        let rs = this.room.room_setting
        if (!rs) return false
        if (rs.is_instant_room) return true
        return false
    }

    public nothingCandidatesAndChangeCal() {
        gtagClick(`遷移: FIX → AstagView`, `NotPossibleDates`)
        this.$router.push(`/available_schedule`)
    }

    // 日程を選択中か、それ以外かを判別します.
    get selectingDates() {
        if (!this.appo) return
        Logger(`${funcName()} appo.status:${this.appo?.status}, currentStep:${this.currentStep}`)
        if (this.pdm.displayType == `reschedule`) {
            // this.selectingDates = true
            return true
        }
        if ([10, -1].includes(this.appo.status)) {
            // this.selectingDates = false
            return false
        }
        if (this.showSuggestedView()) {
            // this.selectingDates = false
            return false
        }

        if (![`calendar`, `modal_fix`].includes(this.currentStep)) {
            // this.selectingDates = false
            return false
        }
        // if (Util.isBlank(this.currentPossibleDates)) return false

        // this.selectingDates = true
        return true
    }

    // 提案完了ページを表示します.
    showSuggestedView() {
        if (!this.room) return false
        if (this.room.is_owner) return false
        if (this.pdm.canFix) return false
        if (this.pdm.displayType != `suggested`) return false

        if (this.appo && !Util.isEmbeds()) gtagPageOwner(`thanksPage`, {})
        return true
    }

    // selectable_magsの場合に選択したmagから日程候補を更新します。
    public selectMag(mag: MeetingAttendeesGroup) {
        if (!this.pdm.selectable_mode) return
        Logger(`${funcName()} mag.id:${mag?.id}`)

        if (mag.id == `any`) {
            this.currentPossibleDates = this.pdm.possibleDates
            this.pdm.selectedMag = null
        } else {
            this.currentPossibleDates = this.pdm.possible_dates_selectable_mag_dic[mag.id]
            this.pdm.selectedMag = mag
        }
        if (Util.isPresent(this.currentPossibleDates)) {
            let dateId = this.pdm.currentDateId
            if (Util.isBlank(dateId)) {
                let _pd = this.currentPossibleDates[0] as PossibleDate
                dateId = _pd.short_format.slice(0, 4)
            }

            this.pdm.selectDate(dateId)
        } else {
            this.pdm.selectDate(null)
        }
    }

    // selectable_locationsの選択したloctagを入れ、appoを更新して
    // pdmを取得し直します。
    public selectLoctag(loctag: LocationTag) {
        Logger(`${funcName()} pdm.displayType:${this.pdm?.displayType}, loctag(${loctag?.id}):${loctag?.name}`)
        let _room = Room.copy(this.room)
        _room.current_appointment.location_tag_id = loctag.id
        _room.current_appointment.location_name = loctag.keyword
        _room.current_appointment.selected_location = loctag
        this.rm.updateCurrentInfo(_room)
        this.refresher.needRefresh.push(`room`)

        sleep(100).then(_ => {
            this.resetPossibleDates()
            sleep(100).then(_ => {
                this.getPossibles()
            })
        })
    }

    /**
     * パラメータがURLに付随している場合は、自動でそのフィールドを埋めます
     */
    getAdditionalParams() {
        this.additionalParams = FormUtil.getParams()
    }

    public getPreviewEvents(
        astag: AvailableScheduleTag,
        appo: Appointment = null,
        rs: RoomSetting,
        needRefresh: boolean,
        month: string,
        room: Room = null,
        week: string = null
    ) {
        let _astag = { ...astag }
        // 三者以上のときに、選択中になってしまうためoptionalに変更していたが、ADOWを設定していない場合、露骨に
        // 候補日程が表示されなくなってしまうため、いったんはずす.
        // _astag.optional_schedules = PossibleDate.changeForPreview(_astag)
        this.astag = _astag
        appo = appo || this.appo
        this.pdm.updateDisplayType(`possibles`)
        Logger(
            `${funcName()} posDates.length:${(this.pdm.possibleDates || []).length} gettingPds?:${this.gettingPds} astag:${
                astag?.id
            }, userInfo.user_id:${this.rm.userInfo?.user_id}, month:${month}`
        )
        if (!this.astag) return
        Logger(`${funcName()} this.appo:${this.appo?.id}, appo:${appo?.id}, astag:${astag?.name}`)
        if (Util.isBlank(appo)) return

        if (this.gettingPds) return
        this.gettingPds = true

        gtagLog("event", "get", {
            event_category: `プレビュー用の候補日を取得`,
            event_label: `${astag.type == 10 ? `不参加カレンダー` : `調整カレンダー`}`,
        })

        // ページ設定から来た場合、ABCを表示したいため、owner=trueで見ます.
        let isOwner = true

        // プレビューの場合、お相手に見えるようにしたいため、falseに変更.
        if (!Util.isRoomSettingsPreview()) isOwner = false
        Logger(`${funcName()} month:${month}, this.cm.pdm.available_months:${Util.output(this.cm?.pdm?.available_months)}`)
        let calendarShowType = this.calendarShowType()
        if (calendarShowType == `monthly`) {
            // 月表示の場合、月を指定して取得します.
            if (!month) {
                if (this.cm?.pdm?.available_months) {
                    month = this.cm.pdm.available_months[0]
                }
                month = !month ? CalendarUtil.toCalId(DateTime.local()) : month
            }
            week = null
        } else if (calendarShowType == `weekly`) {
            if (!week) {
                if (this.cm?.pdm?.available_weeks) {
                    Logger(`getPreviewEvents available_weeks:${this.cm.pdm.available_weeks}`)
                    week = this.cm.pdm.available_weeks[0]
                }

                if (!week) {
                    week = CalendarUtil.toWeekSpan(DateTime.local())
                }
            }
            month = null
        }

        let max_bookings_num = 1
        if (room && room.max_bookings_num > 1) {
            max_bookings_num = room.max_bookings_num
        }

        AvailableScheduleTag.getPossibleDatesPreview(
            astag,
            this.rm.userInfo,
            appo.duration,
            isOwner,
            appo.location_name,
            rs,
            month ? [month] : null,
            needRefresh,
            max_bookings_num,
            room,
            week ? [week] : null,
            calendarShowType
        ).then(pdm => {
            this.gettingPds = false

            // 取得内容を調整ページの候補日時と同じUIにはめます。
            // 3者での調整または2者仲介不在の場合、プレビューでは提案モーダルを表示します。
            Logger(`getPossibleDatesPreview mems: ${Util.output(this.room.attendees)}`)

            if (Util.isPresent(rs) && rs.submit_lower_limit_num) {
                this.pdm.owner_request_num = rs.submit_lower_limit_num
            }
            this.pdm.updateDisplayType(`possibles`)

            let gettableItems = this.pdm.getGettableItems()

            // const avMonths = this.pdm.available_months
            // let months = this.pdm.months
            // let gettableMonths = Util.arraySabun(avMonths, months)
            let posDates = this.pdm.possibleDates
            Logger(
                `ScheduleMainContent.getPossibleDatesPreview month:${month}, week:${week}, gettableItems:${Util.output(
                    gettableItems
                )}  posDates:${(posDates || []).length}, is_only_use_optional_schedules:${
                    astag.is_only_use_optional_schedules
                }, astag.optional_schedules:${Object.keys(astag?.optional_schedules || []).length}`
            )

            if (astag.is_only_use_optional_schedules && Object.keys(astag?.optional_schedules || []).length == 0) {
                this.currentPossibleDates = []
                return
            }
            if ((posDates || []).length == 0) {
                if (gettableItems.length > 0) {
                    let nextItem = gettableItems[0]
                    if ([month, week].includes(nextItem)) {
                        nextItem = gettableItems[1]
                        Logger(`ScheduleMainContent.getPossibleDatesPreview すでに取得済みです`)
                        if (!nextItem) {
                            this.currentPossibleDates = []
                            return
                        }
                    }
                    this.getPreviewEvents(
                        astag,
                        null,
                        rs,
                        false,
                        calendarShowType == `monthly` ? nextItem : null,
                        room,
                        calendarShowType == `weekly` ? nextItem : null
                    )
                } else {
                    this.currentPossibleDates = posDates
                }
            } else {
                this.currentPossibleDates = posDates
            }
        })
    }

    @Watch("appo", { deep: true })
    updateFixOrNot1() {
        this.pdm.checkFixOrNot()
        Logger(`updateFixOrNot1: canFix:${this.pdm.canFix}`)
    }

    @Watch("room", { deep: true })
    updateFixOrNot2() {
        if (!this.room) return
        this.timingType = this.room.display_form_timing_type
        this.pdm.checkFixOrNot()
        Logger(
            `${funcName()} 最終的にアップデートされたroom canfix:${this.pdm.canFix}, timingType:${this.timingType}, room:${
                this.room?.id
            }`
        )
        // this.currentStep = this.getNextStep()
    }

    @Watch("pdm.possibleDates", { deep: true })
    updateFixOrNot3() {
        if (!this.pdm) return
        this.pdm.checkFixOrNot()
        // this.selectablePdm = this.pdm
        let gettableItems = this.pdm.getGettableItems()

        if ((this.pdm.possibleDates || []).length > 0 || gettableItems.length == 0) {
            this.currentPossibleDates = this.pdm.possibleDates
        }
    }

    public selectPossibleDates(selectedPds: PossibleDate[]) {
        if (Util.isRoomSettingsPreview()) {
            Notice.message = `調整ページ編集画面のため、提案できません。`
            return
        }
        const nextstep = this.getNextStep()
        this.pdm.currentPds = selectedPds
        gtagClick(`複数選択 ${selectedPds.length}`, `next: ${nextstep}`)
        if (nextstep == `modal_fix`) this.currentStep = nextstep
        Logger(`${funcName()} answeredFields:${Util.output(this.answeredFields)}`)
        if (Util.isPresent(this.answeredFields)) {
            this.completeForm(this.answeredFields)
        } else {
            const nextstep = this.getNextStep()
            this.currentStep = nextstep
        }
    }

    // 確定後の再提案時には、変更リクエストのモーダルを表示します.
    public selectPossibleDatesFromReschedule(selectedPds: PossibleDate[]) {
        Logger(`${funcName()}  selectedPds.length:${(selectedPds || []).length}件`)
        this.pdm.currentPds = selectedPds
        this.$vfm.open(`CancelAppointmentModal`)
    }

    /**
     * 一度提案した日程を再度選び直します。
     */
    public reselectSuggestDates(needRefresh = true, calId = null) {
        gtagClick(`候補再選択`, `${Object.values(this.rm.astag.optional_schedules)}`)
        //
        if (!this.type) return
        if (!this.showPossibleDatesTypes.includes(this.type)) return

        if (this.gettingPds) return
        Logger(`${funcName()} 未確定の案件のため再度日程候補を取得してきます。 needRefresh:${needRefresh}, calId:${calId}`)
        this.rm.startProgress()
        this.gettingPds = true
        this.exceptSelf = true
        this.pdm.updateDisplayType(`reschedule`)
        if (needRefresh) {
            this.currentPossibleDates = null
            this.pdm.selectedPds = null
            this.pdm.currentDateId = null
            this.pdm.currentDate = null
            this.pdm.currentCalendarDate = null
        }

        this.pdm
            .getPossibleDates(
                this.rm.currentRoom,
                this.exceptSelf,
                needRefresh,
                this.pdm.calendarShowType == `monthly` ? calId : null,
                null,
                null,
                this.rm.guestKey,
                null,
                null,
                this.pdm.calendarShowType == `weekly` ? calId : null
            )
            .then(pds => {
                this.gettingPds = false

                let posDates = this.pdm.possibleDates
                let gettableItems = this.pdm.getGettableItems()
                Logger(`${funcName()} RETURN possibleDates:${posDates.length}`)

                if ((posDates || []).length == 0 && gettableItems.length > 0) {
                    Logger(`${funcName()} 候補日時がないため、再度日程候補を取得します。 gettableItems:${gettableItems[0]}`)
                    this.reselectSuggestDates(false, gettableItems[0])
                } else {
                    this.currentPossibleDates = posDates || []
                    if ((posDates || []).length == 0) {
                        this.pdm.possibleDates = []
                    } else {
                        let _first_pd = posDates[0]
                        this.pdm.selectDate(_first_pd?.date_format)
                    }

                    this.rm.endProgress()
                }
            })
    }

    public confirmFixSchedule(pd: PossibleDate) {
        Logger(`${funcName()} date format: ${pd.jp_format} , ${this.pdm.sharedMembers}`)
        if (Util.isRoomSettingsPreview()) {
            Notice.message = `調整ページ編集画面のため、確定できません。`
            return
        }
        this.pdm.selectCurrentPd(pd)
        gtagClick(`この日程を確認ボタン`, `${pd.jp_format}`)
        let notificationType = NotificationControl.notificationTypeSelectTime
        gtagEventOwner(notificationType, `select`, `${pd.start_time}`)
        if (Util.isEmbeds()) {
            // 埋め込み形式の場合に、親要素に確定した旨を通知します.
            let notiParams = { type: notificationType, startTime: `${pd.start_time}` }
            NotificationControl.notifyParent(notificationType, notiParams)
        }

        if (Util.isPublic()) {
            const nextstep = this.getNextStep()

            if (this.appo.fix_type == 1) {
                // 候補提案型のため、どこにも飛ばさない.
                return
            } else if (this.currentStep == `modal_fix`) {
                // モーダルを一度閉じて、再選択.
            } else if (nextstep == `modal_fix`) {
                // 今までと一緒でモーダルを表示.
                this.currentStep = nextstep
            } else {
                // フォームを表示.
                this.currentStep = nextstep
                gtagClick(`フォーム表示`, `${pd.jp_format} next: ${nextstep}`)
                return
            }
        } else if (this.hasFieldsYetInParentRoom()) {
            // ParentRoomの未入力フォームがあるため、フォームを表示.
            this.currentStep = `form`
            return
        }

        if (this.appoStatus == Appointment.typeReschedule) {
            // リスケの場合、選択した日程を表示とメッセージを入力します.
            this.$vfm.open(`CancelAppointmentModal`)
        } else if (this.isPreview || this.isGuest) {
            Logger(`${funcName()} FixScheduleSmallModalを表示します.`)
            this.$vfm.open("FixScheduleSmallModal")
        } else {
            this.$vfm.open("confirmFixScheduleModal")
        }
    }

    /**
     * モーダルを閉じてフォーム入力に移ります.
     */
    callBeforeFix(pd: PossibleDate) {
        //
        this.pdm.currentPd = pd
        this.$vfm.close(`FixScheduleSmallModal`)
        this.$vfm.close("confirmFixScheduleModal")

        let nextstep = this.getNextStep() // change to form.
        if (nextstep == `complete`) {
            this.completeForm(this.answeredFields)
        } else {
            this.currentStep = nextstep
        }
    }

    @Watch("type")
    public saveType() {
        Logger(`${funcName()} typeを保存します:${this.type}`)
        roomStorage.saveScheduleType(this.type)
    }

    public showCancelModal() {
        this.appoStatus = Appointment.typeCancel
        this.$vfm.open("CancelAppointmentModal")
    }

    public addNewAppo() {
        gtagClick(`新規でアポ作成`, `ScheduleMain`)
        this.appoStatus = Appointment.typeAddNew
        this.$vfm.open("EditAppointmentModal")
    }

    public hideEditAppointmentModal(didChange: boolean, appo: Appointment) {
        this.$vfm.close("EditAppointmentModal")
        if (didChange && appo) {
            this.pdm.resetPdm()
            this.pdm.setNew(this.rm.currentRoom, this.rm.userInfo, appo, this.rm)
            sleep(50).then(_ => {
                this.refresher.needRefresh.push(`pdm`)
            })
        }
    }

    public editAstag() {
        if (this.type != `editable`) return
        Logger(`${funcName()} 調整カレンダーを変更 を押しました.`)
        this.$emit(`editAstag`)
    }

    public editAppoInfo() {
        this.$emit(`editAppoInfo`)
    }

    /**
     * 入力すべきフォームがあるparentRoomか.（入力したかどうかの判定ではありません.）
     * ParentRoomの場合は、日程 -> Formの順番で固定です。
     */
    public hasFieldsYetInParentRoom(room: Room = null): boolean {
        if (this.isPreview) return
        if (Util.isBlank(room)) room = this.room
        if (Util.isBlank(room) || Util.isBlank(room.members)) return
        let _ffs = room.form_fields
        let myMem = room.members.find(m => m.user_id == this.rm.userInfo.user_id && m.answered_status == `not_yet`)
        return Util.isPresent(_ffs) && myMem && myMem.role != 100 ? true : false
    }

    /**
     * フォーム対応
     */

    public completeForm(fields: FormField[]) {
        Logger(`${funcName()} formが完成しました:${Util.output(fields)}`)
        this.answeredForm = true
        this.answeredFields = fields

        // 送信するルームを作成.
        let _room = Util.isPresent(this.instantRoom) ? Room.copy(this.instantRoom) : Room.copy(this.room)
        if (Util.isPresent(fields)) {
            _room.form_fields = fields
        }
        let nextstep = this.getNextStep()
        Logger(`${funcName()} nextstep:${nextstep}`)
        if (this.isPreview) {
            if (nextstep == `complete`) {
                Notice.message = `プレビューのため送信できません。`
            } else {
                this.currentStep = nextstep
            }
            return
        }
        // if (Util.isEmbeds() && nextstep == `calendar`) {
        //     Logger(`${funcName()} 埋め込みでフォーム先の場合、まとめて送信します.`)
        //     this.currentStep = nextstep
        //     return
        // }

        let startTime = this.pdm.currentPd ? this.pdm.currentPd.start_time : null
        let startTimes = Util.isPresent(this.pdm.currentPds) ? this.pdm.currentPds.map(pd => pd.start_time) : null
        if (this.loadingFormSending) return
        this.rm.startProgress()
        this.loadingFormSending = true
        if (Util.isPresent(this.pdm.currentPds) && this.SelectPossibleDatesContent) {
            this.SelectPossibleDatesContent.changeSavingStatus(true)
        }

        if (Util.isPresent(this.pdm.currentTZDic)) {
            this.rm.userInfo.timezone_value = this.pdm.currentTZDic.value
        }

        gtagEvent(`フォーム送信`, `send`, `start: ${startTime}, ${startTimes}`)
        FormField.send(
            _room,
            startTime,
            startTimes,
            this.pdm,
            nextstep,
            false,
            false,
            null,
            null,
            this.cm.registrable_attendees,
            this.pdm.currentTZDic,
            this.pdm.rdlog_id,
            this.additionalParams
        ).then(res => {
            this.rm.endProgress()
            this.$vfm.close("FixLoadingModal")
            if (res) {
                // this.rm.userInfo = res.userInfo

                this.rm.setUserInfo(res.userInfo)
                let room = res.room as Room

                // 確定できずにnextAction==`reload`だった場合、モーダルを表示し、日程候補をリロードします.
                let nextAction: string = res.next_action
                let type: string = res.type

                let beforeRoom = Room.copy(this.rm.currentRoom)
                this.rm.updateCurrentInfo(room)

                let _room = (this.rm.rooms || []).find(r => r.room_id == room.room_id)
                if (_room) {
                    Object.assign(_room, room)
                } else {
                    let _rooms = this.rm.rooms ? [...this.rm.rooms] : []
                    _rooms.push(room)
                    this.rm.rooms = _rooms
                }
                if (Util.isPresent(res.astag)) {
                    this.cm.astag = res.astag
                }
                if (Util.isPresent(res.registered_attendees)) {
                    this.cm.registered_attendees = res.registered_attendees
                }
                let _appo = room.current_appointment
                this.rm.guestKey = res.guestKey

                this.pdm.setNew(room, res.userInfo, _appo, this.rm)
                // AIが調整カレンダーを変更した可能性があるため、再度possible_datesを取得します.
                if (
                    room.display_schedule_type == `rules_by_ai` ||
                    room?.current_appointment?.location_tag_id != beforeRoom?.current_appointment?.location_tag_id
                ) {
                    Logger(
                        `ScheduleMainContent.completeForm refresh PossibleDates display_schedule_type:${room.display_schedule_type}, loctag:${room?.current_appointment?.location_tag_id}`
                    )

                    this.refresher.needRefresh.push(`room`)
                    this.$nextTick(() => {
                        this.getPossibles(true)
                    })
                    // this.pdm.getPossibleDates(room, true, true, null, null, null, this.rm.guestKey).then(res => {
                    //     this.gettingPds = false

                    //     const avMonths = this.pdm.available_months
                    //     let months = this.pdm.months
                    //     let gettableMonths = Util.arraySabun(avMonths, months)
                    //     let posDates = this.pdm.possibleDates
                    //     Logger(`${funcName()} possibleDatesを確認しました: ${posDates.length}`)
                    //     if ((posDates || []).length == 0) {
                    //         this.pdm.possibleDates = []
                    //     }
                    // })
                }

                gtagEvent(`フォーム完了`, `progress_${room.progress_status}`, `appo status: ${_appo.status}`)
                if (_appo.status == 10) {
                    // this.rm.currentAppointment = _appo
                    let notificationType = NotificationControl.notificationTypeFixed
                    gtagEventOwner(notificationType, `engagement`, `${_appo.jp_format}`)
                    if (Util.isEmbeds()) {
                        // 埋め込み形式の場合に、親要素に確定した旨を通知します.
                        let notiParams = { type: notificationType, startTime: `${_appo.start_time}` }
                        NotificationControl.notifyParent(notificationType, notiParams)
                    }
                }
                // 埋め込みの場合、cookiesにセットできないため、一時的なキーを使います.
                if (Util.isEmbeds() && Util.isPresent(res.guestKey)) {
                    this.rm.guestKey = res.guestKey
                    Logger(`ゲストキーをセットしました key:${res.guestKey}`)
                }

                let afterNextStep = this.getNextStep(room)
                this.refresher.needRefresh.push(`room`)
                Logger(
                    `${funcName()} フォーム完了後: currentStep: ${
                        this.currentStep
                    }, nextstep: ${nextstep}, after: ${afterNextStep}, currentPds.length:${this.pdm.currentPds?.length}`
                )

                // リダイレクトするか確認します.
                this.checkAndRedirectIfUrlHasPresent(room)
                this.scrollToContentTop()

                if (nextstep == `calendar` && room.progress_status == `stay`) {
                    this.currentStep = `complete`
                    Notice.message = `お申し込みを受け付けました。`
                    gtagPageOwner(`completeForm`, {})
                    return
                }
                this.currentStep = afterNextStep

                // 埋まっていた場合はモーダルを表示し、再調整を促します。
                if (type == `fix_schedule_failed` && nextAction == `reload`) {
                    this.showFilledScheduleModal()
                    // すぐ呼ぶとroomがまだpublic_roomのケースがあるため、parent_roomになったことを見計らって更新をかけます.
                    sleep(100).then(_ => {
                        this.getPossibles(true)
                        this.currentStep = `calendar`
                    })

                    return
                }

                // 提案ケース
                if (Util.isPresent(this.pdm.currentPds)) {
                    this.pdm.sharedSelf()
                    Logger(`${funcName()} フォーム完了後roomを入れ直します: ${Util.output(res.astag)}`)
                    if (Util.isPresent(res.astag)) {
                        this.astag = res.astag
                        this.rm.astag = this.astag
                        if (this.SelectPossibleDatesContent) this.SelectPossibleDatesContent.changeSavingStatus(false)
                    }
                    this.pdm.updateDisplayType(`suggested`)
                    let notificationType = NotificationControl.notificationTypeFixed
                    let suggestedNum = (this.pdm.currentPds || []).length
                    gtagEventOwner(notificationType, `engagement`, `${suggestedNum}`)
                    if (Util.isEmbeds()) {
                        // 埋め込み形式の場合に、親要素に確定した旨を通知します.
                        let notiParams = { type: notificationType, suggestedNum: suggestedNum }
                        NotificationControl.notifyParent(notificationType, notiParams)
                    }
                    // this.rm.getAvailableSchedule(false, res.room.id).then(res => {
                    //     Logger(`astagを取得してきてはめました.`)
                    //     if (this.SelectPossibleDatesContent) this.SelectPossibleDatesContent.changeSavingStatus(false)
                    // })
                    // pdm.didshareをtrueにして、提案済みのビューを表示する.
                    // this.getPossibles()
                    // this.pdm.sharedSelf()
                }
                // if (res.show_attendees_modal) this.showAttendeesModalIfCompleted()
            }
            this.loadingFormSending = false
        })
        // } else {
        //     this.currentStep = nextstep
        // }
    }

    scrollToContentTop() {
        Logger(`${funcName()}`)
        let el = document.getElementById("ScheduleCalendarContentTop")
        if (el && this.rm.designType() == `normal`) {
            el.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }

    /**
     * モーダル確定後にリダイレクトURLがセットされている場合リダイレクトさせます.
     * @param dic [{key: string, value: any}] {appo: Appointment, show_attendees_modal: boolean}
     */
    actionAfterFixed(dic: any) {
        Logger(`${funcName()} 確定後にとんできました: ${Util.output(this.room)}`)
        if (dic && dic.appo.status == 10) {
            let _appo = dic.appo
            let notificationType = NotificationControl.notificationTypeFixed
            gtagEventOwner(notificationType, `engagement`, `${_appo.jp_format}`)
            if (Util.isEmbeds()) {
                // 埋め込み形式の場合に、親要素に確定した旨を通知します.
                let params = { type: notificationType, startTime: `${_appo.start_time}` }
                NotificationControl.notifyParent(notificationType, params)
            }
        }
        this.checkAndRedirectIfUrlHasPresent(this.room)
        // this.showAttendeesModalIfCompleted()
        // if (dic && dic.show_attendees_modal) {
        //     this.showAttendeesModalIfCompleted()
        // }
    }

    /**
     * ThanksPage表示時にリダイレクトURLを設定している場合は、リダイレクトします。
     */
    checkAndRedirectIfUrlHasPresent(room: Room) {
        if (Util.isBlank(room)) return

        let _appo = room.current_appointment
        let _mem = room.members.find(m => m.user_id == this.rm.userInfo.user_id)

        // フォームの入力後、日程調整表示ステータスがfalseの場合、そのまま完成に変更.
        if (_mem && _mem.answered_status == `done` && (_appo.status == 10 || this.room.progress_status == `stay`)) {
            if (RoomSetting.isRedirectUrl(room)) {
                let _thanksPage = room.room_setting.thanks_page
                let _url = _thanksPage.redirect_url_after_send
                gtagEvent(`timing_complete`, `完了後遷移`, `${_url}`)
                location.href = Util.getUrl(_url)
                return
            }
        }
    }

    /**
     * パラメータのskip_formによってフォーム入力が飛ばされた場合に呼びます.
     */
    skipForm(fields: FormField[]) {
        this.answeredForm = true
        this.answeredFields = fields
        let nextstep = this.getNextStep()
        Logger(`${funcName()} nextstep:${nextstep}`)
        if (nextstep == `calendar`) {
            this.currentStep = nextstep
        } else if (nextstep == `complete`) {
            this.completeForm(fields)
            this.$vfm.open("FixLoadingModal")
        } else if (nextstep == `form`) {
            this.currentStep = nextstep
            this.completeForm(fields)
            this.$vfm.open("FixLoadingModal")
        }
    }

    autoSendForm(fields: FormField[]) {
        Logger(`${funcName()}`)
        this.completeForm(fields)
    }

    @Watch(`timingType`)
    public updateTimingType() {
        // if (!this.timingType) return
        Logger(`${funcName()} timingType:${this.timingType}`)
        if (!this.currentStep) this.currentStep = this.getNextStep()
    }

    changeDate() {
        // 日付を再度選び直します.
        this.currentStep = `calendar`
        this.instantRoom = null
    }

    /**
     * open_page: [`form`, `calendar`, `modal_fix`, `complete`],
        with_fix: [`calendar`, `form_with_fix`, `complete`],
        after_fix: [`calendar`, `modal_fix`, `form_with_fix`, `complete`],
     */
    getNextStep(room: Room = null) {
        if (!this.appo) return

        // 限定公開ページ
        // if (!Util.isPublic()) {
        if ((this.rm.currentRoom && this.rm.currentRoom.room_type == `parent_room`) || !Util.isPublic()) {
            Logger(`${funcName()} timingType:${this.timingType}, currentStep:${this.currentStep}`)
            if (!this.timingType) {
                this.currentStep = `calendar`
                return `calendar`
            }

            if (this.currentStep == `complete`) return `complete`

            let steps = this.stepsDic[this.timingType]
            if ([10, -1].includes(this.appo.status)) {
                return `calendar`
            }
            // else if (this.hasFieldsYetInParentRoom(room)) {
            //     Logger(`hasFieldsYetInParentRoom true`)
            //     return `form`
            // }
            else {
                let yet = this.hasFieldsYetInParentRoom(room)
                Logger(`${funcName()} hasFieldsYetInParentRoom:${yet} currentStep:${this.currentStep}`)
                if (!this.currentStep) {
                    // フォーム先のアンケートフォームの場合、 this.room.progress_status == `stay` &&
                    if (yet && this.room.display_form_timing_type == `open_page`) {
                        return `form`
                    }
                    let index = steps.indexOf(`form`)
                    if (index == -1) index = steps.indexOf(`form_with_index`)
                    return steps[index + 1]
                } else {
                    let index = steps.indexOf(this.currentStep)
                    // let index = steps.indexOf(`form`)
                    Logger(`return nextstep : ${steps[index + 1]}`)
                    return steps[index + 1]
                }
            }

            return
        }
        if (!this.timingType) return
        let steps = this.stepsDic[this.timingType]

        if (!this.currentStep) {
            // 最初のステップを開始.
            return steps[0]
        } else {
            let index = steps.indexOf(this.currentStep)
            Logger(`${funcName()} return nextstep : ${steps[index + 1]}`)
            return steps[index + 1]
        }
    }

    @Watch(`rm.user_setting`, { deep: true })
    updateUserSetting() {
        if (!this.rm.user_setting) return
        if (!this.isPreview) return
        // プレビューのときのみ、下限件数をライブでアップデートします。
        this.pdm.owner_request_num = this.rm.user_setting.submit_lower_limit_num
    }

    /**
     * 投票状況の更新があった場合に呼びます.
     */
    public updatedVotedRooms(success: boolean, dic: any) {
        this.$vfm.close(`CancelAppointmentModal`)
        if (success) {
            let _appo = dic.appo as Appointment
            let room_ids: string[] = dic.room_ids

            this.room.current_appointment = _appo
            if (_appo.status == 10) {
                this.room.is_voted = true
            } else {
                this.room.is_voted = false
            }
            this.rm.updateCurrentInfo(this.room)
        }
    }

    changeLoading(status: boolean) {
        this.loadingFormSending = status
        if (this.appo.status == 10) {
            this.appo.status = 1
            this.pdm.didShare()
        }
    }

    rescheduleWithoutChoice() {
        this.pdm.currentPd = null
        this.pdm.currentPds = null
        this.appoStatus = Appointment.typeReschedule
        this.$vfm.open(`CancelAppointmentModal`)
    }

    clickReschedule() {
        Logger(`${funcName()}`)
        gtagClick(`再調整して日程を選択`, ``)
        let endTime = this.appo.start_time + this.appo.duration * 60
        const now = Util.getSec()
        if (endTime < now) {
            this.$vfm.open(`CancelAppointmentModal`)
            return
        }
        this.appoStatus = Appointment.typeReschedule
        this.currentPossibleDates = null
        this.appo.status = 1
        this.room.current_appointment = this.appo
        this.rm.updateCurrentInfo(this.room)
        this.pdm.updateDisplayType(`reschedule`)

        // 提案形式の場合、自分を除きます.
        if (!this.pdm.canFix) {
            this.exceptSelf = true
        }
        sleep(50).then(_ => {
            this.getPossibles()
        })
    }

    /**
     * リスケが押されてから候補日を取得してきます.
     */
    rescheduledRoom(appo: Appointment, showFilledScheduleModal = false) {
        Logger(`${funcName()} appo.id:${appo.id}, showFilledScheduleModal:${showFilledScheduleModal}`)
        this.pdm.appo = appo
        this.currentPossibleDates = null
        this.appoStatus = Appointment.typeNew
        this.pdm.updateDisplayType(`possibles`)
        if (showFilledScheduleModal) {
            this.showFilledScheduleModal()
        }
        sleep(50).then(_ => {
            this.getPossibles()
        })
    }

    @Watch(`currentStep`)
    updateCurrentStep() {
        this.pdm.updateCurrentStep(this.currentStep)
    }

    editCalendar() {
        this.EditableCalendarHeader.clickEditCurrentAstag()
    }

    clickInstantButton(pd: PossibleDate) {
        if (!this.rm.currentInstantRoom) return
        Logger(`${funcName()} pd:${pd.jp_format}`)
        // let ffs = this.rm.currentInstantRoom.form_fields
        this.instantRoom = this.rm.currentInstantRoom
        this.confirmFixSchedule(pd)
    }

    @Watch(`pdm.sharedMyAstag`)
    updateSharedMyAstag() {
        Logger(`${funcName()} pdm.sharedMyAstag:${this.pdm.sharedMyAstag?.id}`)
        if (!this.pdm.sharedMyAstag) return
        let astag = { ...this.pdm.sharedMyAstag }
        this.rm.astags ||= []
        this.rm.astags.push(astag)
        this.rm.astag = astag
    }

    calendarShowType() {
        if (this.pdm?.calendarShowType) return this.pdm?.calendarShowType

        if (!this.room?.room_setting) {
            return `monthly`
        }

        let type = this.pdm.getCalendarShowType(this.room.room_setting, this.isSP)
        return type
    }
}
