import { DateTime } from "luxon"
import { zeroPadding, Logger } from "../common"
import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "./Notice"
import NotificationControl from "packs/utils/NotificationControl"
import PossibleDate from "packs/models/PossibleDate"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarTag from "./CalendarTag"
import Room from "./Room"
import lodash from "lodash"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

export default class RoomMember {
    static readonly OWNER: number = 100
    static readonly MEMBER: number = 10
    static readonly MEMBER_OPTIONAL: number = 9
    static readonly OBSERVER: number = -10

    static readonly FIX = `fix`

    constructor(
        public id: string,
        public email: string,
        public cc_emails: string,
        public name: string,
        public company_name: string,
        public short_name: string,
        public private_memo: string,
        public role: number,
        public role_name: string,
        // member=1, admin=100,
        public user_group_role: number,
        public is_company: boolean,
        public group_status: number,
        public user_group_id: string,
        public systemUpdatedAt: number,
        public user_id: string,
        public image_url: string,
        public last_read_message_id: string,
        public last_read_message_created_at: number,
        public last_read_at: number,
        public shared: boolean,
        public display_status: number,
        public color: string,
        public is_temporary: boolean,
        public answered_status: string,
        public answer_results: any,
        public suggested_dates: PossibleDate[],
        public share_type: string,
        public attendable: boolean, // 出席可能かどうか.サマリーで提案している日程に参加できるかどうかの判定を入れておきます.
        public user_permissions_option: UserPermissionsOption,
        public assign_type: string, // fix, watch, none
        public calendar_tag: CalendarTag,
        public priorityType: string,
        public timezone_value: string,
        public additional_attendees: any[], // [{name:, email:}]
        public tel: string,
        public link: string,
        public member_id: string,

        public suggested_dates_display_type: string,
        public suggested_dates_display_start_at: number,
        public suggested_dates_display_end_at: number,
        public suggested_dates_optional_schedules: number[],
        public available_schedule_tag_id: string,

        public assign_form_answer_results: any,
        public match_rule_dic: any,
        public parent_room_id: string,

        public otp_required_for_login: boolean,

        // "parent_room_group"で利用 block_type, can_skip_suggest, open_uuid, last_suggested_at, optional_schedules, optional_schedules_count, available_schedule_tag_id
        public line_parent_room: any
    ) {}

    static copy(o: RoomMember): RoomMember {
        let newO = lodash.cloneDeep(o)
        return newO
    }

    public update() {
        this.systemUpdatedAt = DateTime.local().toMillis()
    }

    static fetchFromJson(membersJson: any[]): RoomMember[] {
        let members: RoomMember[] = []
        if (!membersJson || membersJson.length == 0) return members
        // const arrOfGroups = JSON.parse(groupsJson)
        // Logger(`roomMember arrOfGroups: ${membersJson.length}`)
        for (let mem of membersJson) {
            if (!mem) continue
            // Logger(`mem: ${Util.output(mem)}`)
            let _m = this.createDefaultClient()
            Object.assign(_m, mem)
            let _mem: RoomMember = _m
            try {
                if (!mem.color) {
                    let hex: string = Util.hex(_mem.name)
                    _mem.color = hex.slice(0, 6)
                    if (_mem.color.length < 6) {
                        _mem.color += "00000000".slice(0, 6 - _mem.color.length)
                    }
                }
            } catch (e) {
                _mem.color = "2c96ff"
            }
            // Logger(`AFTER mem: ${Util.output(_mem)}`)

            _mem.update
            members.push(_mem)
        }
        return members
    }

    static createDefaultClient(): RoomMember {
        return new RoomMember(
            ``,
            ``,
            ``,
            ``,
            ``,
            null,
            ``,
            1,
            `member`,
            100,
            true,
            1,
            ``,
            DateTime.local().toMillis(),
            ``,
            null,
            null,
            null,
            null,
            false,
            10,
            "2f85d0",
            false,
            null,
            null,
            null,
            null,
            true,
            null,
            `none`,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            `all`,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            {}
        )
    }

    /**
     *
     */
    static createForPublicRoom(): RoomMember {
        // 架空ユーザーを追加
        let mem = RoomMember.createDefaultClient()
        mem.name = `●●`
        mem.company_name = `○○株式会社`
        mem.tel = `08012345678`
        return mem
    }

    static removeOwner(members: RoomMember[], myUserInfo: RoomMember = null) {
        if (Util.isBlank(members)) return []
        // members = members.filter(m => m.role != 1)
        if (Util.isPresent(myUserInfo)) {
            members = members.filter(m => m.user_id != myUserInfo.user_id)
        }
        return members
    }

    static sendRemind(
        parent_ids: string[] = null,
        mem_ids: string[],
        subject: string = null,
        content: string = null,
        show_possible_dates: boolean = false,
        lp_id: string = null,
        parg: ParentRoomGroup = null
    ): Promise<any> {
        let endpoint = `rooms/remind_to_share_schedule`
        if (parg) {
            endpoint = `parent_room_groups/remind_to_share_schedule`
        }

        return axios({
            method: `post`,
            url: `${Util.prefixUrl}/${endpoint}`,
            data: {
                parent_ids: parent_ids,
                mem_ids: mem_ids,
                subject: subject,
                content: content,
                show_possible_dates: show_possible_dates,
                lp_id: lp_id,
                parent_room_group: parg,
            },
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data.appointment)}`)
                Notice.message = `${res.data.message}`

                return ``
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return null
            })
    }

    static sendAgreement() {
        return axios({
            method: `post`,
            url: `${Util.prefixUrl}/user_groups/agree`,
        })
            .then(res => {
                Logger(`agreement res: ${Util.output(res.data)}`)

                return res.data.tutorial_phase
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getUserList(): Promise<RoomMember[]> {
        return axios
            .get(`${Util.prefixUrl}/user_groups/list`)
            .then(res => {
                Logger(`list: ${Util.output(res.data.users)}`)
                let users = RoomMember.fetchFromJson(res.data.users)
                return users
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getSharedList(): Promise<any> {
        return axios
            .get(`${Util.prefixUrl}/user_groups/my_calendar/share`)
            .then(res => {
                Logger(`getSharedList list: ${Util.output(res.data.list)}`)
                let users = RoomMember.fetchFromJson(res.data.list)
                let ctags = CalendarTag.fetchFromJson(res.data.shared_me_list)
                return { users, shared_me_list: ctags }
            })
            .catch(err => {
                Logger(`getSharedList err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static shareMyCalendar(method, email: string, share_type: string) {
        let params = { email: email, share_type: share_type }

        return axios({
            method: method,
            url: `${Util.prefixUrl}/user_groups/my_calendar/share`,
            data: params,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                let users = []
                if (res.data.list) {
                    users = RoomMember.fetchFromJson(res.data.list)
                }
                return users
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 日程決定者のリストを取得します。
     * @param roomId
     * @returns
     */
    static getFixableMembers(roomId: string): Promise<any> {
        return axios
            .get(`${Util.prefixUrl}/public_rooms/${roomId}/fixable_members`)
            .then(res => {
                Logger(`RoomMember.getFixableMembers members:${Util.output(res.data.members)}`)
                let members = RoomMember.fetchFromJson(res.data.members)

                return { members }
            })
            .catch(err => {
                Logger(`getFixableMembers err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static deleteFixableMember(pubId: string, memId: string): Promise<RoomMember> {
        return axios
            .delete(`${Util.prefixUrl}/public_rooms/${pubId}/fixable_members/${memId}`)
            .then(res => {
                Logger(`RoomMember.deleteFixableMember member:${Util.output(res.data.member)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                let member = RoomMember.fetchFromJson([res.data.member])[0]

                return member
            })
            .catch(err => {
                Logger(`deleteFixableMember err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * アドレス帳のユーザー情報を追加/変更します。
     * @param user 追加or編集するユーザー
     * @param sendType "post"/"put"
     */
    static saveAddressBook(user: RoomMember, sendType): Promise<RoomMember> {
        // let endpoint = ``
        // if (sendType == `put`) {
        //     endpoint = `edit`
        // } else if (sendType == `post`) {
        //     endpoint = `create`
        // }

        return axios({
            method: sendType,
            url: `${Util.prefixUrl}/address_books`,
            data: { user: user },
        })
            .then(res => {
                Logger(`list: ${Util.output(res.data)}`)
                return res.data.saved_user
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * アドレス帳を取得してきます。
     */
    static getInvitedUsers(): Promise<RoomMember[]> {
        return axios
            .get(`${Util.prefixUrl}/address_books`)
            .then(res => {
                const users = res.data.invited_users
                let mems = []
                for (const user of users) {
                    let member: RoomMember = user
                    if (member.email == "service@demo.waaq.jp") {
                        continue
                    }
                    mems.push(member)
                }
                return mems
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 自分のイメージを送信します。
     * @param params
     */
    static uplaodUserImage(params): Promise<any> {
        return axios
            .post(`${Util.prefixUrl}/file_uploaders/upload_public_image`, params)
            .then(res => {
                // 同じファイル名を選択した際にchangeイベントが走るようにvalueを初期化する。
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return res.data
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)

                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return null
            })
    }

    static checkNamespace(namespace: string): Promise<any> {
        return axios
            .get(`${Util.prefixUrl}/user_groups/check_namespace?namespace=${namespace}`)
            .then(res => {
                const can_register: boolean = res.data.can_register
                if (can_register) {
                    return { canRegister: true, msgs: null }
                } else {
                    const ng_reasons = res.data.ng_reasons
                    // Notice.message = ng_reasons.join()
                    return { canRegister: false, msgs: ng_reasons }
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return { canRegister: false, msgs: null }
            })
    }

    // セキュリティ事項を取得.
    static getSecurity(): Promise<any> {
        return axios
            .get(`${Util.prefixUrl}/user_groups/security`)
            .then(res => {
                const ipAddresses: string[] = res.data.ip_addresses_whitelist
                return { ipAddresses: ipAddresses }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return { canRegister: false, msgs: null }
            })
    }

    static updateSecurity(dic: any) {
        return axios
            .put(`${Util.prefixUrl}/user_groups/security`, dic)
            .then(res => {
                Notice.message = res.data.message
                const ipAddresses: boolean = res.data.ip_addresses_whitelist
                return { ipAddresses: ipAddresses }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return { canRegister: false, msgs: null }
            })
    }

    /**
     * メンバー（会社名・名前）の情報からanswer_resultsの内容を埋めます
     * @param mem [RoomMember]
     * @param overwrite [boolean] 既にanswer_resultsが作成されている場合に上書きするか.
     */
    static setAnswerResultsFromMember(mem: RoomMember, overwrite = false) {
        if (!mem) return
        if (Util.isPresent(mem.answer_results) && !overwrite) return mem

        let dic = {
            attendee1_name: mem.name,
            attendee1_email: mem.email,
            attendee1_company_name: mem.company_name,
            attendee1_cc_emails: mem.cc_emails,
            attendee1_tel: mem.tel,
        }
        // let dic = {
        //     name: mem.name,
        //     email: mem.email,
        //     company_name: mem.company_name,
        //     cc_emails: mem.cc_emails,
        // }
        mem.answer_results = dic
        return mem
    }

    static addRoomMember(room: Room, member: RoomMember, userInfo: RoomMember) {
        let params = { member: member, id: room.id, role: 10, available_schedule_tag_id: null, send_mail: false, message: null }
        return axios({
            method: `put`,
            url: `${Util.prefixUrl}/rooms/add_room_member`,
            data: params,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                let room = Room.fetchFromJson([res.data.room], userInfo.user_id)[0]

                // const appo: Appointment = res.data.appointment

                return room
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return null
            })
    }

    static updateLocalAnswerResults(mem: RoomMember, editAnswerKey, newValue) {
        if (!mem) return
        if (!mem.answer_results) return
        if (!mem.answer_results[editAnswerKey]) return
        let ans = mem.answer_results[editAnswerKey]
        ans.value = newValue
        return mem
    }

    static removeRoomMember(room: Room, member: RoomMember, userInfo: RoomMember) {
        let params = { id: room.id, user_id: member.user_id }
        return axios({
            method: `put`,
            url: `${Util.prefixUrl}/rooms/remove_room_member`,
            data: params,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                let room = Room.fetchFromJson([res.data.room], userInfo.user_id)[0]
                // const appo: Appointment = res.data.appointment

                return room
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * テキストと（名前、会社名、emailのどれかと）一致する場合はtrueを返します.
     */
    static includeSomeWords(m: RoomMember, _text: string): boolean {
        return (
            (m.name && m.name.toLowerCase().includes(_text)) ||
            (m.company_name && m.company_name.toLowerCase().includes(_text)) ||
            (m.email && m.email.toLowerCase().includes(_text))
        )
    }

    static changeMemberInfo(room: Room, mem: RoomMember, sendMail: boolean, message: string) {
        let params = { id: room.id, member: mem, member_id: mem.user_id, send_fix_member: false, user_message: null }
        if (sendMail) {
            params.send_fix_member = true
            if (message) {
                params.user_message = message
            }
        }
        return axios
            .put(`${Util.prefixUrl}/rooms/room_member/change_info`, params)
            .then(res => {
                Logger(`RESPONSE: /rooms/room_member/change_info ${res.data}`)
                if (res.data.member) {
                    let mem = res.data.member as RoomMember
                    Notice.message = res.data.message
                    return mem
                }

                return null
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static updatePrivateMemo(room: Room, mem: RoomMember) {
        let params = { id: mem?.parent_room_id || room?.id, member: mem, member_id: mem.user_id, private_memo: mem.private_memo }

        return axios
            .put(`${Util.prefixUrl}/rooms/room_member/update_private_memo`, params)
            .then(res => {
                Logger(`RESPONSE: /rooms/room_member/update_private_memo ${res.data}`)
                if (res.data.member) {
                    let mem = res.data.member as RoomMember
                    Notice.message = res.data.message
                    return mem
                }

                return null
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * answer_resultsを更新します.
     * @param room
     * @param mem
     * @param editAnswerKey
     * @param roomId roomにpubic_roomが送られてくる可能性があるため、roomIdを指定した場合、こちらのroomIdを使います.
     * @returns
     */
    static updateAnswerResults(room: Room, mem: RoomMember, editAnswerKey, roomId: string = null) {
        let params = {
            id: mem.parent_room_id || roomId || room.id,
            member: mem,
            member_id: mem.user_id,
            answer_results: mem.answer_results,
            edit_answer_key: editAnswerKey,
        }

        return axios
            .put(`${Util.prefixUrl}/rooms/room_member/update_answer_results`, params)
            .then(res => {
                Logger(`RESPONSE: /rooms/room_member/update_answer_results ${res.data}`)
                if (res.data.member) {
                    let mem = res.data.member as RoomMember
                    Notice.message = res.data.message
                    return mem
                }

                return null
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
