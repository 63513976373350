import axios from "axios"
import { funcName, Logger } from "packs/common"

import Util from "packs/utils/Util"
import { DateTime } from "luxon"
import Notice from "packs/models/Notice"

import Room from "packs/models/Room"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomStorage from "packs/models/RoomStorage"
import NotificationControl from "packs/utils/NotificationControl"
import UserSetting from "packs/models/UserSetting"
import RoomSetting from "packs/models/RoomSetting"
import AppealContent from "packs/models/AppealContent"
import MailFlow from "packs/models/MailFlow"
import FormField from "packs/models/FormField"
import TemplateUtil from "packs/utils/TemplateUtil"
import PossibleDate from "packs/models/PossibleDate"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import ContactListGroup from "packs/models/ContactListGroup"
import LocationTag from "packs/models/LocationTag"
import DateTag from "./DateTag"
import AvailableScheduleRule from "packs/models/AvailableScheduleRule"
import { reactive } from "vue"
const roomStorage = new RoomStorage()
import CalendarUtil from "packs/utils/CalendarUtil"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

/**
 * 主にルームを作成/更新する際に利用します。
 */
interface RoomCreateManager {
    room: Room
    appo: Appointment
    members: RoomMember[]
    astag: AvailableScheduleTag
    userInfo: RoomMember
    room_setting: RoomSetting
    appeal_content: AppealContent
    fields: FormField[]
    mail_flows: MailFlow[]
    assign_fields: FormField[]

    // draft, publishing, suspended, hidden
    publicStatus: string
    selectedMailFlow: MailFlow
    chukaiAstag: AvailableScheduleTag

    edittingAstag: AvailableScheduleTag
    hasUnsaved: boolean
    attendees: RoomMember[]
    pds: PossibleDate[]
    // 調整ページから調整カレンダーに作成しに行く場合に、一度pending状態にして、resetしないでおきます.
    pendingCreateRoom: boolean
    changeOwner: boolean
    showPlanOptionModalWithName: string

    // 公開ページから限定公開作成時に必要なパラメータを保持.
    // user_list Array<{user: RoomMember, mail: MailFlow}>
    user_list: any
    createType: string // only_url, send_mail, except_first_mail
    mf: MailFlow // 全体に適用するMailFlow
    currentSendMem: RoomMember // user_listの選択しているユーザー.
    opMembers: RoomMember[]
    fixMembers: RoomMember[]
    clg: ContactListGroup
    currentTab: string
    currentMail: MailFlow
    showSMSField: boolean
    showLeftPanelContentWithType: string
    edittingRule: AvailableScheduleRule
    periodText: string
    formDisplayType: string // common / assign

    // 限定公開ページグループ作成時に利用するユーザーリスト
    userList: RoomMember[]
    viewType: string // appoInfo, fixer, addressbook
    parg: ParentRoomGroup
    addressbookType: string // block_1, fixer, approval
    fixers: RoomMember[]
    approvers: RoomMember[]

    setNew(
        room: Room,
        appo: Appointment,
        mems: RoomMember[],
        astag: AvailableScheduleTag,
        userInfo: RoomMember,
        us: UserSetting,
        action: string
    )
    reset()

    updateRoom(room: Room)
    updateAppo(appo: Appointment)
    updateMembers(mems: RoomMember[])
    updateAstag(astag: AvailableScheduleTag)
    updateRoomSetting(room_setting: RoomSetting)
    updateAppealContent(apc: AppealContent)
    updateMailFlow(mf: MailFlow)
    findCustomMailFlow(mf: MailFlow)
    removeCustomMailFlow(mf: MailFlow)
    updateFormFields(fields: FormField[])

    calcurateAttendees()
    saveRoom(): Promise<any>
    configureRoom(purpose: string): Room
    saveForPreview(...args: any)
    insertTemplateText(...args: any): string
    demoFixFormat(duration)
    realFields()
    defaultSimpleText(defName: string, ...args): string
    appoTableText(): string
    answeredFormText(): string
    showCandidateDatesText(headerText: string)
    canFix(): boolean
    createParentRoomsFromPublicRoom(
        members: RoomMember[],
        mailsDic: any,
        fixMembers: RoomMember[],
        fixMemberMessage: string,
        endpoint: string,
        settingsDic: any
    )
    updateEdittingRule(rule: AvailableScheduleRule)
    updateSpanText(beforeRs, afterRs)
    updateUserList(mems: RoomMember[])
}

//  データストア
let _RoomCreateManager: RoomCreateManager = reactive({
    room: null,
    appo: null,
    members: null,
    astag: null,
    userInfo: null,
    room_setting: null,
    appeal_content: null,
    publicStatus: ``,
    selectedMailFlow: null, // 選択中のメールフロー
    chukaiAstag: null,
    fields: null,
    assign_fields: null,
    mail_flows: null,

    edittingAstag: null,
    hasUnsaved: false, // 保存していない変更点がある場合、true trueで戻ろうとするとwarningを表示.
    attendees: null,
    pds: null, // 表示する候補日を保持.
    // 途中で調整カレンダーを作成しにいってもデータリセットされないように変更
    pendingCreateRoom: false,

    // Editする場合に、自分の調整ページか判断するためのオーナー情報を持っておきます.
    // 自分の調整ページでない場合、更新時にモーダルを表示し、オーナーを変更していいか確認を入れます。
    changeOwner: false,

    // プラン・オプションに該当がない場合、名前を詰め込みます. watchでRoomSettingsから表示します.
    showPlanOptionModalWithName: null,

    // 公開ページから複数人を同時に作成する場合はこちらを利用します.
    user_list: null,
    createType: ``,
    mf: null,
    currentSendMem: null,
    opMembers: [],
    fixMembers: [],
    clg: null,
    currentTab: null,
    currentMail: null,
    showSMSField: false,
    showLeftPanelContentWithType: null,
    edittingRule: null,
    periodText: null,
    formDisplayType: `common`, // common / assign
    userList: [],
    viewType: `appoInfo`, // appoInfo, fixer, addressbook
    parg: ParentRoomGroup.createDefault(),
    addressbookType: `block_1`, //アドレス帳をクリックした際にいれるブロックの種類
    fixers: [],
    approvers: [],

    setNew(
        room: Room,
        appo: Appointment,
        mems: RoomMember[],
        astag: AvailableScheduleTag,
        userInfo: RoomMember,
        us: UserSetting = null,
        action: string = `new`
    ) {
        this.reset()
        Logger(`RoomCreateManager.setNew::: action:${action} room:${room?.id}, astag:${astag?.name}, appo:${appo?.id}`)
        room = { ...room }
        if (action == `copy` && room.room_type == `public_room`) {
            // page_urlに番号をつけて名前被りしないようにします。
            room.page_key = Math.random().toString(32).substring(2)
            if (Util.isPresent(room.appeal_content) && room.appeal_content.id) {
                room.appeal_content.id = null
                room.appeal_content.presetInfo = false
            }
            room.form_id = null
            let rs = RoomSetting.copy(room.room_setting)

            if (rs) {
                rs.id = null
            }
            this.room_setting = rs
            this.room_setting.auto_share_public_id = null
            room.owner = userInfo

            if (room.is_owner) {
                if (rs?.auto_share_my_room_type == `owner_only`) {
                    this.room_setting.auto_share_public_id = room.public_id
                }
            } else if (room.is_shared) {
                // 共有を受けている公開ページを複製した場合、自分がオーナーの自分の調整カレンダーを利用します.

                room.owner_avaialble_schedule_tag_id = null
            } else {
                room.owner_avaialble_schedule_tag_id = null
            }

            // if (room.is_shared) {
            //     // 共有を受けている公開ページを複製した場合、自分がオーナーの自分の調整カレンダーを利用します.
            //     room.owner = userInfo
            //     room.owner_avaialble_schedule_tag_id = null
            // }
            Logger(`RoomCreateManager.setNew page_url:${room.page_url}, page_key:${room.page_key}`)
        }
        Logger(`RoomCreateManager.setNew 更新されたappeal_content:${Util.output(room.appeal_content)}`)

        if (Util.isBlank(room.appeal_content)) {
            room.appeal_content = AppealContent.createDefault()
        }

        if (Util.isPresent(room.form_fields)) {
            // edit/copyしたときにform_fieldsの必須フィールドをもとに戻す.
            for (let _f of room.form_fields) {
                if ([`attendee1_name`, `attendee1_email`].includes(_f.name_attr)) {
                    _f.canDelete = false
                }
            }
        }

        if (action == `copy`) {
            appo.status = 1
        }

        Logger(`RoomCreateManager.setNew owner:${Util.output(room.owner)}`)
        if (Util.isBlank(room.owner)) {
            this.room.owner = userInfo
        }

        if (Util.isPresent(appo.selectable_locations)) {
            let _loctag = appo.selectable_locations[0]
            _loctag.selected = true
            appo.selected_location = _loctag
            appo.location_name = _loctag.keyword
            appo.location_tag_id = _loctag.id
        }
        this.room = room

        if (appo.location_select_type.includes(`registrable`)) {
            appo.selectable_locations = Appointment.adjustLocations(appo, appo.selectable_locations)
        }
        this.appo = appo
        this.members = mems || []
        this.astag = astag
        this.userInfo = userInfo
        this.calcurateAttendees()

        if (!this.room_setting) {
            this.room_setting = RoomSetting.findOrCreateSetting(room, { ...us })
            this.room_setting.duration = appo.duration
        }

        // 権限が「チャットなし」の場合、チャットをはずします.
        if (!UserPermissionsOption.permitDisplay(this.userInfo, `room_settings`, `room_settings_use_chat`)) {
            this.room_setting.use_chat = false
        }
        this.appeal_content = { ...room.appeal_content }
        if (this.room.room_type == `parent_room`) {
            this.selectedMailFlow = this.room_setting.mail_flows.find(mf => mf.def_type == `create_room`)
        } else {
            this.selectedMailFlow = this.room_setting.mail_flows.find(mf => mf.def_type == `inquire_on_public_room`)
        }
        this.fields = this.room.form_fields
        this.mail_flows = this.room_setting.mail_flows
    },
    reset() {
        this.room = null
        this.appo = null
        this.members = null
        this.attendees = null
        this.astag = null
        this.edittingAstag = null
        this.room_setting = null
        this.appeal_content = null
        this.publicStatus = ``
        this.selectedMailFlow = null
        this.fields = null
        this.mail_flows = null
        this.pendingCreateRoom = false
        this.changeOwner = false
        this.user_list = null
        this.createType = ``
        this.mf = null
        this.currentSendMem = null
        this.opMembers = []
        this.fixMembers = []
        this.clg = null
        this.currentTab = null
        this.currentMail = null
        /**
        userInfo: null,
        chukaiAstag: null,
        hasUnsaved: false, // 保存していない変更点がある場合、true trueで戻ろうとするとwarningを表示.
        pds: null, // 表示する候補日を保持.
         */
    },

    updateRoom(room: Room) {
        this.room = room
        this.saveForPreview()
    },
    updateAppo(appo: Appointment) {
        this.appo = appo
        this.room.current_appointment = appo
        this.room.appointments = [appo]
        this.calcurateAttendees()
        this.saveForPreview()
    },
    updateMembers(mems: RoomMember[]) {
        this.members = mems
        this.calcurateAttendees()
        this.saveForPreview()
    },
    updateAstag(astag: AvailableScheduleTag) {
        this.astag = astag
        this.saveForPreview()
    },
    updateRoomSetting(room_setting: RoomSetting) {
        this.updateSpanText(this.room_setting, room_setting)
        this.room_setting = room_setting
        this.room.room_setting = room_setting
        this.saveForPreview()
    },
    updateAppealContent(apc: AppealContent) {
        this.appeal_content = apc
        this.room.appeal_content = apc
        Logger(`updateAppealContent: ${Util.output(apc)}`)
        this.saveForPreview()
    },
    updateMailFlow(mf: MailFlow) {
        Logger(`${funcName()} ${mf.subject}`)
        let mfs = this.room_setting.mail_flows
        let _mf = null
        if (mf.def_type == `custom`) {
            _mf = this.findCustomMailFlow(mf)
        } else {
            _mf = mfs.find(_mf => _mf.def_type == mf.def_type)
        }

        if (_mf) {
            Object.assign(_mf, mf)
        }
        this.mail_flows = mfs
        let rs = this.room_setting
        rs.mail_flows = this.mail_flows
        this.room.room_setting = rs
        this.saveForPreview()
    },
    findCustomMailFlow(mf: MailFlow) {
        let _mfs = this.mail_flows
        let _mf = _mfs.find(_mf => MailFlow.isSame(_mf, mf))
        return _mf
    },
    removeCustomMailFlow(mf: MailFlow) {
        let _mfs = this.mail_flows
        _mfs = _mfs.filter(_mf => !MailFlow.isSame(_mf, mf))
        this.mail_flows = _mfs
        let rs = this.room_setting
        rs.mail_flows = this.mail_flows
        this.room.room_setting = rs
        this.saveForPreview()
        return _mfs
    },
    updateFormFields(fields: FormField[]) {
        this.fields = fields
        this.room.form_fields = fields
        this.saveForPreview()
    },
    calcurateAttendees() {
        let members: RoomMember[] = this.members ? [...this.members] : []
        let room = { ...this.room }
        let attendees = []
        let owner = members.find(m => m.role == 100)
        if (!owner) {
            let _owner = { ...this.userInfo }
            _owner.role = 100
            members.push(_owner)
        }
        if (members.length <= 2) {
            this.appo.required_owner_participation = true
        }
        for (let _member of members) {
            if (this.appo.required_owner_participation) {
                // 仲介参加の場合、全員が参加
                attendees.push(_member)
            } else {
                // オーナー不参加.
                if (_member.role != 100) {
                    attendees.push(_member)
                }
            }
        }
        Logger(`attendees: ${attendees.map(m => m.name)}`)
        this.attendees = attendees
        this.room.attendees = attendees
        this.room.members = members
        this.members = members
    },

    /**
     * 調整ページを作成します。
     */
    saveRoom(): Promise<any> {
        let room = this.configureRoom(`publish`)
        let endpoint = `rooms/save_and_publish`
        if (room.room_type == `public_room`) {
            endpoint = `public_rooms`
            Logger(`公開ページとして保存します.`)
            room.public_status = Util.isPresent(this.publicStatus) ? this.publicStatus : `publishing`
        } else {
            if (this.publicStatus == `draft`) {
                endpoint = `rooms/save_as_draft`
            }
        }
        room[`change_owner`] = this.changeOwner

        return axios
            .post(`${Util.prefixUrl}/${endpoint}`, {
                room: room,
            })
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                let _newRoom = res.data.room
                let newRoom = Room.fetchFromJson([_newRoom], this.userInfo.user_id)[0]
                let astag = null
                if (res.data.is_change_owner_astag) {
                    astag = AvailableScheduleTag.fetchFromJson([res.data.astag])[0]
                }

                return { room: newRoom, isSuccess: true, astag: astag }
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                const res = err.response ? err.response.data : null
                // Notice.message = `調整ページの作成に失敗しました。 \n管理者にお問い合わせください。`
                // let newRoom = res.room ? Room.fetchFromJson([res.room], userInfo.user_id)[0] : null

                return { room: null, isSuccess: false }
            })
    },

    /**
     * 送信用・調整ページプレビュー用にroomに詰め込みます。
     * @param _room
     * @param _appo
     * @param members
     * @param astag
     * @param purpose [string] 利用目的 `publish`: 調整ページ作成時, `preview` プレビュー表示.
     */
    configureRoom(purpose: string): Room {
        let room: Room = { ...this.room }
        let appo: Appointment = { ...this.appo }
        let rs = { ...this.room_setting }

        // 送信するのに不要なキーを削除.
        if (purpose == `publish`) {
            room = Room.deleteUnusedKeys(room)
            this.members = RoomMember.removeOwner(this.members, this.userInfo)
            let ffs = [...(room.form_fields || [])]
            let newFfs = []
            for (let _ff of ffs) {
                if (_ff.allow_free_text && (_ff.selectable_values || []).length > 0) {
                    _ff.selectable_values = _ff.selectable_values.filter(f => f.value != `その他`)
                }
                newFfs.push(_ff)
            }
            room.form_fields = newFfs
        }

        let astagId = ""

        // 2人の日程調整の場合、オーナーは参加必須です。
        if ((this.members || []).length == 1) {
            appo.required_owner_participation = true
        }
        if (room.room_type == `public_room` || appo.required_owner_participation) {
            if (Util.isPresent(this.astag)) {
                astagId = this.astag.id
                Logger(`連携する astag id: ${astagId} ${this.astag.name}`)
            }
        }

        if (!appo.name || appo.name === ``) {
            appo.name = "打ち合わせ"
        }
        if (room.id == `newId`) {
            delete room.id
        }
        // 権限が「チャットなし」の場合、チャットをはずします.
        if (!UserPermissionsOption.permitDisplay(this.userInfo, `room_settings`, `room_settings_use_chat`)) {
            rs.use_chat = false
        }
        Logger(`configureRoom内のappo前: ${Util.output(appo)}`)
        appo = Appointment.setLocationSelectType(appo, appo.selectable_locations, true)
        Logger(`configureRoom内のappo後: ${Util.output(appo)}`)

        if (purpose == `publish`) {
            room.members = this.members
            room["appointment"] = appo
            if (Util.isPresent(room.room_setting)) {
                if (RoomSetting.hasRedirectUrl(room)) {
                    if (!RoomSetting.isRedirectUrl(room)) {
                        room.room_setting.thanks_page.redirect_url_after_send = null
                    }
                }
            }
        } else if (purpose == `preview`) {
            appo.status = 1
            room.appointments = [appo]
            room.current_appointment = appo
            room.keyId = `preview`
            // 仲介不参加で出席者2以上の場合、chukaiAstagをはめる.（すでに仲介astagを送ってるはず）
            astagId = this.astag.id
            let atts = [...this.members]
            if (!appo.required_owner_participation) {
                atts = RoomMember.removeOwner(atts, this.userInfo)
                this.members = RoomMember.removeOwner(this.members, this.userInfo)
            }

            room.attendees = atts
            room.members = this.members
        }
        room.owner_avaialble_schedule_tag_id = astagId
        room.room_setting = rs

        Logger(`configureRoom 最終的: ${Util.output(room)}`)
        return room
    },

    /**
     * ローカルストレージに情報を保存します。
     * @param room
     * @param userInfo
     * @param astag
     * @param appo
     * @param members
     * @param chukaiAstag
     * @param forceParticateRule 強制的に今の設定しているparticipate_ruleを変更しないでおくか.
     */
    saveForPreview(forceParticipateRule: boolean = false) {
        if (!this.userInfo) return
        if (!this.astag) return
        let _astag = Util.isPresent(this.astag) ? { ...this.astag } : null
        let _appo = Util.isPresent(this.appo) ? { ...this.appo } : null
        let mems = Util.isPresent(this.members) ? [...this.members] : []
        let room = { ...this.room }
        let userInfo = { ...this.userInfo }

        // memberに自分を追加
        if (mems.length == 1) {
            _appo.required_owner_participation = true
            _appo.fix_type == 100
        }
        Logger(`${funcName()} mems人数確認1: ${mems.length} ${_appo.required_owner_participation}`)
        // room.owner = userInfo
        room.current_appointment = _appo
        room.appeal_content = this.appeal_content
        if (!mems.some(m => m.user_id == userInfo.user_id)) {
            mems.push(userInfo)
        }
        Logger(`${funcName()}mems人数確認2: ${mems.length} ${_appo.required_owner_participation}`)
        if (mems.length >= 3) {
            _appo.fix_type == 99
            if (!Room.needOwner(room)) {
                _astag = this.chukaiAstag
                mems = mems.filter(mem => mem.user_id != userInfo.user_id)
            }
        }
        let astagId = _astag.id
        // ルームを表示させるために必要な情報を仕込んで、調整ページに遷移
        room = this.configureRoom(room, _appo, mems, _astag, `preview`, userInfo)

        // room = Room.fetchFromJson([room], userInfo.user_id)[0]

        if (forceParticipateRule) {
            // 元の情報を入れ直す.
            room.current_appointment.required_owner_participation = _appo.required_owner_participation
            if (!Room.needOwner(room)) {
                _astag = this.chukaiAstag
                mems = RoomMember.removeOwner(mems, userInfo)
                let atts = RoomMember.removeOwner(room.attendees, userInfo)
                room.members = mems
                room.attendees = atts
            }
            Logger(`${funcName()}forceParticipateRuleを適用します. ${Util.output(room.members)}`)
        }
        room.keyId = `preview`
        room.owner_avaialble_schedule_tag_id = astagId
        Logger(`${funcName()}プレビュー用のroom: ${Util.output(room)}`)

        let roomStorage = new RoomStorage()
        roomStorage.saveRoomPreview(room)
        roomStorage.saveAppointmentPreview(room.current_appointment)
        let hasUnsaved = true
        this.hasUnsaved = true
        return { room, hasUnsaved }
    },
    /**
     * 文中の変数を代入し、プレビュー文に変更します.
     * @param content [string] メールタイトルまたはメール本文
     */
    insertTemplateText(content: string, defType: string = null): string {
        if (Util.isBlank(content)) return ``
        let atts = this.members.filter(m => m.user_id != this.userInfo.user_id)
        if (Util.isBlank(atts) && this.room.room_type == `public_room`) {
            atts.push(RoomMember.createForPublicRoom())
        }
        let owner = this.members.find(m => m.role == 100) || this.userInfo
        Logger(`rcm.insertTemplateText content: ${content} fields: ${Util.output(this.fields)}`)
        let _appo = Appointment.copy(this.appo)
        _appo.jp_format = this.demoFixFormat()
        let mag = null
        let _astag = this.astag
        if (Util.isPresent(_astag.meeting_attendees_groups)) {
            mag = _astag.meeting_attendees_groups[0]
        }
        let str = TemplateUtil.insertTemplateText(
            content,
            atts,
            owner,
            _appo,
            this.userInfo,
            this.pds,
            this.realFields(),
            defType,
            `rich`,
            this.room,
            mag
        )
        return str
    },
    demoFixFormat(duration = null) {
        if (!duration) duration = this.appo.duration
        return Appointment.createJpFormat(DateTime.local(), duration)
    },
    realFields() {
        if (Util.isBlank(this.fields)) return this.fields

        let rs = this.room_setting
        if (rs && rs.send_channel_type == `sms`) {
            let fields = [...this.fields]
            let ffs = fields.filter(f => f.param_key != `attendee1_email`)
            return ffs
        } else {
            return [...this.fields]
        }
    },
    defaultSimpleText(defName: string, addName = true) {
        let content = ``
        // if (addName) content += `○○株式会社\n●●様\n\n`
        if (defName == `create_room`) {
            content += this.showCandidateDatesText(`以下の日程で「<<調整タイトル>>」のご都合いかがでしょうか。`)
        } else if ([`fix`, `fixed_appointment`].includes(defName)) {
            content += `調整いただきありがとうございます。下記日程で確定しました。\n`
            content += `${this.appoTableText(true)}`
            content += "\n<b>日程のご確認および再調整をされたい場合は、下記リンクからお願い致します\n↓↓↓</b>"
        } else if ([`inquire_on_public_room`].includes(defName)) {
            if (this.selectedMailFlow.show_qa) content += `${this.answeredFormText()}`
            let inquiryTypeText = `日程を確定いたしました。日程の確認やキャンセルはメール下部にありますリンクから調整ページに入り、いつでも確認・変更できます。`
            content += `お問い合わせいただきありがとうございます。\n`
            content += `${inquiryTypeText}\n`
            if (this.selectedMailFlow.show_qa) {
                content += "------------\nお申し込み内容"
            }
            if (this.room.display_schedule_type == `anytime`) {
                content += this.appoTableText(true)
            }
            if (this.selectedMailFlow.show_qa) {
                content += "\n<お申し込み内容が表示されます>\n\n"
            }
        } else if ([`shared_calendar`].includes(defName)) {
            content += this.showCandidateDatesText(`以下の候補が提案されていますが、ご都合いかがでしょうか。`)
            content += "下記調整ページリンクから日程候補を確認し、確定してください。"
        } else if ([`feedback`].includes(defName)) {
            content += `本日はありがとうございました。\n\nよろしければ以下よりアンケートにお答えください。\n\n1分ほどで完了します！`
        } else if ([`the_day_before`].includes(defName)) {
            content += `明日開催予定の出席者にお送りしております。\n`
            let apcTitle = Util.isPresent(this.appeal_content) ? this.appeal_content.title : this.appo.name
            content += this.appoTableText(this.appo, apcTitle)

            content += "\n<b>日程のご確認は、下記リンクからお願い致します\n↓↓↓</b>"
        } else if ([`start_appointment`].includes(defName)) {
            content += `新規で日程調整を開始しました。\n`
            content += this.showCandidateDatesText(`以下の日程でご都合いかがでしょうか。`)
            content += this.appoTableText(this.appo, `<<調整タイトル>>`)
        } else if ([`cancel_appointment`].includes(defName)) {
            content += `<<出席者-1-氏名>>様が日程調整のキャンセルをしました。`
            content += "------------\n"
            content += "<<出席者-1-氏名>>様のキャンセル時のメッセージ\n"
            content += "<キャンセル時のメッセージが表示されます>\n"
            content += "------------\n"
            content += "\n新しい日程調整を作成する場合は調整ページから作成できます。\n"
            let apcTitle = Util.isPresent(this.appeal_content) ? this.appeal_content.title : this.appo.name
            content += this.appoTableText(this.appo, `<<調整タイトル>>`)
        } else if ([`one_day_before_deadline`].includes(defName)) {
            content += "作成した調整ページの有効期限が残り1日となりました。\n"
            content += this.showCandidateDatesText(
                `以下の日程でご都合いかがでしょうか。\nお時間あるときにご確認いただけますと幸いです。`
            )
        } else if ([`request_other_possible_dates`].includes(defName)) {
            content += `<<出席者-1-氏名>>が別日程の提出リクエストをしました。\n`
            content += "------------\n"
            content += "リクエスト時のメッセージ\n"
            content += "<リクエスト時のメッセージが表示されます>\n"
            content += "------------\n"
            content +=
                "日程を多く提案することで、お相手も選びやすくなり一回の候補選択で確定できる確率が高まります。\n以下から調整ページに入り、追加で日程を提案してください。"
        } else if ([`remind_to_share_schedule`].includes(defName)) {
            content += this.showCandidateDatesText(`以下の日程でご都合いかがでしょうか。`)
            content += "\n\n日程のご確定お待ちしております。\n"
        } else if ([`auto_remind`].includes(defName)) {
            content += this.showCandidateDatesText(`未確定の日程調整がありますが、ご予定はいかがでしょうか。`)
            content += "\n\n日程のご確定お待ちしております。\n"
            return content
        } else if ([`reschedule_appointment`].includes(defName)) {
            content += `<<出席者-1-氏名>>様が再調整の依頼をしました。`
            content += "------------\n"
            content += "<<出席者-1-氏名>>様から再調整時のメッセージ\n"
            content += "<再調整時のメッセージが表示されます>\n"
            content += "------------\n"
            content += "\n新しい日程調整を作成する場合は調整ページから作成できます。\n"
            content += this.showCandidateDatesText(`以下の日程が提案されています`)
            return content
        } else if ([`remind_with_message`].includes(defName)) {
            content += "新着のメッセージがあります。\n下記リンクから調整ページに入り、ご確認ください。"
        } else if ([`notify_message`].includes(defName)) {
            content += "新着メッセージがあります。\n下記リンクから調整ページに入り、ご確認してください。"
        } else if ([`custom`].includes(defName)) {
            content += `カスタムの通知内容を作成します。`
        }
        // `日程を確定いたしました。日程の確認やキャンセルは以下のボタンから調整ページに入り、いつでも確認・変更できます。`
        return content
    },
    appoTableText(isFix: boolean = true) {
        let qa = "\n--------------\n"
        qa += "■ <<調整タイトル>>\n"
        if (isFix) {
            qa += `<<確定日時>> (<<所要時間>>)\n`
        }
        qa += `場所: <<場所/オンライン>>\n`
        return qa
    },

    answeredFormText() {
        return ``
    },

    showCandidateDatesText(headerText: string = null) {
        let text = headerText ? `\n${headerText}\n` : ``
        text += `<<日程候補-確定リンクあり>>`
        return text
    },
    canFix() {
        if (!this.appo.fix_type) {
            return false
        }
        if (this.appo.fix_type == 100) {
            return true
        }
        if (this.appo.fix_type == 1) {
            return false
        }
        if (Util.isBlank(this.members) || this.members.length <= 1) {
            return true
        }
        let exceptOwner = this.members.filter(m => m.user_id != this.userInfo.user_id)
        if (exceptOwner.length == 1) {
            return true
        }
        return false
    },
    /**
     * 公開ページから限定公開ページをまとめて作成します.
     * @param members [RoomMember[]]
     * @param createType [string] send_mail
     * @param mailsDic [Hash]
     * @param fixMembers [RoomMember[]]
     * @param fixMemberMessage [String]
     * @param endpoint [string]
     * @param settingsDic [any] {email: any}
     */
    createParentRoomsFromPublicRoom(
        members: RoomMember[],
        mailsDic: any,
        fixMembers: RoomMember[],
        fixMemberMessage: string,
        endpoint: string = `public_rooms/create_room_from_public_room`,
        settingsDic: any = null
    ) {
        let dic = {}
        let userList = []
        if (this.createType == `send_mail`) {
            for (let mem of members) {
                let mailDic = mailsDic[mem.email]
                let settingDic = settingsDic ? settingsDic[mem.email] : null
                // mailDic.content = mailDic.content.replace(TemplateUtil.autoLinkText, ``)
                userList.push({
                    user: mem,
                    mail: mailDic,
                    settings: settingDic,
                })
            }
        } else {
            settingsDic ||= {}
            for (let mem of members) {
                let settingDic = settingsDic ? settingsDic[mem.email] : null
                userList.push({
                    user: mem,
                    mail: null,
                    settings: settingDic,
                })
            }
        }

        if (!endpoint) {
            endpoint = `public_rooms/create_room_from_public_room`
        }

        let params = {
            user_list: userList,
            create_type: this.createType,
            mail_flow: this.mf,
            id: this.room.id,
            fix_members: fixMembers,
            fix_member_message: fixMemberMessage,
            contact_list_group_id: Util.isPresent(this.clg) ? this.clg.id : null,
            parent_room_group: this.parg,
        }

        if (endpoint == `parent_room_groups/create_rooms_group`) {
            let fixUserList = []
            let approvers = []
            for (let mem of this.fixers) {
                let settingDic = settingsDic[mem.email]
                fixUserList.push({
                    user: mem,
                    mail: null,
                    settings: settingDic,
                })
            }

            for (let mem of this.approvers) {
                let settingDic = settingsDic[mem.email]
                approvers.push({
                    user: mem,
                    mail: null,
                    settings: settingDic,
                })
            }
            params[`fix_members`] = fixUserList
            params[`approvers`] = approvers
            params[`duration`] = this.room.current_appointment.duration
        }

        return axios
            .post(`${Util.prefixUrl}/${endpoint}`, params)
            .then(res => {
                Logger(`rcm.createParentRoomsFromPublicRoom ${endpoint} RESPONSE ${res.data.message}`)
                Notice.message = `${res.data.message}`
                /**
                 * ret = {
                    message: render_msg,
                    room: parent.to_h(user: current_user, ug_id: access_ug.id),
                    astag: astag&.to_h(access_user: current_user),
                    room_link: room_link,
                    user_info: access_ug.to_h(user: current_user, display_personal: true, display_role: true),
                    type: inquiry_type,
                }
                 */
                let _newRooms = res.data.parent_rooms_dic
                // let rooms = []
                for (let _dic of _newRooms) {
                    // rooms.push(_dic.room)
                    let parent = Room.fetchFromJson([_dic.room], this.userInfo.user_id)[0]
                    let mem = parent.members.find(m => m.role_name != "owner")
                    _dic.room = parent
                    _dic[`member`] = mem
                }
                // let parents = Room.fetchFromJson(rooms, this.userInfo.user_id)

                return { generated_rooms_dic: _newRooms, isSuccess: true, contact_list_group: res.data.contact_list_group }
            })
            .catch(err => {
                Logger(`rcm.createParentRoomsFromPublicRoom err: ${err.message} ${Util.output(err.response)}`)

                NotificationControl.showErrorMessage(err)

                return { generated_rooms_dic: null, isSuccess: false }
            })
    },
    updateEdittingRule(rule: AvailableScheduleRule) {
        if (Util.isBlank(rule)) {
            return
        }
        let _rule = (this.room.available_schedule_rules || []).find(r => r.id == rule.id)
        if (_rule) {
            _rule.available_schedule_tag_id = rule.available_schedule_tag_id
            _rule.astag_info = rule.astag_info
            this.edittingRule = _rule
            // this.updateRoom(this.room)
        } else {
            if (this.room.available_schedule_rules) {
                this.room.available_schedule_rules.push(rule)
            } else {
                this.room.available_schedule_rules = [rule]
            }

            this.edittingRule = rule
        }
    },
    updateSpanText(beforeRs, afterRs, isForce = false) {
        if (RoomSetting.needUpdateSpanText(beforeRs, afterRs) && !isForce) {
            Logger(`rcm.updateSpanText 期間に変更がないため更新しません.`)
            return
        }

        CalendarUtil.getSpanDescriptionText(afterRs).then(e => {
            Logger(`LinkHeaderRoomSettings#getSpanDescriptionText:${e}, `)
            this.periodText = e[0]
            if (this.cm && this.cm.pdm) {
                Logger(`${funcName()} available_months:${Util.output(e[1])}`)
                this.cm.pdm.available_months = e[1]
                this.cm.pdm.months = e[1]
            }
        })
    },
    updateUserList(mems: RoomMember[]) {
        Logger(`${funcName()} mems:${mems.length}`)
        this.userList = mems
    },
})
export default _RoomCreateManager
