import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger } from "../common"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import DateTag from "packs/models/DateTag"
import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomStorage from "packs/models/RoomStorage"
import RoomHistoryLargeWrapper from "packs/models/RoomHistoryLargeWrapper"
import RoomManager from "packs/models/RoomManager"
import NotificationControl from "packs/utils/NotificationControl"
import AppealContent from "packs/models/AppealContent"
import FormField from "packs/models/FormField"
import RoomSetting from "packs/models/RoomSetting"
import RoomTab from "packs/models/RoomTab"
import CalendarTag from "packs/models/CalendarTag"
import GroupTag from "packs/models/GroupTag"
import AvailableScheduleRule from "packs/models/AvailableScheduleRule"
import FormFieldConverter from "./FormFieldConverter"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

export default class Room {
    static readonly STATUS_DRAFT = -10
    static readonly STATUS_ACTIVE_IN_PROGRESS = 101
    static readonly STATUS_ACTIVE_UPCOMING_FIXED = 111
    static readonly STATUS_ACTIVE_PAST_FIXED = 112
    static readonly STATUS_ACTIVE_CANCELED = 121
    static readonly STATUS_ACTIVE_OTHER = 199

    static readonly STATUS_CLOSED_NOT_FIX = 201
    static readonly STATUS_CLOSED_UPCOMING_FIXED = 211
    static readonly STATUS_CLOSED_PAST_FIXED = 212
    static readonly STATUS_CLOSED_CANCELED = 221
    static readonly STATUS_CLOSED_OTHER = 299

    // 全ステータス一覧 (DRAFT除く)
    static readonly ALL_STATUSES = [
        Room.STATUS_ACTIVE_IN_PROGRESS,
        Room.STATUS_ACTIVE_UPCOMING_FIXED,
        Room.STATUS_ACTIVE_PAST_FIXED,
        Room.STATUS_ACTIVE_CANCELED,
        Room.STATUS_ACTIVE_OTHER,
        Room.STATUS_CLOSED_NOT_FIX,
        Room.STATUS_CLOSED_UPCOMING_FIXED,
        Room.STATUS_CLOSED_PAST_FIXED,
        Room.STATUS_CLOSED_CANCELED,
        Room.STATUS_CLOSED_OTHER,
    ]

    // 確定ステータス一覧
    static readonly FIXED_STATUSES = [
        Room.STATUS_ACTIVE_UPCOMING_FIXED,
        Room.STATUS_ACTIVE_PAST_FIXED,
        Room.STATUS_CLOSED_UPCOMING_FIXED,
        Room.STATUS_CLOSED_PAST_FIXED,
    ]

    constructor(
        public title: string,
        public id: string,
        public public_id: string,
        public room_id: string,
        public appointments: Appointment[],
        public current_appointment: Appointment,
        public is_owner: boolean,
        public is_owner_group: boolean,
        public status: number,
        public status_name: string,
        public is_expired: boolean,

        public duration: number, // 所要時間.
        public memo: string, // 応募職種
        public role: number,
        public role_name: string,
        public location: string,
        public created_at: number,
        public createdAtText: string,
        public updated_at: number,
        public updatedAtText: string,
        public expired_at: number,
        public expiredAtText: string,
        public expiredLastText: string, // 残り○日
        public current_appointment_end_time: number,
        public is_valid: boolean, // 下書きの場合の作成できるかどうか
        public invalid_messages: string[], //下書きで作成不可の場合の不可メッセージ.
        public template_public_id: string,
        public owner_avaialble_schedule_tag_id: string,
        public children: any[],
        public members: RoomMember[],
        // public otherMembers: RoomMember[],
        public attendees: RoomMember[],
        public owner: RoomMember,
        public pics: RoomMember[], // 同じugの担当者
        public shared_schedule_member_ids: string[],
        // public invitations: any[],
        public progress_member_dic: { [key: string]: RoomHistoryLargeWrapper },
        public isParent: boolean,
        public isParentOwner: boolean,
        public parentRoomId: string,
        public keyId: string,
        public unread_num: number,
        public starred: boolean,
        public appeal_content: AppealContent,

        // PublicRoom
        //
        public public_status: string,
        public public_status_name: string,
        // url_name
        public page_key: string,
        //
        public password: string,
        public is_required_password: boolean,
        public use_noindex: boolean,
        // v8 rsに移行
        public use_chat: boolean,
        public display_form_timing_type: string, // open_page, with_fix, after_fix
        public display_schedule_type: string, // anytime, never, rules
        public public_memo: string,
        public private_memo: string,
        public form_fields: FormField[],
        public rule: FormField[],
        public room_type: string,
        public page_url: string,
        public answer_status: string,
        public answer_results: any,
        public form_id: string,
        public progress_status: string, // go, stay

        public room_setting: RoomSetting,
        public rooms_fixed: Room[],
        public rooms_not_voted: Room[],
        public rooms_voted: Room[],
        public rooms_closed: Room[],
        public rooms_voted_num: number,
        public public_room_id: string,
        public is_voted: boolean,
        public access_stats: any, // page_view_total: 0, page_view_weekly: 0, daily_dic: {page_view: 0, start: 0, fixed: 0}
        public max_bookings_num: number,

        public rooms_voted_summary: any,
        public owner_avaialble_schedule_tag_color: string,
        public owner_avaialble_schedule_tag_name: string,
        public current_tab_info: RoomTab,
        public priority_type: string,

        // スグINボタンに紐づくユーザー.
        public instant_room_assigned_users: any[],
        // 共有を受けた公開ページか.
        public is_shared: boolean,
        public group_tags: GroupTag[],
        public available_schedule_rules: AvailableScheduleRule[],
        // AIが利用する質問のpublic_id.
        public available_field_public_ids: string[],
        // ページ作成時に公開ページに紐付けるメッセージテンプレート.
        public default_message_template_id: string,
        public is_shared_chukai: boolean,
        public assign_form_id: string,
        public form_field_converters: FormFieldConverter[],
        public has_detail_converters: boolean,
        public has_detail: boolean,
        public form_submit_button_name: string,
        public form_submit_button_bg_color: string,
        public form_submit_button_text_color: string,
        public form_style_setting: { [key: string]: string },
        public terms_of_service_name: string,
        public privacy_policy_name: string,
        public show_waaq_tos: boolean,
        public show_waaq_pp: boolean
    ) {}

    static isParentDraft(status: number): boolean {
        return status == Room.STATUS_DRAFT
    }

    static isParentActiveInProgress(status: number): boolean {
        return status == Room.STATUS_ACTIVE_IN_PROGRESS
    }

    static isParentCanceled(status: number): boolean {
        return [Room.STATUS_ACTIVE_CANCELED, Room.STATUS_CLOSED_CANCELED].includes(status)
    }

    static isParentActive(status: number): boolean {
        return [
            Room.STATUS_ACTIVE_IN_PROGRESS,
            Room.STATUS_ACTIVE_UPCOMING_FIXED,
            Room.STATUS_ACTIVE_PAST_FIXED,
            Room.STATUS_ACTIVE_CANCELED,
        ].includes(status)
    }

    static isParentActiveWithoutCanceled(status: number): boolean {
        return [Room.STATUS_ACTIVE_IN_PROGRESS, Room.STATUS_ACTIVE_UPCOMING_FIXED, Room.STATUS_ACTIVE_PAST_FIXED].includes(status)
    }

    static isParentFixed(status: number): boolean {
        return Room.FIXED_STATUSES.includes(status)
    }

    static fetchFromJson(groupsJson: any, my_user_id: string): Room[] {
        if (!groupsJson) return []
        let rooms = []
        const now = DateTime.local()

        Logger(`Room.fetchFromJson arrOfGroups: ${Util.output(groupsJson.length)}`)
        for (const room of groupsJson) {
            // let _r = plainToClass(Room, room)
            let _room: Room = room
            // let _room: Room = room
            // let _room: Room = Object.assign(new Room(), _r)
            _room.isParent = _room.room_type == `parent_room`
            _room.isParentOwner = _room.is_owner
            _room.parentRoomId = _room.id
            _room.keyId = this.createKeyId(_room, true)
            _room.is_voted = _room.is_voted

            // ルームの日付関係を入れます。
            if (_room.created_at) {
                let createdAtText = DateTag.getTimeStringSlashFromSeconds(_room.created_at)
                _room.createdAtText = createdAtText
            }
            if (_room.updated_at) {
                let updatedAtText = DateTag.getTimeStringSlashFromSeconds(_room.updated_at)
                _room.updatedAtText = updatedAtText
            }
            if (_room.expired_at) {
                let expiredAtText = DateTag.getTimeStringSlashFromSeconds(_room.expired_at)
                _room.expiredAtText = expiredAtText
                const expiredTime = DateTime.fromSeconds(_room.expired_at)
                let interval = Interval.fromDateTimes(now, expiredTime)
                const days = interval.length("days")

                // Logger(`days: ${days}日, expiredtime: ${expiredAtText}`)
                if (days > 0) {
                    _room.expiredLastText = `残り${Math.floor(days)}日`
                } else if (days < 0) {
                    const hours = interval.length("hours")
                    _room.expiredLastText = `残り${Math.floor(hours)}時間`
                } else {
                    _room.expiredLastText = `終了しました`
                }
            }

            let appo = Appointment.fetchFromJson([room.current_appointment])[0]
            _room.current_appointment = appo
            _room.appointments = Appointment.fetchFromJson(room.appointments)

            // Logger(`current appoを取得してはめます: ${Util.output(_room.current_appointment)}`)

            // 親ルームのメンバーを設定
            let members: RoomMember[] = RoomMember.fetchFromJson(room.members)
            // 同一ugの担当者.
            let pics: RoomMember[] = []
            _room.members = members
            let attendees = []
            for (let _member of members) {
                const mem: RoomMember = _member
                // Logger(`room appo in jSON: ${Util.output(_room)}`)
                if (appo.required_owner_participation) {
                    let magDic = appo.selected_mag_dic
                    if (appo.status == 10 && appo.can_display_mag_in_room) {
                        if (mem.role == RoomMember.MEMBER) {
                        } else {
                            let mag = magDic[mem.user_id]
                            if (mag?.team_name) mem.name = mag.team_name
                            if (Util.isPresent(mag.profile_image_urls)) {
                                let image = mag.profile_image_urls[0]
                                mem.image_url = image
                            } else {
                                mem.image_url = null
                            }
                        }
                    }
                    // 仲介参加の場合、全員が参加
                    attendees.push(mem)
                } else {
                    // オーナー不参加.
                    if (mem.role != 100) {
                        attendees.push(mem)
                    }
                }

                if (_member.user_id == my_user_id) {
                    pics.push(mem)
                }

                // if (_member.role == 100) {
                //     _room.owner = _member
                // }
            }
            _room.title = this.getTitle(members, my_user_id)
            _room.attendees = attendees
            _room.pics = pics

            // _room.room_setting = RoomSetting.findOrCreateSetting(_room, rm.user_setting)

            // Logger(`自分のrole: ${_room.role} ${_room.role_name}`)

            // 子ルームのメンバーを親同様に設定.
            let children = []
            let childrooms = room.children
            if (childrooms && childrooms.length > 0) {
                for (let childroom of childrooms) {
                    let _childroom: Room = childroom
                    let childmembers: RoomMember[] = RoomMember.fetchFromJson(childroom.members)
                    // let childmembers = childroom.members
                    let _atts = childmembers
                    // let _atts = []
                    // for (let _childmember of childmembers) {
                    //     // let childmember: RoomMember = _childmember
                    //     _atts.push(childmember)
                    //     // if (_room.current_appointment.required_owner_participation) {
                    //     //     _atts.push(childmember)
                    //     // } else {
                    //     //     // オーナー不参加.
                    //     //     if (childmember.role != 100) {
                    //     //         _atts.push(childmember)
                    //     //     }
                    //     // }
                    // }
                    // _childroom.title = this.getTitle(_atts)
                    _childroom.title = this.getTitle(_atts, my_user_id)
                    _childroom.attendees = _atts
                    _childroom.isParent = false
                    _childroom.isParentOwner = _room.isParentOwner
                    _childroom.parentRoomId = _room.id
                    _childroom.keyId = this.createKeyId(_childroom, false)
                    // _childroom.keyId = `room${_childroom.id}${_childroom.room_id}${_childroom.parentRoomId}`
                    children.push(_childroom)
                }
            }

            _room.children = children

            if (Util.isPresent(_room.form_fields)) {
                let _fs = FormField.fetchFromJson(_room.form_fields)
                _room.form_fields = _fs
            }
            if (Util.isPresent(_room.group_tags)) {
                _room.group_tags = GroupTag.fetchFromJson(_room.group_tags)
            }
            if (Util.isPresent(_room.rule)) {
                let _fs = FormField.fetchFromJson(_room.rule)
                _room.rule = _fs
            }
            if (Util.isPresent(_room.rooms_fixed)) {
                const _crooms = Room.fetchFromJson(_room.rooms_fixed, my_user_id)
                _room.rooms_fixed = _crooms
            }
            if (Util.isPresent(_room.rooms_voted)) {
                const _crooms = Room.fetchFromJson(_room.rooms_voted, my_user_id)
                _room.rooms_voted = _crooms
            }
            if (Util.isPresent(_room.rooms_not_voted)) {
                const _crooms = Room.fetchFromJson(_room.rooms_not_voted, my_user_id)
                _room.rooms_not_voted = _crooms
            }
            rooms.push(_room)
        }

        return rooms
    }

    static createKeyId(room, isParent: boolean): string {
        if (isParent) {
            return `room${room.id}${room.room_id}${room.parentRoomId}`
        } else {
            return `room${room.id}${room.room_id}${room.parentRoomId}`
        }
    }

    /**
     * リモートに送るときに不要なキーを削除して軽くします。
     */
    static deleteUnusedKeys(room: Room): Room {
        delete room.appointments
        delete room.current_appointment
        delete room.attendees
        delete room.owner
        delete room.pics
        delete room.shared_schedule_member_ids
        // delete room.invitations
        delete room.isParent
        delete room.isParentOwner
        delete room.parentRoomId
        delete room.keyId
        delete room.unread_num
        delete room.created_at, room.createdAtText, room.updated_at, room.updatedAtText
        delete room.expired_at, room.expiredAtText, room.expiredLastText
        delete room.role, room.role_name
        delete room.invalid_messages, room.is_valid, room.starred
        if (room.room_type == `public_room`) {
            delete room.members
            delete room.access_stats
        }
        return room
    }

    /**
     * ルームタイトルを作成します。
     */
    static getTitle(members: RoomMember[], my_user_id): string {
        if (!members || members.length == 0) return ``

        let titleMems = []
        for (let mem of members) {
            let titleMem = ``
            if (mem.user_id != my_user_id) {
                titleMem += `${mem.name}`
                titleMem += mem.short_name ? `(${mem.short_name})` : ``
                titleMems.push(titleMem)
            }
        }
        return titleMems.join(`,`)
    }

    static copy(o: Room): Room {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(o)), o)
        return clone
    }

    // 部屋に、メンバーの役割に応じて紐付けます。
    // static setMemberRole(_room: Room, member: RoomMember): Room {
    //     if (member.role == 1) {
    //         // chukai
    //         _room.chukai = member
    //     } else if (member.role == 2) {
    //         // candidate
    //         _room.candidate = member
    //     } else if (member.role == 3) {
    //         // client
    //         _room.client = member
    //     }
    //     return _room
    // }

    static createDefaultRoom() {
        const time = DateTime.local()
        const appo = Appointment.createDefault()
        return new Room(
            ``,
            ``,
            ``,
            ``,
            [appo],
            appo,
            true,
            true,
            Room.STATUS_ACTIVE_IN_PROGRESS,
            "公開中",
            false,
            60,
            "",
            1,
            "chukai",
            "",
            time.toSeconds(),
            time.toISODate(),
            time.toSeconds(),
            time.toISODate(),
            time.toSeconds(),
            time.toISODate(),
            "残り○日",
            time.toSeconds(),
            true,
            [],
            null,
            null,
            [],
            null,
            [],
            RoomMember.createDefaultClient(),
            [],
            null,
            null,
            true,
            true,
            null,
            "",
            0,
            false,
            // PublicRoom
            AppealContent.createDefault(),
            `draft`,
            `下書き`,
            Math.random().toString(32).substring(2),
            null,
            false,
            true,
            true,
            `with_fix`,
            `anytime`,
            null,
            null,
            FormField.createDefaultFields(),
            null,
            `public_room`,
            null,
            null,
            null,
            null,
            `go`,
            RoomSetting.createDefault(),
            null,
            null,
            null,
            null,
            0,
            null,
            false,
            null,
            1,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            [],
            [],
            null,
            null,
            false,
            null,
            null,
            false,
            false,
            null,
            null,
            null,
            null,
            null,
            null,
            true,
            true
        )
    }

    /**
     * 自分の参加するルーム一覧のみを絞り込みます。
     * @param rooms [Room[]] 全体のルーム
     * @param type  [string]
     * @param userInfo [RoomMember] 自分のユーザー情報
     */
    static filterRooms(rooms: Room[], type: string, userInfo: RoomMember) {
        if (type == "self") {
            return rooms.filter(room => {
                let mems = room.members
                let _mem = mems.find(mem => mem.email == userInfo.email)
                if (_mem) {
                    return room
                }
            })
        }
    }

    /**
     * 自分を参加者から抜きます。
     * @param room
     * @param my_user_id
     */
    static deleteMeFromRoomMembers(room: Room, my_user_id: string): RoomMember[] {
        let mems = []
        const currentMems: RoomMember[] = room.attendees || []
        for (let _mem of currentMems) {
            if (_mem && my_user_id && _mem.user_id == my_user_id) continue
            if (_mem.role_name == `owner`) continue
            mems.push(_mem)
        }
        return mems
    }

    /**
     * 部屋の終了リクエストを行います。
     */
    static close(room: Room) {
        const room_id: string = room.id
        return axios
            .put(`${Util.prefixUrl}/rooms/close`, {
                id: room_id,
            })
            .then(res => {
                return res.data.updated
            })
    }

    /**
     * このルームでメンバーが調整カレンダーを共有しているかを返します。
     * @param room
     * @param mem
     */
    static sharedCalendar(room: Room, mem: RoomMember): boolean {
        if (!room || !mem) return false
        return room.shared_schedule_member_ids.includes(mem.id)
    }

    static needOwner(room: Room): boolean {
        return room.current_appointment.required_owner_participation
    }

    /**
     * 自分がメンバーに入っている場合はtrueを返します。
     * @param room
     * @param myUserInfo
     */
    static attend(room: Room, myUserInfo: RoomMember): boolean {
        let mems = room.members
        return mems.some(m => m.user_id == myUserInfo.user_id)
    }

    /**
     * roomsの中からparentIdが一致するもの、roomIdが子ルームから一致するものを取得してきます。
     * @param rooms
     * @param parentId
     * @param roomId
     */
    static find(rooms: Room[], parentId: string, roomId: string): Room {
        if (Util.isBlank(rooms)) return null
        for (let _room of rooms) {
            if (!_room) continue
            if (_room.id == parentId) {
                return _room
            }
            if (_room.room_id == roomId) {
                return _room
            }
            if (Util.isBlank(_room.children)) continue
            for (let _child of _room.children) {
                if (_child.room_id == roomId) {
                    return _child
                }
            }
        }
    }

    /**
     * 未読があるルームを取得します。
     * @param rooms
     */
    static getUnreadRooms(rooms: Room[]): Room[] {
        return (
            rooms.filter(room => {
                if (room.unread_num > 0) {
                    return room
                }
                if (room.children.find(c => c.unread_num > 0)) {
                    return room
                }
            }) || []
        )
    }

    /**
     * 未読があるルームを取得します。
     * @param rooms
     */
    static getStarredRooms(rooms: Room[]): Room[] {
        return (
            rooms.filter(room => {
                if (room.starred) {
                    return room
                }
            }) || []
        )
    }

    /**
     * 調整ページにスターします。
     * @param parentId
     * @param action
     * @param room_type
     */
    static saveFav(parentId: string, action = `add`, room_type: string): Promise<boolean> {
        const params = { id: parentId, action_type: action, room_type: room_type }

        return axios({
            method: "post",
            url: `${Util.prefixUrl}/favorites/room`,
            data: params,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }

                return res.data.did_success
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return false
            })
    }

    /**
     * 調整ページを取得した際に、新しいものは追加、以前から取得しているものは新しいものに変更します。
     * @param beforeRooms
     * @param newRooms
     * @param sortFunc Array.sort()関数で指定する比較関数
     */
    static mergeRooms(beforeRooms: Room[], newRooms: Room[], sortFunc: any = null) {
        if (Util.isBlank(beforeRooms)) return newRooms
        const beforeRoomsLength = beforeRooms ? beforeRooms.length : null
        const newRoomsLength = newRooms ? newRooms.length : null
        // Logger(
        //     `Room.mergeRooms beforeRooms.length:${beforeRoomsLength} - newRooms.length:${newRoomsLength} sortFunc:${!!sortFunc}`
        // )
        const beforeRoomIds = beforeRooms.map(r => r.id)

        for (let room of newRooms) {
            if (beforeRoomIds.includes(room.id)) {
                let brooms = beforeRooms.filter(r => r.id != room.id)
                brooms.unshift(room)
                beforeRooms = brooms
            } else {
                beforeRooms.unshift(room)
            }
        }
        beforeRooms.sort(sortFunc || RoomTab.defaultSortFunc())
        return beforeRooms
    }

    static getPublicRoom(roomId: string, rm: any): Promise<Room> {
        return axios
            .get(`${Util.prefixUrl}/public_rooms/${roomId}`)
            .then(res => {
                const roomsData = [res.data.room]
                const _rooms = Room.fetchFromJson(roomsData, rm.userInfo.user_id)
                const room = _rooms[0]

                return room
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 下書きにある調整ページを削除します。
     * @param _room
     */
    static delete(_room: Room) {
        // let room: Room = { ..._room }
        let endpoint = `rooms/delete_draft`
        if (_room.room_type == `public_room`) {
            endpoint = `public_rooms`
        }
        let params = { id: _room.id }

        return axios
            .delete(`${Util.prefixUrl}/${endpoint}`, { data: params })
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`

                return true
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * 下書きにある調整ページを削除します。
     * @param _room
     * @param status
     */
    static changeStatus(_room: Room, status: string) {
        // let room: Room = { ..._room }
        let endpoint = `public_rooms/change_status`
        if (_room.room_type == `parent_room`) {
            endpoint = `rooms/${status}`
        }
        let params = { id: _room.id, public_status: status }

        return axios
            .put(`${Util.prefixUrl}/${endpoint}`, params)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`

                return true
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * 下書きにある調整ページを削除します。
     * @param _room
     */
    static deleteAll(userFilter: string) {
        let params = { user_filter: userFilter }
        return axios
            .delete(`${Util.prefixUrl}/rooms/delete_all_drafts`, { data: params })
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                return true
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * 下書きにある調整ページを削除します。
     * @param _room
     */
    static createAll(userFilter: string) {
        return axios
            .put(`${Util.prefixUrl}/rooms/publish_all_drafts?user_filter=${userFilter}`)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                return true
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * 検索して、ルームを取得してきます。
     * @param publicIds
     */
    static search(
        publicIds: string[],
        my_user_id: string,
        memberUserIds: string[],
        memberEmail: string,
        magId: string,
        pubRoomName: string,
        searchCategory: string,
        isParg: boolean = false
    ) {
        const params = {
            public_ids: publicIds,
            user_ids: memberUserIds,
            email: memberEmail,
            mag_id: magId,
            public_room_name: pubRoomName,
            search_category: searchCategory,
        }
        let endpoint = `rooms/search`
        if (isParg) {
            endpoint = `parent_room_groups/search`
        }
        // const paramsSerializer = qs.stringify(params)

        return axios
            .get(`${Util.prefixUrl}/${endpoint}?`, { params: params })
            .then(res => {
                let rooms = []
                if (isParg) {
                    rooms = ParentRoomGroup.fetchFromJson(res.data.parent_room_groups)
                } else {
                    rooms = Room.fetchFromJson(res.data.rooms, my_user_id)
                }

                return rooms
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 直近作成したルームを取得します。
     * @param publicIds
     */
    static getRecentRooms(my_user_id: string) {
        // const params = { public_ids: publicIds }
        // const paramsSerializer = qs.stringify(params)

        return axios
            .get(`${Util.prefixUrl}/recent_rooms`)
            .then(res => {
                let rooms = Room.fetchFromJson(res.data.rooms, my_user_id)
                return rooms
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 調整ページで最初に表示するデータを取得します。
     * @param isGuest
     * @param parentId
     * @param currentRoom [Room]
     */
    static getRoomData(isGuest: boolean, parentId: string, currentRoom: Room) {
        //const {parentId, roomKey, appo, type } = Room.
        let roomStorage = new RoomStorage()
        let roomKeyId
        let appo: Appointment
        let type
        let room: Room
        let openHeader: boolean
        let nextAction = ``
        let start_time: number

        const isPreview = Util.isPreview()

        if (isPreview) {
            roomKeyId = `preview`
            room = roomStorage.fetchRoomPreview()
            appo = roomStorage.fetchAppointmentPreview()
            parentId = `preview`
            type = `confirm`
            nextAction = "confirm"
            Logger(`プレビューモードです: ${Util.output(room.attendees)}`)
            return { parentId, roomKeyId, room, appo, type, openHeader, nextAction, start_time }
        }

        // この部屋にいる間変更されることはないので、コピーして保持します。

        Logger(`this room: ${Util.output(currentRoom)}`)

        if (currentRoom) {
            room = currentRoom
            roomKeyId = currentRoom.keyId
            appo = currentRoom.current_appointment
        }

        parentId = roomStorage.fetchSelectedParentRoomId()
        openHeader = roomStorage.fetchOpenMessageWindow(roomKeyId)
        Logger(`開閉処理確認: openHeader: ${openHeader} key_id: ${roomKeyId} room: ${Util.output(room)} parentId: ${parentId}`)
        if (Util.isPresent(room)) {
            if (room.unread_num > 0) {
                openHeader = true
                roomStorage.saveOpenMessageWindow(roomKeyId, true)
            }
            roomStorage.saveSelectedRoomKey(roomKeyId)
            roomStorage.saveSelectedParentRoomId(parentId)
            roomStorage.saveAppointment(appo)
        }

        type = `confirm`

        // ログイン済みの場合、roomsからリダイレクトされてくるので、保管していたlocalIdに`fix`が入っている場合は、
        // 確定予定の日程です。
        if (appo) {
            if (appo.status == 10) {
                // すでに確定済みの場合は、alreadyに変更.
                type = "already"
                start_time = appo.start_time
                nextAction = "confirm"
            } else if (appo.localId && appo.localId == `fix`) {
                type = `fix`
                nextAction = `fix`
                start_time = appo.start_time as number
            } else if (appo.localId && appo.localId != `refresh`) {
                nextAction = "confirm"
            }
        } else {
            nextAction = "confirm"
        }
        Logger(`Room#getRoomData type: ${type} appo: ${Util.output(appo)} start_time ${start_time}`)
        return { parentId, roomKeyId, room, appo, type, openHeader, nextAction, start_time }
    }

    static getRoomDataFromRails(data: any) {
        Logger(`Railsからデータを受け取りました。${Util.output(data)}`)
        let roomStorage = new RoomStorage()
        // let appo = data.current_appointment as Appointment
        let appo = null
        if (data.current_appointment) {
            appo = Appointment.fetchFromJson([data.current_appointment])[0]
        }

        let userType = data.user_type
        let parentId = data.parent_id
        let nextAction = ``
        let start_time: number
        let userInfo = data.user_info
        let astags: AvailableScheduleTag[] = data.astags || []
        let ctags: CalendarTag[] = data.ctags || []
        let hff: boolean = data.hide_filled_fields

        // 現在の調整ページを保存.
        roomStorage.saveSelectedParentRoomId(data.parent_id)
        const keyId = `room${data.parent_id}${data.rid}null`
        roomStorage.saveSelectedRoomKey(keyId)
        roomStorage.saveAppointment(appo)

        // fixの場合、チェックして確定
        if (data.type == `fix`) {
            nextAction = "fix"
            start_time = appo.start_time
        }
        // すでに確定済みの場合、ただ表示.
        else if (data.type == `already` || data.type == `confirm`) {
            nextAction = "confirm"
            start_time = appo.start_time
            if (!start_time) {
                start_time = Util.getSec()
            }
        }
        // それ以外の場合、日程候補を取得.
        else {
            nextAction = "showPd"
        }
        return { appo, userType, parentId, nextAction, start_time, keyId, userInfo, astags, ctags, hff }
    }

    /**
     * 調整ページで最初に表示するデータを取得します。
     * @param isGuest
     * @param parentId
     * @param currentRoom [Room]
     */
    static getEditableRoomData(isGuest: boolean, parentId: string, currentRoom: Room) {
        //const {parentId, roomKey, appo, type } = Room.
        let roomStorage = new RoomStorage()
        let roomKeyId
        let appo: Appointment
        let type
        let room: Room
        let openHeader: boolean
        let nextAction = ``
        let start_time: number
        let rm = RoomManager

        roomKeyId = `preview`
        room = roomStorage.fetchRoomPreview()
        appo = roomStorage.fetchAppointmentPreview()
        parentId = `preview`
        type = `confirm`
        nextAction = "confirm"
        Logger(`プレビューモードです: ${Util.output(room.attendees)}`)
        return { parentId, roomKeyId, room, appo, type, openHeader, nextAction, start_time }
    }

    /**
     * チャット表示切り替え/日程提案表示切り替え
     * @param room
     * @param type
     */
    static changeDetailStatus(room: Room, type: string, user_id: string, message: string = null): Promise<Room> {
        let params = { id: room.id, type: type, message: message }
        return axios
            .put(`${Util.prefixUrl}/rooms/change_detail_status`, params)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                let _room = Room.fetchFromJson([res.data.room], user_id)[0]
                return _room
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static generateShortUrl(room: Room, data: string): Promise<string> {
        let params = { id: room.id, data: data }
        return axios
            .post(`${Util.prefixUrl}/public_rooms/short_url`, params)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                // let _room = Room.fetchFromJson([res.data.room], user_id)[0]
                return res.data.generated_url
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getOwnerUgId(room: Room): string {
        return room.owner.user_group_id
    }

    /**
     * can_copy_if_voted_fixのステータスを変更します.
     * @param room
     * @returns
     */
    static changeCopyStatus(room) {
        let params = { id: room.id }
        return axios
            .put(`${Util.prefixUrl}/rooms/change_copy_status`, params)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                // let _room = Room.fetchFromJson([res.data.room], user_id)[0]
                return res.data.room
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static updateRoomGroupSetting(room: Room, memo: string, groupTags: GroupTag[]) {
        let params = { id: room.id, private_memo: memo, group_tags: groupTags }
        return axios
            .post(`${Util.prefixUrl}/public_rooms/private_memo`, params)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                // let _room = Room.fetchFromJson([res.data.room], user_id)[0]
                return { success: true, memo: res.data.private_memo || "", group_tags: res.data.group_tags }
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getPossibleInstantRoom(pubId: string) {
        let params = { id: pubId }
        return axios
            .post(`${Util.prefixUrl}/instant_rooms/possible_instant_room`, params)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                // Notice.message = `${res.data.message}`

                return { success: true, res: res }
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return { success: false, res: null }
            })
    }

    static getPublicRoomWithVotedRooms(pubId: string, parentId: string, userInfo: RoomMember): Promise<Room> {
        return axios
            .get(`${Util.prefixUrl}/public_rooms/${pubId}?parent_room_id=${parentId}`)
            .then(res => {
                if (res.data.room) {
                    const roomsData = [res.data.room]
                    const _rooms = Room.fetchFromJson(roomsData, userInfo.user_id)
                    const room = _rooms[0]
                    return room
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getPublicRoomWithVotedRoomsForDisplayAll(pubId: string, userInfo: RoomMember): Promise<Room> {
        return axios
            .get(`${Util.prefixUrl}/public_rooms/${pubId}/voted_rooms`)
            .then(res => {
                if (res.data.room) {
                    const roomsData = [res.data.room]
                    const _rooms = Room.fetchFromJson(roomsData, userInfo.user_id)
                    const room = _rooms[0]
                    return room
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static addDefaultTemplate(room: Room, templateId: string) {
        let params = { template_id: templateId }
        return axios
            .post(`${Util.prefixUrl}/public_rooms/${room.id}/default_template`, params)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }

                return { success: true, default_message_template_id: res.data.default_message_template_id }
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return { success: false, res: null }
            })
    }

    static conflictCheck(publicRoom: Room, params): Promise<any> {
        // let params = { ok_schedules: suggestedDates, id: publicRoom.id }
        return axios
            .post(`${Util.prefixUrl}/public_rooms/conflict_check`, params)
            .then(res => {
                Logger(`Room.conflictCheck res:${Util.output(res.data)}`)
                return res.data
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
