
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagLog, gtagClick, gtagPage, gtagPageOwner } from "packs/GoogleTagManager"
import FormUtil from "packs/utils/FormUtil"
import Const from "packs/utils/Const"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import ScheduleMainContent from "packs/pages/schedule/ScheduleMainContent.vue"
import ScheduleUnderContent from "packs/pages/schedule/ScheduleUnderContent.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import DataContent from "packs/components/data/DataContent.vue"
import AppointmentCardViewLarge from "./AppointmentCardViewLarge.vue"
import RoomMessages from "packs/pages/link/RoomMessages.vue"
import RoomMembersMenu from "./RoomMembersMenu.vue"
import LinkHeader from "packs/components/link/LinkHeader.vue"
import ModalInputMessage from "packs/pages/link/modals/ModalInputMessage.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import FixLoadingModal from "packs/pages/link/modals/FixLoadingModal.vue"
import CalendarPinkButton from "packs/pages/schedule/CalendarPinkButton.vue"
import ImagePreviewModal from "packs/components/images/ImagePreviewModal.vue"
import ScheduleAppealContent from "packs/pages/schedule/ScheduleAppealContent.vue"
import NewMessageModal from "packs/pages/link/modals/NewMessageModal.vue"
import CalendarsMenu from "packs/pages/schedule/CalendarsMenu.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"
import FilledScheduleModal from "packs/pages/schedule/FilledScheduleModal.vue"
import InstantRoomContent from "packs/pages/schedule/InstantRoomContent.vue"
import FormContentModal from "packs/pages/schedule/modals/FormContentModal.vue"
import AssignFormMainContent from "packs/pages/schedule/AssignFormMainContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Refresher from "packs/models/Refresher"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import PossibleDate from "packs/models/PossibleDate"
import UserFile from "packs/models/UserFile"
import CalendarManager from "packs/models/CalendarManager"
import RoomStorage from "packs/models/RoomStorage"
import CalendarUtil from "packs/utils/CalendarUtil"
import Timezones from "packs/utils/Timezones"
import NotificationControl from "packs/utils/NotificationControl"
import AstagsTab from "packs/models/fetcher/AstagsTab"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        DataContent,
        AppointmentCardViewLarge,
        RoomMessages,
        RoomMembersMenu,
        LinkHeader,
        ModalInputMessage,
        IconLock,
        FixLoadingModal,
        CalendarPinkButton,
        ImagePreviewModal,
        ScheduleMainContent,
        ScheduleUnderContent,
        ScheduleAppealContent,
        NewMessageModal,
        CalendarsMenu,
        AlertModal,
        FilledScheduleModal,
        InstantRoomContent,
        FormContentModal,
        AssignFormMainContent,
    },
})
export default class ScheduleView extends Vue {
    // data:

    @Ref()
    ScheduleMainContent

    @Ref()
    cardViewLarge

    @Ref()
    roomMessagesView

    @Ref()
    NewMessageModal

    notice = Notice
    $store: any
    rm = RoomManager
    pdm = PossibleDatesManager
    cm = CalendarManager
    isSP = isMobile(window.navigator).phone

    astag: AvailableScheduleTag = null
    href
    // duration = null;
    appo: Appointment = null
    type = `` // `fill`, `overstart`, `overdue`, `already`, `fix`
    naType = null
    roomKeyId: string = null
    // selectablePdm = null
    // pd: PossibleDate = null
    currentPossibleDates = null
    parentId = null

    room: Room = null
    selectedTab = `apc` // スマホタブ: appo / schedule / apc: ヘッダーのみ

    userType: string = `` // user or guest
    showPossibleDatesTypes = [`fill`, `overstart`, `overdue`, `confirm`, `fix`]
    // fixの場合のstart_time
    start_time: number = null

    // 利用規約同意画面を表示したときに利用.(available_scheduleとの出し分け.)
    // fix="利用規約に同意して、日程を確定", showPd="利用規約に同意して、日程候補を確認"
    // actionは次とるべき行動が入ります、
    nextAction = ``
    loading = false

    openHeader = false
    refresher = Refresher
    Const = Const
    Util = Util
    truncate = truncate
    isGuest = Util.isGuest()
    gettingPds: boolean = false
    isEmbedsInstantButton = Util.isEmbedsInstantButton()
    instantRoomPd: PossibleDate = null

    showPinkButton = false
    isPreview = Util.isPreview() // 調整ページ作成時のプレビュー画面か.

    currentFile: UserFile = null // イメージプレビューをするファイル.

    answerdForm: boolean = false // フォームに回答したか.
    additionalParams = null
    fullwindow = true // ウィンドウの横幅を計算して、1200pxより大きい場合にtrueを入れます.

    public created() {
        this.href = location.href
        Logger(`${funcName()} location.href:${location.href}`)

        let status = this.$store.state.activeStatus
        if (status.length == 0) this.$store.dispatch("start")
        this.getAdditionalParams()
        // this.transparentBackground()

        this.calculateWindowWidth()
        window.addEventListener("resize", this.calculateWindowWidth)

        this.getLocalTimezone()
    }

    getLocalTimezone() {
        Timezones.getLocalTimezone().then(dic => {
            let tzDic = dic
            Logger(`${funcName()} LocalTZを取得しました: tzDic:${Util.output(tzDic)}`)
            if (Util.isPresent(tzDic)) {
                this.pdm.changeTimezone(tzDic)
            }
        })
    }

    public unmounted() {
        this.pdm.resetPdm()
        this.pdm.resetInfo()
        $("body").removeClass("lskyblueBg")
    }

    resetPossibleDates() {
        Logger(`${funcName()}`)
        this.pdm.resetPdm()
        this.currentPossibleDates = null
        if (this.ScheduleMainContent) {
            this.ScheduleMainContent.currentPossibleDates = null
        }
    }

    public mounted() {
        gtagPage("#/調整ページ")

        /**
         * このページで利用する情報を取得してきます。（Astag情報取得前）
         */
        if (this.rm.currentRoom || Util.isPreview()) {
            const { parentId, roomKeyId, room, appo, type, openHeader, nextAction, start_time } = Room.getRoomData(
                this.isGuest,
                this.parentId,
                this.rm.currentRoom
            )
            ;(this.parentId = parentId), (this.roomKeyId = roomKeyId)
            ;(this.room = room), (this.appo = appo), (this.type = type), (this.openHeader = openHeader)
            this.rm.currentMessageRoom = this.room
            ;(this.nextAction = nextAction), (this.start_time = start_time)
            this.updateAppo()
        } else {
            // オーナーがリフレッシュしたときに呼ばれます.
            if (!this.isGuest && !Util.isEmbeds()) {
                this.roomKeyId = roomStorage.fetchSelectedRoomKey()
                this.parentId = roomStorage.fetchSelectedParentRoomId()
                if (!Util.isPublic()) this.openHeader = roomStorage.fetchOpenMessageWindow(this.roomKeyId)
            }
        }

        // roomがなければここで取得を停止します。（Rails経由で取得してくるため.）
        if (this.parentId) this.getUserInfo(this.roomKeyId, this.type)

        window.onbeforeunload = function (e) {
            e = e || window.event
            // parentId以外は削除.
            if (!Util.isPreview() && !Util.isEmbeds()) {
                Logger(`${funcName()} リロードされます.`)
                roomStorage.saveRefresher(`room`)
            }
        }
        this.updateHasNewMessage()
        this.transparentBackground()
    }

    updated() {
        this.showShadow()
    }

    get tooltipName() {
        if (Util.isBlank(this.rm.currentMessageRoom)) return null
        if (Util.isBlank(this.rm.currentMessageRoom.members)) return null
        // let names = []
        let names = this.rm.currentMessageRoom.members.map(
            m => `${m.company_name ? `${m.company_name} ` : ` `}${m.name ? m.name : ``}`
        )
        return names.join(`\n`)
    }

    /**
     * ゲストの場合、Railsからもらった情報で判断を開始します。
     */
    public updateDataFromRails(data) {
        Logger(`${funcName()} type:${data?.type}, public_room_id:${data?.public_room_id}, room:${data?.room?.id}}`)
        // if (this.isPreview) return
        if (data.type == `na`) {
            this.showNAView()
            return
        }

        if (data.public_room_id) {
            Logger(`${funcName()} data.public_room_id:${data.public_room_id}, room:${Util.output(data.room)}`)
            this.room = Room.fetchFromJson([data.room], null)[0]
            this.appo = this.room.current_appointment
            this.userType = data.user_type
            this.type = data.type
            this.rm.userInfo = data.user_info || RoomMember.createDefaultClient()
            this.rm.didConnectCal = data.did_connect_cal
            this.rm.updateCurrentInfo(this.room)
            this.pdm.setNew(this.room, this.rm.userInfo, this.appo, this.rm)
            // this.rm.currentRoom = this.room
            this.parentId = this.room.id

            if (Util.isPresent(data.astags)) this.rm.astags = data.astags
            if (Util.isPresent(data.ctags)) this.cm.ctList = data.ctags
            if (Util.isPresent(data.user_setting)) this.rm.user_setting = data.user_setting
            if (Util.isPresent(data.tutorial_phase)) this.rm.tutorial_phase = data.tutorial_phase
            this.updateRoomDataAfterFetch()
            if (Util.isEmbeds()) {
                let notificationType = NotificationControl.notificationTypeOpenPage
                let notiParams = { type: notificationType }
                NotificationControl.notifyParent(notificationType, notiParams)
            } else {
                Logger(`gtagPageOwner: ${this.room.page_url}${location.search}`)
                if (Util.isPublic()) {
                    gtagPageOwner(`${this.room.page_url}${location.search}`, {})
                } else {
                    gtagPageOwner(this.room.page_url, {})
                }
            }

            // gtagPageOwner(this.room.page_url, {})
            // gtagPageOwner(`G-E9Q2B0X15T`, `${this.room.public_id}`, {})
            return
        }

        const { appo, userType, parentId, nextAction, start_time, keyId, userInfo, astags, ctags, hff } =
            Room.getRoomDataFromRails(data)
        ;(this.appo = appo), (this.userType = userType)
        ;(this.nextAction = nextAction), (this.start_time = start_time), (this.rm.userInfo = userInfo)
        if (parentId) this.parentId = parentId

        if (Util.isPresent(astags)) {
            this.rm.astags = astags
            this.rm.astagsTab = AstagsTab.createNew(astags, [], data.astags_tab_info)
            let defaultType = data?.astag_default_type || 1
            this.rm.setAstag(defaultType)
        }
        if (Util.isPresent(data.ctags)) this.cm.ctList = ctags
        if (Util.isPresent(data.user_setting)) this.rm.user_setting = data.user_setting
        if (Util.isPresent(data.tutorial_phase)) this.rm.tutorial_phase = data.tutorial_phase
        this.rm.didConnectCal = data.did_connect_cal
        this.rm.hide_filled_fields = hff
        this.rm.parent_room_group_id = data.parent_room_group_id
        this.rm.lp_id = data.lp_id
        this.rm.parg = data.parg ? ParentRoomGroup.fetchFromJson([data.parg])[0] : null
        // if (!this.isGuest) {
        //         this.roomKeyId = roomStorage.fetchSelectedRoomKey()
        //         this.parentId = roomStorage.fetchSelectedParentRoomId()
        //         if (!Util.isPublic()) this.openHeader = roomStorage.fetchOpenMessageWindow(this.roomKeyId)
        //     }

        // Railsから来た場合roomデータを持っていないので、refresherにroomを入れておきます。
        roomStorage.saveRefresher(`room`)

        if ([`fix`, `already`].includes(data.type)) {
            roomStorage.saveScheduleStartTime(this.start_time)
        }

        this.getUserInfo(keyId, data.type)
    }

    showNAView() {
        if (this.rm.parent_room_group_id) {
            this.naType = `parg_not_turn`
        }
        this.type = `na`
        $("body").addClass("lskyblueBg")
    }

    public getUserInfo(roomKeyId: string, type: string) {
        Logger(`${funcName()} roomKeyId:${roomKeyId}, type:${type}`)
        if (!this.isPreview && Util.isPublic()) return
        /**
         * astagページに遷移させる必要はないので、リダイレクトループにならないようチェック不要で取得してきます。
         */
        let pid = this.parentId
        if (this.isGuest) {
            Logger(`${funcName()} astagに送る this.parentId:${this.parentId}, roomKeyId:${roomKeyId}, type:${type}`)
            if (!this.parentId) {
                this.type = type
                this.roomKeyId = roomKeyId
                if (this.type == `na`) this.showNAView()

                if (this.rm.currentRoom) this.parentId = this.rm.currentRoom.id

                if (!this.parentId) return
            }
        }

        if ([`parg_not_turn`, `na`].includes(type) || !pid) {
            Logger(`${funcName()} 防ぎました.`)
            this.showNAView()
            return
        }

        if (this.room) this.rm.updateCurrentInfo(this.room)

        this.astag = this.rm.astag ? { ...this.rm.astag } : { ...this.rm.defaultAstag }
        this.buildData()
        this.roomKeyId = roomKeyId
        this.type = type
    }

    public buildData() {
        Logger(`${funcName()} isPreview:${this.isPreview} rm.currentRoom:${this.rm.currentRoom?.id}`)
        // ログインしたことがなかったりユーザー情報を確認していない場合、モーダルで使い方の説明をはさみます。

        // let refresher = Util.isEmbeds() ? [] : roomStorage.fetchRefresher()
        if (this.isPreview) {
            this.updateRoomDataAfterFetch()
            return
        }

        if (!this.rm.currentRoom) {
            Logger(`${funcName()} currentRoomがないため取得し直します parentId:${this.parentId}`)
            if (this.parentId && !this.isPreview) {
                if (this.loading) return
                this.loading = true
                this.rm.getRoom(this.parentId).then(_room => {
                    if (Util.isPresent(_room)) {
                        this.room = _room
                        this.appo = _room.current_appointment
                        this.rm.updateCurrentInfo(this.room)
                        // roomStorage.removeRefresher()
                        this.updateRoomDataAfterFetch()
                    }
                    this.loading = false
                })
            }
        } else {
            Logger(`${funcName()} currentRoomはある ${Util.output(this.room)}`)
            this.updateRoomDataAfterFetch()
        }
    }

    updateRoomDataAfterFetch() {
        if (!this.room) this.room = this.rm.currentRoom
        Logger(`${funcName()} nextAction:${this.nextAction}`)

        if (Util.isPresent(this.room)) {
            // roomStorage.saveSelectedRoomKey(this.room.keyId)
            if (Util.isBlank(this.rm.currentMessageRoom)) {
                this.rm.currentMessageRoom = this.room
            }
        }

        if (!this.appo) {
            this.appo = this.rm.currentAppointment
            // roomStorage.saveAppointment(this.appo)
        }

        this.pdm.setNew(this.room, this.rm.userInfo, this.appo, this.rm)
        this.pdm.getCalendarShowType(this.room?.room_setting, this.isSP)

        if (Util.isBoard()) {
            Logger(`公開ボードの場合、日程候補は取得不要です.`)
            return
        }

        // 確定かキャンセルでなければ、候補を取得.
        if (this.appo && ![10, -1].includes(this.appo.status)) {
            if (this.rm.didConnectCal && Util.isPresent(this.cm.ctList)) {
                // カレンダー連携している場合は、連携しているカレンダーをpossibleDatesに入れて送るため、ここではリターンさせる.
                let ctags = [...(this.cm.ctList || [])]
                let ct = ctags.find(ct => ct.is_provider_default)
                if (Util.isPresent(ct)) return
            }
            this.getPossibles()
        }
    }

    @Watch("refresher", { deep: true })
    public refreshData() {
        Logger(`${funcName()} refresher:${Util.output(this.refresher)}`)
        if (this.refresher.needRefresh.includes(`pdm`)) {
            // this.pdm = null
            this.refresher.needRefresh = this.refresher.needRefresh.filter(e => e != `pdm`)
            if (this.appo && ![10, -1].includes(this.appo.status)) this.getPossibles()
        }
        if (this.refresher.needRefresh.includes(`room`)) {
            this.refresher.needRefresh = this.refresher.needRefresh.filter(e => e != `room`)
            // roomを取得してはめます.
            // this.updateRoomDataAfterFetch()
            Logger(`${funcName()} Room:${Util.output(this.rm.currentRoom)}`)
            this.room = this.rm.currentRoom
            this.appo = this.rm.currentAppointment
            // roomStorage.saveAppointment(this.appo)
            sleep(500).then(() => {
                this.ScheduleMainContent?.scrollToContentTop()
            })
        }
    }

    public goLP() {
        gtagClick(`遷移: FIX → LP`, `UnderAd`)
        // location.href = `https://link.waaq.jp/`;
        open(`https://link.waaq.jp/`, `_blank`)
    }

    public goAvailableScheduleView() {
        gtagClick(`遷移: FIX → AstagView`, `pinkButton`)
        // もし仲介で、仲介不参加の場合、不参加カレンダー設定ページにリダイレクトします。
        if (this.room.is_owner && !this.appo.required_owner_participation) {
            this.$router.push(`/settings/not_attendees_calendar`)
        } else {
            this.$router.push(`/available_schedule`)
        }
    }

    public addNewAppo() {
        Logger(`${funcName()} ScheduleView`)
        this.cardViewLarge.addNewAppointment()
    }

    // タイプが変更されたときに、fill, overstart, overdue の場合、再度pdmsを取得しなおして表示.
    public getPossibles(needRefresh = true, month = null, checkConflictsAstag = null, checkConflictsCtags = null, week = null) {
        // this.ScheduleMainContent.getPossibles()
        if (!this.room) {
            if (this.rm.currentRoom) this.room = this.rm.currentRoom
            if (!this.room && this.pdm.room) this.room = this.pdm.room
        }
        if (!this.type) {
            this.updateAppo()
        }
        if (!this.room) return
        Logger(
            `${funcName()} needRefresh:${needRefresh}, type:${this.type}, pdm.displayType:${
                this.pdm.displayType
            }, month:${month}, week:${week}, calendarShowType:${this.pdm.calendarShowType}`
        )
        if (this.isPreview) {
            Logger(
                `${funcName()} getPossibles Preview: astag: ${Util.output(this.astag)}, astags: ${Util.output(this.rm.astags)}`
            )
            if (!this.astag) return
            if (!this.rm.astags) return
            if (this.room.owner_avaialble_schedule_tag_id) {
                this.astag = (this.rm.astags || []).find(a => a.id == this.room.owner_avaialble_schedule_tag_id)
            }
            Logger(`${funcName()} プレビューで取得します利用するastagを表示:[${this.astag.name}] ${Util.output(this.astag)}`)
            this.ScheduleMainContent.getPreviewEvents(this.astag, null, this.room.room_setting, needRefresh, month)
            return
        }
        Logger(
            `${funcName()} currentPossibledates(${this.currentPossibleDates?.length}):${Util.output(
                this.currentPossibleDates
            )}, isPublic:${Util.isPublic()}`
        )

        if (!(month || week) && Util.isPublic() && Util.isPresent(this.currentPossibleDates)) {
            // publicで日程がすでにある場合、場所変更しても取得情報が変わらないので、ここでリターン.
            return
        }

        // const getPDArray = [`fill`, `overstart`, `overdue`];
        if (!this.type) return

        if (!this.showPossibleDatesTypes.includes(this.type)) return

        Logger(`${funcName()} appo.status:${this.appo?.status}`)

        if (!this.appo || [10, -1].includes(this.appo.status)) {
            Logger(`${funcName()} 114`)
            if (this.pdm.displayType != `reschedule`) {
                this.currentPossibleDates = []
                return
            }
        }
        Logger(`${funcName()} gettingPds:${this.gettingPds}, needRefresh:${needRefresh}`)

        if (this.gettingPds) return
        this.gettingPds = true

        if (needRefresh) {
            this.currentPossibleDates = null
        }

        Logger(
            `${funcName()} 未確定の案件のため再度日程候補を取得してきます。 parentId:${this.parentId}, this.room:${Util.output(
                this.room
            )}, rm.currentRoom: ${Util.output(this.rm.currentRoom)}, month:${month}, needRefresh:${needRefresh}`
        )

        if (!this.room && this.rm.currentRoom) this.room = this.rm.currentRoom

        let exceptSelf = this.ScheduleMainContent && !this.room.is_owner ? this.ScheduleMainContent.exceptSelf : false
        Logger(`${funcName()} exceptSelf:${exceptSelf}`)
        if (!checkConflictsAstag && this.cm.astag) {
            checkConflictsAstag = this.cm.astag
        }
        if (!checkConflictsCtags && this.cm.registrable_attendees) {
            checkConflictsCtags = this.cm.registrable_attendees
        }

        this.pdm.getCalendarShowType(this.room.room_setting, this.isSP)

        if (!week && this.pdm.calendarShowType == `weekly`) {
            week = CalendarUtil.toWeekSpan(DateTime.local())
            Logger(`${funcName()} weekly week:${week}`)
        } else if (!month && this.pdm.calendarShowType == `monthly`) {
            month = CalendarUtil.toCalId(DateTime.local())
        }
        this.rm.startProgress()

        this.pdm
            .getPossibleDates(
                this.room,
                exceptSelf,
                needRefresh,
                month,
                checkConflictsAstag,
                checkConflictsCtags,
                this.rm.guestKey,
                null,
                null,
                week
            )
            .then(posDates => {
                gtagLog("event", "get", {
                    event_category: `調整ページpd取得`,
                    event_label: `表示件数: ${(this.pdm.possibleDates || []).length}, shared: ${
                        (this.pdm.sharedMembers || []).length
                    } / ${(this.rm.currentRoom ? this.rm.currentRoom.members : null || []).length}`,
                })
                const avMonths = this.pdm.available_months
                let months = this.pdm.months
                let gettableMonths = Util.arraySabun(avMonths, months)

                const avWeeks = this.pdm.available_weeks
                let weeks = this.pdm.weeks
                let gettableWeeks = Util.arraySabun(avWeeks, weeks)
                this.gettingPds = false
                let _posDates = this.pdm.possibleDates
                Logger(
                    `ScheduleView.getPossibles 可能日:${(_posDates || []).length} gettableMonths:${Util.output(
                        gettableMonths
                    )}, gettableWeeks:${Util.output(gettableWeeks)}`
                )

                if ((_posDates || []).length == 0 && (gettableMonths.length > 0 || gettableWeeks.length > 0)) {
                    if (this.pdm.calendarShowType == `weekly`) {
                        this.getPossibles(false, null, checkConflictsAstag, checkConflictsCtags, gettableWeeks[0])
                    } else {
                        this.getPossibles(false, gettableMonths[0])
                    }
                } else {
                    this.currentPossibleDates = _posDates || []
                    if ((_posDates || []).length == 0) {
                        this.pdm.possibleDates = []
                    }

                    if (this.nextAction == `fix`) {
                        // hangai: メール上から来たので、確定モーダルを表示します.
                        // this.checkAndfixAppo()
                        Logger(
                            `${funcName()} nextActionがfixなのでモーダルを表示します. st:${this.start_time}, duration:${
                                this.appo.duration
                            }`
                        )
                        // this.$vfm.open("FixScheduleSmallModal")
                        let pd = PossibleDate.createFromStartTime(this.start_time, this.appo.duration)
                        this.ScheduleMainContent.confirmFixSchedule(pd)
                    }
                }

                // Logger(`***********this pdm: ${Util.output(this.pdm)}`);
                this.rm.endProgress()
            })
    }

    @Watch("rm.currentAppointment", { deep: true })
    public updateAppo() {
        this.appo = this.rm.currentAppointment
        if (!this.appo) return
        Logger(`${funcName()} appoの更新がはしりました。 this.appo:${Util.output(this.appo)}`)
        if (this.appo.status == 10) {
            if (this.pdm.displayType == `reschedule`) {
                this.type = `confirm`
            } else {
                this.type = "already"
                this.currentPossibleDates = []
            }
        } else if (this.appo.status == -1) {
            this.type = "canceled"
            this.currentPossibleDates = []
        } else {
            if (this.type != `fix`) {
                this.type = "confirm"
            }
        }
        // Appoが更新されたら、possible_datesを叩き直します。
        // else {
        //     // PublicRoomの場合は場所情報を更新してもリモートで更新かけていないので、リフレッシュ不要.
        //     if (!Util.isPublic()) this.currentPossibleDates = null
        //     if (this.type != `fix`) {
        //         this.type = "confirm"
        //     }
        //     this.getPossibles()
        // }
    }

    @Watch("type")
    public saveType() {
        Logger(`${funcName()} typeを保存します。${this.type}`)
        // roomStorage.saveScheduleType(this.type)
    }

    /**
     * 開閉処理
     */
    public closeMessageHeader() {
        gtagClick(`messageWindow 閉じる`, ``)
        this.openHeader = false
        // roomStorage.saveOpenMessageWindow(this.roomKeyId, this.openHeader)
    }

    /**
     * スマホでタブを押下したときの処理.
     * tabType: appo: CardView / schedule: 日程調整
     */
    public selectTab(tabType: string) {
        this.closeMessageHeader()
        this.selectedTab = tabType
    }

    // ルームを選択した場合に、RoomMembersMenuから呼ばれます。
    public selectMessageRoom(selectedRoom: Room): void {
        this.rm.currentMessageRoom = selectedRoom
        this.openHeader = true
    }

    /**
     * メッセージコンテントを押下したときに、未読を既読に変更します。
     */
    public selectMessageContent() {
        Logger(`${funcName()} コンテントを押しました`)
        if (Util.isBlank(this.rm.currentMessageRoom) || Util.isBlank(this.rm.rooms)) return
        this.rm.currentMessageRoom.unread_num = 0
        const _room = this.rm.rooms.find(r => r.room_id == this.rm.currentMessageRoom.room_id)
        if (_room) {
            Logger(`${funcName()} unread_num: ${_room.unread_num}`)
            _room.unread_num = 0
            // this.room = _room;
            // 既読に変更するため、roomTabsの内容を更新しておきます。
            // ロードに時間がかかりすぎるためいったんコメントアウト. hangai20200928
            // this.rm.updateRoomTabs(null)
            this.roomMessagesView.changeMarkAsRead(_room.room_id)
        }
    }

    public sendFromModal(msg) {
        this.$vfm.close(`InputMessageModal`)
        this.roomMessagesView.sendFromModal(msg)
    }

    showImagePreview(file) {
        this.currentFile = file
        this.$vfm.open("ImagePreviewModal")
    }

    clickWaaqmark() {
        gtagClick(`click`, `waaq logo`)
    }

    showCardView() {
        if (Util.isBoard()) {
            return false
        }
        if (this.isSP && this.selectedTab == "appo") {
            $(`.ScheduleCenterContent`).css({ width: `100%` })
            return true
        }
        if (
            this.isSP ||
            (this.additionalParams &&
                (this.additionalParams.hide_appointment_info == `true` ||
                    this.additionalParams.hm == `true` ||
                    this.additionalParams.hide_main == `true`))
        ) {
            // 左アポなし.
            $(`.ScheduleCenterContent`).css({ width: `100%` })
            return false
        } else {
            // 左のアポ情報も表示
            $(`.ScheduleCenterContent`).css({ width: `calc(100% - 320px)`, "min-width": `600px` })
        }

        return this.type == `na` || !this.isSP || this.selectedTab == `appo`
    }

    showHeader() {
        // パラメータでヘッダー非表示にしている場合、表示しない.
        if (this.additionalParams && this.additionalParams.hide_header == `true`) {
            $(`.ScheduleCenterContent`).css({ width: `100%` })
            return false
        }
        if (this.isSP) {
            $(`.ScheduleCenterContent`).css({ width: `100%` })
        }
        // SPの全体タブにapcがある場合.
        if (this.isSP) {
            if (this.rm.spHeadersMenu.includes(`apc`)) {
                if (this.selectedTab == `apc`) {
                    return true
                }
                return false
            } else {
                return true
            }
        }
        return true
    }

    showShadow() {
        if (this.additionalParams && this.additionalParams.hide_shadow == `true`) {
            Logger(`${funcName()} 影を消します。`)
            $(`.ScheduleCalendarContent`).css(`box-shadow`, `0 0 0 0`)
            return false
        }
        return true
    }

    calculateWindowWidth() {
        if (window.innerWidth < 1200) {
            this.fullwindow = false
        } else {
            this.fullwindow = true
        }
    }

    @Watch(`rm.currentRoom.room_setting.design_type`, { deep: true })
    transparentBackground() {
        Logger(`${funcName()} rm.designType:${this.rm.designType()}`)
        if (this.additionalParams) {
            if (this.additionalParams.background_color) {
                $(`body`).removeClass(`lskyblueBg`)
                $(`body`).css({ "background-color": `#${this.additionalParams.background_color}` })
            } else {
                if (this.rm.designType() == `normal`) {
                    $("body").addClass("lskyblueBg")
                } else {
                    $("body").removeClass("lskyblueBg")
                }
            }
        } else {
            if (this.rm.designType() == `normal`) {
                $("body").addClass("lskyblueBg")
            } else {
                $("body").removeClass("lskyblueBg")
            }
        }
    }

    /**
     * パラメータがURLに付随している場合は、自動でそのフィールドを埋めます
     */
    getAdditionalParams() {
        this.additionalParams = FormUtil.getParams()
        Logger(`${funcName()} additionalParams:${Util.output(this.additionalParams)}`)
    }

    showMainContent() {
        // スグINボタンから確定できなかった調整ページはメインコンテンツを表示しない.
        if (Util.isInstantRoom(this.room) && !Util.isPublic() && !Appointment.isFix(this.room)) return false
        let [isOpen, msg] = Util.isOpenTime(this.room)
        if (!isOpen) return false
        if (Util.isBoard()) return false

        if (!this.additionalParams) return true

        if (this.additionalParams.hm == `true`) return false
        if (this.additionalParams.hide_main == `true`) return false
        return true
    }

    showAssignForm() {
        if (!this.room) return false
        if (!Util.isBoard()) return false
        if (this.additionalParams && this.additionalParams.hide_assign_form == `true`) return false
        if (!this.room.assign_form_id) return false
        return true
    }

    @Watch(`rm.messagesPerRoom`, { deep: true })
    updateHasNewMessage() {
        if (Util.isBlank(this.room)) return
        if (Util.isPublic()) return
        if (!this.rm.userInfo) return

        if (!this.rm.messagesPerRoom[this.room.room_id]) return
        let myMem = this.room.members.find(m => m.user_id == this.rm.userInfo.user_id)
        if (Util.isBlank(myMem)) return

        // 新着メッセージモーダルを表示します。
        let messageDic = this.rm.messagesPerRoom[this.room.room_id] || {}
        if (Util.isBlank(messageDic.messages)) return
        let mess = [...messageDic.messages].reverse()
        Logger(`${funcName()} last_read_at:${myMem.last_read_at}, mess:${Util.output(mess)}`)
        let mes = mess.find(
            mes => mes.owner.user_id != this.rm.userInfo.user_id && !mes.is_system && myMem.last_read_at <= mes.created_at
        )
        if (!mes) return

        this.openHeader = true

        if (this.isSP) return
        if (this.rm.confirmed_new_message) return
        let mem: RoomMember = mes.owner
        Logger(`${funcName()} 新着メッセージモーダルを表示します。`)
        this.$vfm.open(`NewMessageModal`)
        this.NewMessageModal.updateRoomMessage(mem)
    }

    showFilledScheduleModal() {
        this.$vfm.open(`FilledScheduleModal`)
    }

    clickOKFilledSchedule() {
        this.$vfm.close(`FilledScheduleModal`)
    }

    clickInstantButton(pd: PossibleDate) {
        if (!this.rm.currentInstantRoom) return
        this.instantRoomPd = pd
        Logger(`${funcName()} pd:${pd.jp_format}`)
        // スグINボタンを埋め込みで利用している場合に、フォームモーダルを表示.
        this.$vfm.open(`FormContentModal`)
    }
}
