
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger, funcName, sleep } from "packs/common"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import FormFieldContent from "packs/pages/link/parts/room_settings/FormFieldContent.vue"
import UrlWithQRcodeContent from "packs/components/common/UrlWithQRcodeContent.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import AddCommentToMail from "packs/pages/link/parts/room_settings/AddCommentToMail.vue"
import SendMailContent from "packs/pages/link/parts/available_schedule/SendMailContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"
import TemplatesContent from "packs/pages/link/parts/room_settings/TemplatesContent.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import ChipButton from "packs/components/buttons/ChipButton.vue"
import PargSectionsContent from "packs/pages/link/parts/room_summary/PargSectionsContent.vue"
import AnsweredFormTable from "packs/pages/link/parts/room_summary/AnsweredFormTable.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import MeetingTimeContent from "packs/pages/link/parts/room_settings/MeetingTimeContent.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
import FormField from "packs/models/FormField"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomTab from "packs/models/RoomTab"
import MailFlow from "packs/models/MailFlow"
import Notice from "packs/models/Notice"
import ContactListGroup from "packs/models/ContactListGroup"
import Appointment from "packs/models/Appointment"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

@Options({
    components: {
        SelectTag,
        FormFieldContent,
        UrlWithQRcodeContent,
        AddressBookContent,
        UserIcon,
        AddCommentToMail,
        SendMailContent,
        VueFinalModal,
        MdProgressBar,
        MdProgressSpinner,
        TemplatesContent,
        TextfieldTag,
        ChipButton,
        PargSectionsContent,
        AnsweredFormTable,
        CheckboxTag,
        MdTextfieldTag,
        MeetingTimeContent,
        TextareaTag,
    },
})
export default class LeftPanelSettingsContent extends Vue {
    @Prop()
    member: RoomMember

    @Prop()
    currentMember: RoomMember

    @Prop()
    editOrNewForm: string

    @Prop() // form, mail, parg
    viewType: string

    // data
    rm = RoomManager
    rcm = RoomCreateManager

    loading = false
    isMe = false

    results = null
    Util = Util
    // currentRoom: Room = null

    rooms: Room[] = null
    pubRoomsArray: any[] = []
    publics: string[] = null

    /**
     * ボタンに設定している公開ページ
     * ParentRoomのユーザー（member）を指定してきた場合のみ入ります.
     */
    defaultPubName = null
    selectedPubRoom: Room = null
    fields: FormField[] = null
    systemUpdatedAt = Util.getSec()

    additionalParams = {}
    generatedUrl = null

    step = 0

    members: RoomMember[] = []
    UserPermissionsOption = UserPermissionsOption
    titles = TemplateUtil.getTitles()
    mailsDic: { [key: string]: MailFlow } = {} // {key: user.email, value: {subject:, content:, cc_emails:, use_template: false}}
    currentMail: MailFlow = null
    selectedClg: ContactListGroup = null

    tmp: MessageTemplate = null

    // 作成されたルーム情報 Array<{room: Room, room_link: string}>
    generatedRoomsDic: any[] = null

    showCc = false
    currentMemCcEmails = ``
    selectedTabName = `appoInfo`

    mounted() {
        this.rcm.parg.title = this.defaultTitle()
    }

    selectCategoryTab(tabName: string) {
        this.selectedTabName = tabName
        this.rcm.viewType = tabName
    }

    public restart() {
        this.additionalParams = {}
        if (this.defaultPubName) {
            // 公開ページから選択
            this.selectedPubRoom = null
            this.defaultPubName = null
            this.fields = null

            this.step = 0
        } else {
            // step==1から開始
            this.step = 1
        }
    }

    defaultTitle() {
        let room = this.rcm.room
        let apc = room.appeal_content
        let appo = room.current_appointment
        let title = ``
        if (Util.isPresent(apc)) {
            title = apc.title
        } else if (Util.isPresent(appo)) {
            title = appo.name
        }
        return title
    }

    public addAttendee(member: RoomMember) {
        Logger(`${funcName()} member:${Util.output(member)}`)
        this.$emit(`addAttendee`, member)
    }

    selectContactListGroup(clg: ContactListGroup) {
        this.$emit(`selectContactListGroup`, clg)
    }

    public inputTemplateContent(e: string, tmpId: string) {
        Logger(`inputTemplateContent ${e}`)
        let _mf = this.rcm.selectedMailFlow

        if (_mf.template_public_id != tmpId) {
            _mf.template_public_id = tmpId
        }

        if (_mf) {
            _mf.content = e
            this.rcm.updateMailFlow(_mf)
        }
    }

    closeForm() {
        this.rcm.currentSendMem = null
        this.rcm.viewType = `addressbook`
    }

    clickUseAiFix() {
        Logger(`clickUseAiFix`)
        if (this.rcm.parg.use_ai_fix) {
            this.rcm.parg.use_ai_fix = false
        } else {
            this.rcm.parg.use_ai_fix = true
        }
    }

    inputPargTitle(e: string) {
        Logger(`${funcName()} title:${e}`)
        this.rcm.parg.title = e
    }

    updateAppo(appo: Appointment) {
        Logger(`${funcName()} appo:${Util.output(appo)}`)
        this.rcm.room.current_appointment = appo
    }

    inputAiFixMessage(e: string) {
        Logger(`${funcName()} e:${e}`)
        this.rcm.parg.ai_fix_message = e
    }

    clickFixer() {
        Logger(`${funcName()} clickFixer`)
        this.rcm.addressbookType = ParentRoomGroup.FIXER
        this.rcm.viewType = `addressbook`
    }

    clickApproval() {
        Logger(`${funcName()} clickApproval`)
        this.rcm.addressbookType = ParentRoomGroup.APPROVAL
        this.rcm.viewType = `addressbook`
    }

    addressbookTypeText() {
        if (this.rcm.addressbookType == ParentRoomGroup.FIXER) {
            return `日程決定者を指定します。`
        } else if (this.rcm.addressbookType == ParentRoomGroup.APPROVAL) {
            return `日程承認者を指定します。`
        }
        return ``
    }
}
