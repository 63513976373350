
// モジュールを読込.
import { Options, Prop, Vue, Ref, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import draggable from "vuedraggable"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import EditableContent from "packs/components/forms/EditableContent.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import CalendarTag from "packs/models/CalendarTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomCreator from "packs/utils/RoomCreator"
import Room from "packs/models/Room"
import MailFlow from "packs/models/MailFlow"
import RoomMember from "packs/models/RoomMember"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

@Options({ components: { EditableContent, TextareaTag, TextfieldTag, UserIcon, draggable } })
export default class PargSectionsContent extends Vue {
    @Prop()
    mf: MailFlow

    cm = CalendarManager

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    Util = Util

    pdm = PossibleDatesManager
    rcm = RoomCreateManager

    currentMailSubject = ``
    currentMailContent = ``

    sections = []
    userList = []
    sec1Mems = []
    sec2Mems = []
    sec3Mems = []
    sec4Mems = []
    sec5Mems = []

    fixMem: RoomMember = null
    approvalMem: RoomMember = null

    alphabets = Util.alphabets
    drag = false

    public mounted() {
        this.setSections()
    }

    @Watch("rcm.userList", { deep: true })
    setSections() {
        Logger(`${funcName()} rcm.userList:${this.rcm.userList.length}`)
        this.userList = this.rcm.userList
        for (const mem of this.userList) {
            if (!mem.line_parent_room) {
                mem.line_parent_room = {}
            }
            if (!mem.line_parent_room.block_type) {
                mem.line_parent_room.block_type = ParentRoomGroup.BLOCK_1
            }
        }

        this.sec1Mems = this.userList.filter(m => m?.line_parent_room?.block_type == ParentRoomGroup.BLOCK_1)
        this.sec2Mems = this.userList.filter(m => m?.line_parent_room?.block_type == ParentRoomGroup.BLOCK_2)
        this.sec3Mems = this.userList.filter(m => m?.line_parent_room?.block_type == ParentRoomGroup.BLOCK_3)
        this.sec4Mems = this.userList.filter(m => m?.line_parent_room?.block_type == ParentRoomGroup.BLOCK_4)
        this.sec5Mems = this.userList.filter(m => m?.line_parent_room?.block_type == ParentRoomGroup.BLOCK_5)
        this.sections = [this.sec1Mems, this.sec2Mems, this.sec3Mems, this.sec4Mems, this.sec5Mems]
        this.fixMem = this.rcm.fixers[0]
        this.approvalMem = this.rcm.approvers[0]
    }

    @Watch("rcm.fixers", { deep: true })
    setFixMem() {
        this.fixMem = this.rcm.fixers[0]
    }

    @Watch("rcm.approvers", { deep: true })
    setApprovalMem() {
        this.approvalMem = this.rcm.approvers[0]
    }

    deleteSectionMem(mem: RoomMember) {
        let userList = this.userList.filter(m => m.user_id != mem.user_id)
        this.rcm.updateUserList(userList)
    }

    addFixMem() {
        // 確定者(sectionsに追加されているメンバー)またはAIを設定します.
        this.rcm.viewType = `fixer`
        this.rcm.addressbookType = `block_1`
    }

    sortEnd(event: any) {
        try {
            Logger(`${funcName()} event:${event}`)
            this.drag = false
            if (Util.isBlank(this.sections)) return
            // ドラッグ＆ドロップ後のセクションの順番を更新
            this.sections = [...this.sections]
            // this.sections = MeetingAttendeesGroup.addNames(this.sections)
            this.setSections()
        } catch (error) {
            console.error("sortEnd メソッド内でエラーが発生しました:", error)
        }
    }

    startDrag() {
        Logger(`${funcName()} startDrag`)
        this.drag = true
    }

    handleChange(event: any, secindex: number) {
        Logger(`${funcName()} handleChange:${JSON.stringify(event)}, secindex:${secindex}`)
        if (event.added) {
            const member = event.added.element
            member.line_parent_room ||= {}
            member.line_parent_room.block_type = ParentRoomGroup[`BLOCK_${secindex + 1}`]
            let mems = this.userList.filter(m => m.user_id != member.user_id)
            mems.push(member)
            this.rcm.updateUserList(mems)
            Logger(`${funcName()} Item added:${member.line_parent_room.block_type}`)
        }
        if (event.removed) {
            Logger(`${funcName()} Item removed: ${event.removed}`)
        }
        if (event.moved) {
            Logger(`${funcName()} Item moved:${event.moved}`)
        }
        this.sortEnd(event)
    }

    selectMember(mem: RoomMember) {
        // this.$emit(`selectMember`, mem)
        this.rcm.viewType = `userInfo`
        this.rcm.currentSendMem = mem
    }
}
