
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import HeaderControl from "packs/utils/HeaderControl"
import { Dropdown } from "uiv"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"

@Options({
    components: { Dropdown },
})
export default class ScheduleMainHeaderButton extends Vue {
    // data:
    @Prop()
    type: string

    @Prop()
    appo: Appointment

    @Prop()
    room: Room

    @Prop()
    appoStatus: string

    rcm = RoomCreateManager
    cm = CalendarManager
    rm = RoomManager
    pdm = PossibleDatesManager
    Util = Util
    Appointment = Appointment

    showTooltip = false
    periodText = ``

    public requestOtherDays() {
        Logger(`リクエストモーダルを開く.`)
        this.$vfm.open("RequestAdditionalPossibleDatesModal")
    }

    get showRequestButton(): boolean {
        Logger(
            `ScheduleMainHeaderButton.showRequestButton room:${this.room?.id}, appo:${this.appo?.id}, userInfo:${
                this.rm.userInfo?.user_id
            }, type:${this.type}, isPublic:${Util.isPublic()}`
        )
        if (this.rm.parg) {
            if (this.rm.parg.my_block_type == `fixer` && this.appo.status != 10) {
                return true
            }
        }
        if (!this.appo) return false
        if (!this.rm.userInfo) return false
        if (this.appo.status == 10) return false
        if (this.appo.status == -1) return false
        if (this.type == `editable`) return false
        if (Util.isPublic()) return false
        let rs = this.room.room_setting
        Logger(`ScheduleMainHeaderButton.showRequestButton rs:${rs ? rs.request_dates_button_type : ``}`)
        if (rs && rs.request_dates_button_type == `none`) return false
        if (this.pdm.didShareOther(this.rm.userInfo.user_id)) return true
        return false
    }

    get showCancelButton() {
        if (Util.isPublic()) return false
        if (!this.appo) return false
        if (Util.isInstantRoom(this.room) && Appointment.isFix(null, this.appo)) {
            return false
        }

        if (Appointment.isFix(null, this.appo) && this.appo.can_cancel && this.pdm.displayType != `reschedule`) {
            return true
        }

        return false
    }

    get cancelButtonTitle() {
        if (this.room) {
            let [isOpen, msg] = Util.isOpenTime(this.room, true)
            if (isOpen) {
                return `schedule.cancel_or_reschedule`
            } else {
                return `schedule.want_cancel`
            }
        }
        return `schedule.cancel_or_reschedule`
    }

    get showRescheduleButton() {
        if (this.room) {
            let [isOpen, msg] = Util.isOpenTime(this.room, true)
            if (isOpen) {
                return true
            } else {
                return false
            }
        }
        return true
    }

    rescheduleWithoutChoice() {
        this.$emit(`rescheduleWithoutChoice`)
    }

    // 投票形式の場合、再調整・キャンセルしたいボタンを押すと、いきなりモーダルを表示.
    cancelVoted() {
        gtagClick(`投票キャンセル不可モーダル表示`, ``)
        this.$vfm.open(`CancelAppointmentModal`)
    }

    clickReschedule() {
        this.$emit(`clickReschedule`)
    }

    showCancelModal() {
        this.$emit(`showCancelModal`)
    }
}
