
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import LinkHeaderAvailableSchedule from "packs/components/link/LinkHeaderAvailableSchedule.vue"
import ConnectedCalendar from "packs/pages/link/parts/available_schedule/ConnectedCalendar.vue"
import InputAstagNameModal from "packs/pages/link/modals/InputAstagNameModal.vue"
import AstagLists from "packs/pages/link/parts/available_schedule/AstagLists.vue"
import WeeklyDetailSchedule from "packs/pages/link/parts/available_schedule/WeeklyDetailSchedule.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import AppointmentCardViewSmall from "packs/pages/link/parts/available_schedule/AppointmentCardViewSmall.vue"
import LeftNavPanel from "packs/pages/link/parts/room_settings/LeftNavPanel.vue"
import CompleteToCreateVotedRoom from "packs/pages/link/parts/available_schedule/CompleteToCreateVotedRoom.vue"
import TutorialModal from "packs/pages/link/modals/TutorialModal.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import CalendarTag from "packs/models/CalendarTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomCreator from "packs/utils/RoomCreator"
import Room from "packs/models/Room"
import ContactListGroup from "packs/models/ContactListGroup"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import AstagsTab from "packs/models/fetcher/AstagsTab"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        LinkHeaderAvailableSchedule,
        ConnectedCalendar,
        InputAstagNameModal,
        AstagLists,
        WeeklyDetailSchedule,
        RoomsLoader,
        AppointmentCardViewSmall,
        LeftNavPanel,
        CompleteToCreateVotedRoom,
        TutorialModal,
    },
    beforeRouteLeave(to, from, next) {
        if (this.cm.hasUnsaved) {
            if (window.confirm(`編集した内容を保存しますか？`)) {
                // 保存処理
                this.saveAvailableSchedule()
            } else {
                // 変更前のastagの情報に戻します.
                Logger(`元のastagに戻しました: ${Util.output(this.beforeAstag)}`)
                this.rm.astags = (this.rm.astags || []).filter(a => a.id != `newId`)

                // this.currentAstag = null
                // this.rm.astag = null
                if (this.cm.createType == `vote`) {
                    this.rcm.reset()
                }
                if (this.pdm) {
                    this.pdm.resetPdm()
                    this.pdm.resetInfo()
                }

                this.cm.resetAstagInfo()
            }
        } else {
            this.rm.astags = (this.rm.astags || []).filter(a => a.id != `newId`)
            if (this.cm.createType == `vote`) {
                this.rcm.reset()
            }
            if (this.pdm) {
                this.pdm.resetPdm()
                this.pdm.resetInfo()
            }

            this.cm.resetAstagInfo()
        }
        next()
    },
})
export default class AvailableScheduleView extends Vue {
    // data:
    isDev = false
    devType = 10 // 0: 未招待ユーザー、1:招待ユーザー 10:利用開始ユーザー
    devdidConnectCal = false

    cm = CalendarManager

    rm = RoomManager
    isSP = isMobile(window.navigator).phone

    Util = Util

    parentId
    roomId
    pdm = PossibleDatesManager
    rcm = RoomCreateManager

    beforeAstag: AvailableScheduleTag = null // 保存しないで戻る場合に、もとのastagにastagデータを入れておきます.
    selectedTabName = `list`
    createType = `normal` // nomal, vote
    willCreateNewAstag = true // 新規で作成するか.（すでに作成済みのastagで投票作成する場合は、falseにします.）

    panelType = null
    currentClg: ContactListGroup = null
    loading = false

    headerButtonEnabled = true
    astagsTab: AstagsTab = null

    @Ref()
    PreviewContent

    @Ref()
    header

    @Ref()
    WeeklyDetailSchedule

    // defaultSelectCtagEmails = [
    //     "ja.japanese#holiday@group.v.calendar.google.com"
    // ]; // 日本の祝日.

    beforeMount() {
        $("body").addClass("lskyblueBg")
    }

    public mounted() {
        Logger(`location: ${location.href}`)
        gtagPage("#/available_schedule")

        // let astag = AvailableScheduleTag.createDefaultAstag();
        // AvailableScheduleTag.availableDateArr(null);
        let lastAstagId = roomStorage.fetchLastAstagId()

        if (this.isGuest()) {
            Logger(`ゲストとして利用しています。 parent id : ${this.parentId}`)
            this.parentId = roomStorage.fetchSelectedParentRoomId()
            this.roomId = roomStorage.fetchSelectedRoomKey()
        }
        this.rm.redirectTopIfChukaiFreePlan()

        if (!this.rm.hasAsTag()) {
            Logger("GET AvailableSchedule")
            this.rm.startProgress()

            /**
             * 既にastagページにいるので、リダイレクトループにならないようチェック不要で取得してきます。
             */
            const checkAstag = false
            let pid = null
            if (this.isGuest()) {
                pid = this.parentId
                if (!pid) {
                    Logger(`parentIdがないため、astag以降の取得処理を中止します。`)
                    return
                }
            }

            this.rm.getAvailableSchedule(checkAstag, pid).then(result => {
                Logger(`tutorial phase1: ${this.rm.tutorial_phase}`)
                this.astagsTab = this.rm.astagsTab
                this.setDev()
                this.rm.endProgress()
                // this.buildData(lastAstagId)
                this.rm.redirectTopIfChukaiFreePlan()
            })
            // });
        } else if (this.rcm.room && this.rcm.room.room_type == `public_room` && this.cm.createType == `vote`) {
            this.createType = `vote`
            this.cm.hasUnsaved = true
            this.buildData()
            this.header.selectTab(`detail`)
            if (!this.rm.confirmed_quick_vote_tutorial) {
                this.$vfm.open(`TutorialModal`)
            }
        } else {
            Logger(`tutorial phase2: ${this.rm.tutorial_phase}`)
            Logger(`rmのastag current: ${Util.output(this.rm.astag)}, astag: ${Util.output(this.rm.astag)}`)
            this.astagsTab = this.rm.astagsTab
            this.resetAstagSelection()
            this.setDev()
            // this.buildData(lastAstagId)
        }
    }

    @Watch(`rm.astagsTab`, { deep: true })
    updateAstagsTab() {
        Logger(`${funcName()} rm.astagsTab:${this.rm.astagsTab?.tab_name}`)
        if (!this.rm.astagsTab) return
        this.astagsTab = this.rm.astagsTab
    }

    public resetAstagSelection() {
        this.cm.astag = null
        this.rm.astag = null
        this.rm.getAllAstags()
    }

    // ページから離れるときは保存したastag情報を削除します。
    public beforeUnmount() {
        Logger(`${funcName()}`)
        $("body").removeClass("lskyblueBg")
    }

    public clickBack() {
        // Logger(`click back: ${Util.output()}`)
        // this.$router.go(-1)
        this.$router.back()

        // this.$router.go(-1) || this.$router.push(`/`)
    }

    public setDev() {
        if (!this.isDev) return

        // 開発環境
        Logger(`AvailableScheduleView::::開発モードです。`)
        this.cm.astag = AvailableScheduleTag.createDefaultAstag()
        if (this.devType == 0) {
            // 未招待
            this.rm.tutorial_phase = -10
        } else if (this.devType == 1) {
            // 招待
            this.rm.tutorial_phase = -9
        } else if (this.devType == 10) {
            this.rm.tutorial_phase = 100
        }
        this.rm.didConnectCal = this.devdidConnectCal
    }

    public buildData(astagId: string = null) {
        // if (astagId) {
        //     // ID指定しているため、取得してきます。
        //     let astags = this.rm.astags ? [...this.rm.astags] : []
        //     let _astag = astags.find(a => a.id == astagId)
        //     if (_astag) {
        //         this.astag = { ..._astag }
        //         this.rm.currentAstag = _astag
        //     } else {
        //         this.astag = this.rm.currentAstag ? { ...this.rm.currentAstag } : { ...this.rm.astag }
        //     }
        // } else {
        //     this.astag = this.rm.currentAstag ? { ...this.rm.currentAstag } : { ...this.rm.astag }
        // }

        Logger(`${funcName()} astag: ${Util.output(this.cm.astag)}`)

        // ログインしたことがなかったりユーザー情報を確認していない場合、モーダルで使い方の説明をはさみます。
        if (!Const.started(this.rm)) this.showHowToUseCalModal()

        if (this.cm.astag) {
            Logger(`beforeAstagにastagを入れいます: ${Util.output(this.cm.astag)}`)
            this.beforeAstag = { ...this.cm.astag }
            roomStorage.saveLastAstagId(this.cm.astag.id)
            let astags = this.rm.astags ? [...this.rm.astags] : []
            for (let _astag of astags) {
                _astag.selected = false
                if (_astag.id == this.cm.astag.id) _astag.selected = true
            }
            this.rm.astags = astags
        }

        if (this.cm.astag) {
            if (this.rm.didConnectCal || this.cm.showAttendeesEdit()) {
                // カレンダーリストを取得.
                Logger(`リモートからカレンダーリストを取得します。`)

                this.rm.startProgress()
                this.cm.getCalList().then(res => {
                    this.rm.endProgress()
                    // this.PreviewContent.getEvents()

                    this.getMagsEventsAndPossibleDates()
                })
            } else {
                this.getMagsEventsAndPossibleDates()
            }
        }
    }

    getMagsEventsAndPossibleDates() {
        // magごとにイベントを取得します.
        let selectMagName = `A`
        this.cm.updateMagName(selectMagName)
        // if (this.cm.createType == `vote` && !this.cm.astag.can_connect_calendar) {
        //     this.cm.pdm = PossibleDatesManager
        //     let today = DateTime.local()
        //     let nextMonth = today.plus({ month: 1 })
        //     this.cm.pdm.available_months = [today.toFormat(`MMdd`), nextMonth.toFormat(`MMdd`)]
        //     return
        // }
        // 表示を初期値に戻す.
        this.cm.getMagEvents(null, selectMagName).then(e => {
            this.cm.getPossibleDatesEvents()
        })
    }

    // public updateDataFromRails(data) {
    //     // type: fixed, jp_format, current_appointment
    //     Logger(`Railsからデータを受け取りました。${Util.output(data)}`)
    //     let parentId = data.parent_id

    //     const roomId = roomStorage.fetchSelectedRoomKey()
    //     if (this.isGuest()) {
    //         Logger(`未ログインのため、ルーム情報を取得してきます。作成して共有させるため.`)
    //         this.rm.getAvailableSchedule(false, parentId).then(result => {
    //             Logger(`tutorial phase1: ${this.rm.tutorial_phase}`)
    //             this.setDev()
    //             this.buildData()
    //         })
    //         this.rm.getRoom(parentId).then(e => {})
    //     }
    // }

    get headerTitle(): string {
        let title
        if (!this.isGuest()) {
            if (!this.cm.astag) return ``
            // 既にログイン済み
            if (this.rcm.room && this.rcm.room.room_type == `public_room` && this.cm.createType == `vote`) {
                if (this.selectedTabName == `complete`) {
                    return null
                } else if (this.loading) {
                    this.headerButtonEnabled = false
                    return `作成中`
                } else if (Util.isPresent(this.pdm.possibleDates) && Util.isPresent(this.rcm.members)) {
                    this.headerButtonEnabled = true
                } else {
                    this.headerButtonEnabled = false
                }
                return `送信`
            }

            title = this.cm.astag.id == `newId` ? `調整カレンダーを作成` : `調整カレンダーを更新`
        } else {
            if (!this.cm.astag) return `調整カレンダーを作成して共有`
            title = this.cm.astag.id == `newId` ? `調整カレンダーを作成して共有` : `調整カレンダーを更新`
        }
        return title || "調整カレンダー"
    }

    public isGuest(): boolean {
        let userType = Util.checkLoggedInStatus()
        return userType == `user` ? false : true
    }

    public inputName(e) {
        this.cm.astag.name = e
        this.cm.saveLocal()
    }

    public blurName() {
        if (!this.cm.astag.name) this.cm.astag.name = `無名`
    }

    public sendForVoting() {
        Logger(`sendForVoting voteViewType: ${this.cm.voteViewType}`)
        if (Util.isBlank(this.pdm.possibleDates)) {
            Notice.message = `候補数がゼロのため送信できません。投票できる候補を作成してください。`
            return
        }

        // 送信
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        let sendType = `put`
        if (this.cm.astag.id == "newId") {
            sendType = `post`
        }

        let opDic = {}
        for (let _pd of [...(this.cm.currentPossibleDates || [])]) {
            let ops = opDic[_pd.date_format] || []
            _pd.type = `optional_add`
            Array.prototype.push.apply(ops, [_pd])
            opDic[_pd.date_format] = ops
        }
        this.cm.astag.optional_schedules = opDic
        this.cm.astag.is_only_use_optional_schedules = true

        // astag作成
        AvailableScheduleTag.saveAvailableSchedule(this.cm.astag, sendType, null).then(ast => {
            this.rcm.astag = ast
            this.cm.astag = ast
            this.rm.astag = { ...this.cm.astag }
            this.findOrSetAstagsAfterSave(ast)

            // 調整ページ作成

            // 保存に成功したら、ローカルに保存した情報は削除しておきます。
            this.rcm.saveRoom().then(dic => {
                let room = dic.room
                let isSuccess = dic.isSuccess
                if (isSuccess) {
                    this.rcm.room = room
                    let mems = this.rcm.members
                    let memEmails = []
                    for (let _mem of mems) {
                        _mem.role = RoomMember.MEMBER
                        _mem = RoomMember.setAnswerResultsFromMember(_mem)
                        memEmails.push(_mem.email)
                    }
                    let opMems = this.rcm.opMembers
                    for (let _mem of opMems) {
                        _mem.role = RoomMember.MEMBER_OPTIONAL
                        _mem = RoomMember.setAnswerResultsFromMember(_mem)
                        memEmails.push(_mem.email)
                    }

                    Array.prototype.push.apply(mems, opMems)
                    let fixMems = this.rcm.fixMembers
                    for (let _mem of fixMems) {
                        // 任意出席者にも必須出席者にも入っていないメンバーはobserverとして作成します.
                        if (!memEmails.includes(_mem.email)) {
                            _mem.role = RoomMember.OBSERVER
                        }
                        _mem.assign_type = RoomMember.FIX
                        _mem = RoomMember.setAnswerResultsFromMember(_mem)
                    }

                    let mailsDic = {}
                    let mf = this.rcm.currentMail

                    // メール送信内容を入れ込みます.
                    for (let mem of mems) {
                        mailsDic[mem.email] = mf
                    }

                    this.rcm.createType = `send_mail`
                    // this.rm.rooms.push(room)
                    let rooms = [...(this.rm.rooms || [])]
                    rooms.push(room)
                    this.rm.updateRoomTabs(rooms)
                    // this.rm.updateLocalRoomsData([room], `public`)

                    this.rcm.createParentRoomsFromPublicRoom(mems, mailsDic, fixMems, null, null, null).then(_dic => {
                        let roomsDic = _dic.generated_rooms_dic
                        let isSuccess2 = _dic.isSuccess
                        if (isSuccess2) {
                            this.currentClg = _dic.contact_list_group
                            this.selectedTabName = `complete`
                            this.cm.voteViewType = `complete`
                        }
                        this.loading = false
                        this.rm.endProgress()
                        // ページ離れたらリセットするように.
                        this.cm.hasUnsaved = true
                    })
                }
            })
        })
    }

    /**
     * 作成した調整カレンダーをリモートに保存します。
     */
    public saveAvailableSchedule() {
        if (this.rcm.room && this.rcm.room.room_type == `public_room` && this.cm.createType == `vote`) {
            // if (this.cm.createType == `vote`) {
            this.sendForVoting()
            return
        }

        Logger(`will save astag id: ${this.cm.astag.id}`)
        let sendType = "post"
        let parentId = ``
        if (this.isGuest()) {
            Logger(`未ログインのため、共有して作成します。`)
            sendType = `createAndShare`
            if (this.rm.currentRoom.isParent) {
                parentId = this.rm.currentRoom.id
            } else {
                parentId = this.rm.currentRoom.parentRoomId
            }
        } else if (this.cm.astag.id == "newId") {
            sendType = "post"
        } else {
            Logger(`既に存在しているため、アップデートします。`)
            sendType = "put"
        }

        if (this.isDev) {
            // 開発環境
            this.redirectAfterSentPath(sendType)
            return
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        Logger(`保存するastagの情報: ${Util.output(this.cm.astag)}`)

        // this.configureAstag()

        AvailableScheduleTag.saveAvailableSchedule(this.cm.astag, sendType, parentId).then(ast => {
            this.rm.endProgress()
            this.loading = false

            // 保存に成功したら、ローカルに保存した情報は削除しておきます。
            if (ast) {
                if (this.showNewButton()) {
                    this.cm.astag = ast
                    this.rm.astag = { ...this.cm.astag }
                    this.findOrSetAstagsAfterSave(ast)
                }

                this.cm.hasUnsaved = false
                // 送信後、遷移します。
                this.redirectAfterSentPath(sendType)
                gtagClick(
                    `調整カレンダー作成 ${sendType}`,
                    `astagid: ${this.cm.astag.id}[${this.rm.userInfo.email}] add: ${
                        Object.values(this.cm.astag.optional_schedules).length
                    }`
                )
            }
        })
    }

    public findOrSetAstagsAfterSave(ast: AvailableScheduleTag) {
        let astags = this.rm.astags ? [...this.rm.astags] : []
        let _astag = astags.find(a => a.id == ast.id)
        if (_astag) {
            Object.assign(_astag, ast)
        } else {
            astags.push(this.rm.astag)
        }

        this.rm.astags = astags
    }

    public redirectAfterSentPath(sendType) {
        const currentUrl = window.location.pathname
        Logger(`url: ${currentUrl}`)
        if (currentUrl.includes(`/start`)) {
            location.href = "/link"
        } else if (sendType == `createAndShare`) {
            // 未ログインユーザーのため、
            // waaq.jp/schedule#/ に行く.
            this.$router.push(`/`)
        } else if (sendType == "post") {
            // 新規で公開カレンダーを作成した場合（1つめの場合）、調整ページ一覧
            // this.$router.push("/messages");
            location.href = "/link"
            // this.$router.go(-1);
        } else if (sendType == "put") {
            this.$router.go(-1)
        }
    }

    public showHowToUseCalModal() {
        Logger(`使い方モーダルを開きます`)
        this.$vfm.open("HowToUseCalModal")
    }

    public clickNew() {
        gtagClick(`Astag`, `新規作成`)
        this.createType = `normal`
        this.cm.createType = `normal`
        this.cm.astag = null

        this.$vfm.open("InputAstagNameModal")
    }

    clickChangeName() {
        this.willCreateNewAstag = false
        this.$vfm.open("InputAstagNameModal")
    }

    clickChangeAutoMode() {
        Logger(`${funcName()}`)
        this.WeeklyDetailSchedule.openResetOptionalSchedulesModal()
    }

    /**
     * 作成済みの調整カレンダーから投票ページを作成します.
     */
    createVotedRoom(astag: AvailableScheduleTag) {
        this.rm.astag = astag
        // astagは作成しない.
        this.clickNewVote(false)
    }

    /**
     * @param willCreateNewAstag [boolean] 新規でastagを作成する場合true, すでに作成済みのastagを利用する場合はfalse
     */
    clickNewVote(willCreateNewAstag = true) {
        gtagClick(`調整カレンダー > 投票新規作成`, ``)
        if (willCreateNewAstag) {
            this.cm.astag = null
            this.cm.resetAstagInfo()
        }

        this.createType = `vote`
        this.cm.createType = `vote`
        this.willCreateNewAstag = willCreateNewAstag

        this.$vfm.open("InputAstagNameModal")
    }

    public createNewAstag() {
        Logger(`新規取得.: ${this.rm.astag.name}`)
        this.selectEnd(this.rm.astag)
        this.$vfm.close("InputAstagNameModal")
    }

    /**
     * 一覧からastagを選択してきます。
     */
    public selectEnd(astag: AvailableScheduleTag, createType: string = null) {
        Logger(`AvailableScheduleView#selectEnd createType:${createType}, this.createType: ${this.createType}`)
        if (!this.showNewButton()) {
            // 編集権限がありません
            Notice.message = `編集権限がありません。`
            this.cm.astag = null
            return
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        this.rm.getAstagDetail(astag?.id).then(res => {
            this.loading = false
            this.rm.endProgress()
            if (!res) return

            let astag = res

            if (createType) {
                this.createType = createType
                this.cm.createType = createType
            }
            // this.astag = this.rm.astag;
            this.cm.setNew(this.rm.userInfo, this.rm.user_setting, astag, null)
            if (this.createType == `vote`) {
                Logger(`selectEnd 投票タイプで作成します.`)
                this.cm.createType = `vote`
                let room = Room.createDefaultRoom()
                room.room_type = `public_room`
                let roomDic = RoomCreator.getVoteRoomDic(`${this.rm.astag.name}の日程投票ページ`)
                room = RoomCreator.configurePublicRoom(room, roomDic, this.rm)
                room.current_appointment.name = astag.name
                this.rcm.setNew(room, room.current_appointment, [], astag, this.rm.userInfo, this.rm.user_setting, `new`)
                this.rcm.members = []
            }
            this.buildData()
            this.header.selectTab(`detail`)
        })
    }

    selectTab(tabname: string) {
        Logger(
            `selectTab: ${Util.output(tabname)} rcm.members:${Util.output((this.rcm || {}).members)}, mags:${Util.output(
                (this.cm || {}).mags
            )}`
        )
        if (tabname == `mail` && Util.isBlank(this.rcm.members) && Util.isPresent(this.cm.mags)) {
            // 出席者に加える宛先を抽出
            let toAddresses = []
            let mag = this.cm.mags[0]
            Logger(`selectTab:mag ${Util.output(mag.required_attendees)}`)
            for (let att of mag.required_attendees) {
                if (att.is_shared_in_waaq && Util.isPresent(att.owner)) {
                    toAddresses.push(CalendarTag.toMember(att))
                }
            }
            Logger(`toAddresses: ${Util.output(toAddresses)}`)
            if (Util.isPresent(toAddresses)) {
                this.rcm.updateMembers(toAddresses)
            }
        }
        this.selectedTabName = tabname
    }

    /**
     * 子要素からどの要素を編集するかをはめて、パネルを表示します。
     * image: ヘッダー画像, attendees: 出席者, locations: appoの場所選択, astag: 調整カレンダー選択, pptos変更, thanks_page 完了ページを編集
     * @param type [string]
     */
    public editEditableContent(type: string) {
        gtagClick(`Open LeftPanel ${type}`, `${type}`)
        this.panelType = type
        $(".leftNavPanel").addClass("open")
    }

    showNewButton() {
        if (Const.suspend(this.rm)) return false

        return UserPermissionsOption.permitEdit(this.rm.userInfo, `room_settings`, `room_settings_create_available_schedule_tag`)
    }
}
