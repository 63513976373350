import { DateTime } from "luxon"
import { zeroPadding, Logger } from "../common"
import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "./Notice"
import NotificationControl from "packs/utils/NotificationControl"
import PossibleDate from "packs/models/PossibleDate"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarTag from "./CalendarTag"
import Room from "./Room"
import lodash from "lodash"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"

export default class ParentRoomGroup {
    static readonly BLOCK_1: string = `block_1`
    static readonly BLOCK_2: string = `block_2`
    static readonly BLOCK_3: string = `block_3`
    static readonly BLOCK_4: string = `block_4`
    static readonly BLOCK_5: string = `block_5`
    static readonly FIXER: string = `fixer`
    static readonly APPROVAL: string = `approval`

    constructor(
        public id: string,
        public updated_at: number,
        public created_at: number,
        public public_id: string,
        public status: number,
        public status_name: string,
        public use_ai_fix: boolean,
        public ai_fix_message: string,
        public is_notify_owner: boolean,

        public current_block_type: string,
        public my_block_type: string,
        public last_block_updated_at: number,
        public after_actions_if_ai_cannot_fix: string[],
        public title: string,
        public fixer: RoomMember,
        public memo: string,
        public private_memo: string,
        public current_appointment: Appointment,
        public location_name: string,
        public step_rooms_dic: any,
        public has_approval_l_parent: boolean,
        public start_time: number,
        public duration: number,
        public expired_at: number,
        public room_type: string,
        public is_owner_group: boolean,
        public is_voted: boolean
    ) {}

    static createDefault() {
        let message = `全員が揃う日程のうち、できるだけ早い日程で確定してください。揃う日がない場合は、確定しないでください。`
        let createdAt = Util.getSec()
        return new ParentRoomGroup(
            null,
            createdAt,
            createdAt,
            null,
            101,
            `調整中`,
            false,
            message,
            false,
            null,
            null,
            null,
            [],
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            -1,
            `parent_room_group`,
            true,
            false
        )
    }

    static fetchFromJson(_pargs: any): ParentRoomGroup[] {
        if (_pargs.length == 0) return []

        return _pargs.map(
            e =>
                new ParentRoomGroup(
                    e.id,
                    e.updated_at,
                    e.created_at,
                    e.public_id,
                    e.status,
                    e.status_name,
                    e.use_ai_fix,
                    e.ai_fix_message,
                    e.is_notify_owner,
                    e.current_block_type,
                    e.my_block_type,
                    e.last_block_updated_at,
                    e.after_actions_if_ai_cannot_fix,
                    e.title,
                    e.fixer,
                    e.memo,
                    e.private_memo,
                    e.current_appointment,
                    e.location_name,
                    e.step_rooms_dic,
                    e.has_approval_l_parent,
                    e.start_time,
                    e.duration,
                    e.expired_at,
                    e.room_type,
                    e.is_owner_group,
                    e.is_voted
                )
        )
    }

    static getParentRoomGroups(pub_room_id: string, page: number, tabName: string): Promise<any> {
        Logger(`ParentRoomGroup.getParentRoomGroups pub_room_id:${pub_room_id}, page:${page}, tabName:${tabName}`)
        return axios
            .post(`${Util.prefixUrl}/parent_room_groups/index`, {
                page: page || 1,
                tab_name: tabName || `active_in_progress`,
                pub_room_id: pub_room_id,
            })
            .then(res => {
                const _pargTab = res.data.parent_room_groups
                if (!_pargTab) return {}

                let pargs = ParentRoomGroup.fetchFromJson(_pargTab.rooms)
                return {
                    rooms: pargs,
                    tab_name: _pargTab.tab_name,
                    user_filter: _pargTab.user_filter,
                    last_ids: _pargTab.last_ids,
                    last_search_at: _pargTab.last_search_at,
                    has_next: _pargTab.has_next,
                    total: _pargTab.total,
                    is_need_detail: _pargTab.is_need_detail,
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static changeStatus(
        parg: any,
        status: string,
        pd: PossibleDate = null,
        user_message: string = null,
        location_name: string = null,
        duration: number = null
    ): Promise<any> {
        let params = { parg: parg, status: status, user_message: user_message }

        // 確定時のみ、パラメータを追加
        if (pd) {
            params[`start_time`] = pd.start_time
            params[`duration`] = duration
            params[`location_name`] = location_name
        }
        return axios.post(`${Util.prefixUrl}/parent_room_groups/change_status`, params).then(res => {
            return res.data
        })
    }

    static blockTypeText(block_type: string, needExtraText: boolean = false) {
        if (block_type == ParentRoomGroup.BLOCK_1) return `ステップ1${needExtraText ? `進行中` : ``}`
        if (block_type == ParentRoomGroup.BLOCK_2) return `ステップ2${needExtraText ? `進行中` : ``}`
        if (block_type == ParentRoomGroup.BLOCK_3) return `ステップ3${needExtraText ? `進行中` : ``}`
        if (block_type == ParentRoomGroup.BLOCK_4) return `ステップ4${needExtraText ? `進行中` : ``}`
        if (block_type == ParentRoomGroup.BLOCK_5) return `ステップ5${needExtraText ? `進行中` : ``}`
        if (block_type == ParentRoomGroup.FIXER) return `決定者${needExtraText ? `確認中` : ``}`
        if (block_type == ParentRoomGroup.APPROVAL) return `承認者${needExtraText ? `確認中` : ``}`
        return ``
    }
}
