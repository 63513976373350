
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique, copyToClipboard, funcName } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import CopyButton from "packs/components/buttons/CopyButton.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import RoomMember from "packs/models/RoomMember"
import Room from "packs/models/Room"
import CalendarUtil from "packs/utils/CalendarUtil"

@Options({
    components: { TextfieldTag, CopyButton, VueFinalModal, TextareaTag },
})
export default class ModalRoomMembersLink extends Vue {
    // data:
    @Prop()
    members: RoomMember[]

    @Prop()
    room: Room

    isSP = isMobile(window.navigator).phone
    showTip = false
    copyFixText = ``

    openModal() {
        this.fixInfoText()
    }

    public goAvailableSchedule() {
        gtagClick(`調整カレンダー未作成→調整カレンダー作成`, ``)
        this.$router.push("/available_schedule")
    }

    public clickTextfield(index) {
        Logger(`clickTextfield ${index}`)
        $(`input.member${index}`).select()
        // $(this).select();
        // e.setSelectionRange(0, 100);
        // e.target.setSelectionRange(0, e.length);
    }

    @Watch(`room`)
    fixInfoText() {
        let text = ``
        if (!this.room) return
        let appo = this.room.current_appointment
        Logger(`${funcName()} appo:${appo?.id} name:${appo?.name} status:${appo?.status}`)
        if (!appo) return
        if (appo.status != 10) return

        let owner = this.room.owner

        // let start = DateTime.fromSeconds(appo.start_time)
        // let end = DateTime.fromSeconds(appo.end_time)
        text += `${CalendarUtil.toJpFormat(appo.start_time, appo.duration)}（${appo.duration}分）\n`

        if (appo.is_online_meeting) {
            let loctag = appo.selected_location
            let onlineLoc = appo.online_meeting
            Logger(`${funcName()} appo:${Util.output(appo)}`)
            let locname = loctag.keyword.replace(`(アカウント連携)`, ``)
            text += `オンライン(${locname}): ${onlineLoc.online_meeting_url}\n`
            text += onlineLoc.online_meeting_account_email ? `アカウント: ${onlineLoc.online_meeting_account_email}\n` : ``
            text += onlineLoc.online_meeting_id ? `ミーティングID: ${onlineLoc.online_meeting_id}\n` : ``
            text += onlineLoc.online_meeting_password ? `パスコード: ${onlineLoc.online_meeting_password}\n` : ``
            text += onlineLoc.online_meeting_zoom_host_key ? `ホストキー: ${onlineLoc.online_meeting_zoom_host_key}\n` : ``
        } else {
            text += appo?.selected_location?.keyword ? `場所: ${appo?.selected_location?.keyword}\n` : ``
        }

        let magDic = appo.selected_mag_dic
        if (magDic && owner) {
            let mag = magDic[owner.user_id]
            if (mag) {
                text += `担当: ${mag.team_name}`
            }
        }
        this.copyFixText = text
    }

    clickFixText() {
        $(`input.fixContent`).select()
    }

    inputFixContentValue(e) {
        this.copyFixText = e
    }
}
