
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { DateTime } from "luxon"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import RadioButtonTag from "packs/components/forms/RadioButtonTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomMessage from "packs/models/RoomMessage"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import Appointment from "packs/models/Appointment"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextareaTag,
        RadioButtonTag,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class ModalRequestAdditionalPossibleDates extends Vue {
    @Prop()
    isDev

    pdm = PossibleDatesManager

    // data:

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    typedMessage = ""
    text = ``
    action = `reschedule`
    loading = false
    sendMembers = []

    public created() {}

    public updated() {}

    public async mounted() {}

    @Watch("pdm", { deep: true })
    public updateSendMembers() {
        if (!this.pdm) return
        const sharedMems = this.pdm.sharedMembers || []

        let mems = sharedMems.filter(_mem => {
            return _mem.user_id != this.rm.userInfo.user_id
        })
        this.sendMembers = mems
    }

    public cancelInput() {
        // モーダルを閉じます。
        this.$vfm.close("RequestAdditionalPossibleDatesModal")
    }

    public selectAction(e) {
        if (this.loading) return
        this.action = e
    }

    send() {
        if (this.loading) return
        gtagClick(`sendRequestPds`, `${this.typedMessage} ${this.rm.currentRoom.id}`)

        this.loading = true
        AvailableScheduleTag.request(this.rm.currentRoom, this.typedMessage, this.rm.lp_id).then(e => {
            this.cancelInput()
            this.loading = false
        })
    }

    public inputMessage(e) {
        this.typedMessage = e
    }
}
