
import { escape } from "helpers"
import Util from "packs/utils/Util"
import { Options, Prop, Vue } from "vue-property-decorator"

@Options({})
export default class IconHelp extends Vue {
    @Prop() // アイコンの大きさ
    width: number

    @Prop() // 表示するテキスト
    tooltipText: string

    @Prop() // 詳しくはこちらのURL deprecated.
    detailUrl: string

    @Prop() // grey / white
    color: string

    $getAssetImageUrl!: (path: string) => string

    getColor() {
        if (Util.isBlank(this.color)) return `/assets/home/help_icon_dgrey.png`
        if (this.color == `grey`) return `/assets/home/help_icon_dgrey.png`
        if (this.color == `white`) return `/assets/home/help_icon_white.gif`
        return `/assets/home/help_icon_dgrey.png`
    }

    getTooltipContent() {
        if (!this.tooltipText) return null

        // 現在HTMLタグが使えないので、detailUrlを利用しないように
        const detailLink = this.detailUrl
            ? `<br><br><a class="fw600 whiteColor" target="_blank" href="${this.detailUrl}">詳しくはこちら</a>`
            : ""

        return `${this.tooltipText}${detailLink}`
    }
}
