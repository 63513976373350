import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Appointment from "packs/models/Appointment"
import RoomMessage from "packs/models/RoomMessage"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import Notification from "packs/models/NotificationAdmin"
import FormField from "packs/models/FormField"
import SearchedRoomsInfo from "packs/models/SearchedRoomsInfo"

interface Storable {
    getItem(key: string): string | null
    setItem(key: string, value: string): void
    removeItem(key: string): void
    clear()
}

const STORAGE_KEY_PREFIX = `${process.env.NODE_ENV} ${navigator ? navigator.appVersion : ``}` // ローカル/本番/ブラウザで分けるため
const STORAGE_KEY = `${STORAGE_KEY_PREFIX}LinkRoom` // 作成中の調整ページ [Room]
const STORAGE_KEY_PREVIEW = `${STORAGE_KEY_PREFIX}LinkRoomPreview` // 作成中の調整ページ [Room]
const STORAGE_KEY_STATUS = `${STORAGE_KEY_PREFIX}LinkRoomStatus` // 作成中の調整ページのステータス [string] new, edit, copy
const STORAGE_KEY_ASTAG = `${STORAGE_KEY_PREFIX}LinkRoomAstag` // Astagの編集画面. [AvailableScheduletag]
const STORAGE_KEY_ASTAG_FROM = `${STORAGE_KEY_PREFIX}LinkRoomAstagFrom` // どこから飛んできたか [string] chukaiNewRoom, chukaiEditRoom, new, edit
const STORAGE_KEY_LAST_ASTAG_ID = `${STORAGE_KEY_PREFIX}LinkRoomLastAstagID` // 最後に利用しているastagのID
const STORAGE_KEY_APPOINTMENT = `${STORAGE_KEY_PREFIX}LinkRoomAppointment` // Appoの編集画面. [Appointment]
const STORAGE_KEY_APPOINTMENT_PREVIEW = `${STORAGE_KEY_PREFIX}LinkRoomAppointmentPreview` // Appoの編集画面. [Appointment]
const STORAGE_KEY_SELECTED_ROOM_ID = `${STORAGE_KEY_PREFIX}LinkRoomSelectedRoomId` // 選択中のRoomのID
const STORAGE_KEY_SELECTED_PARENT_ROOM_ID = `${STORAGE_KEY_PREFIX}LinkRoomSelectedParentRoomId` // 選択中のRoomのID
const STORAGE_KEY_INPUT_MESSAGES = `${STORAGE_KEY_PREFIX}LinkRoomInputMessages` // 送信していないメッセージ入力をRoomIdをキーとして保存.
const STORAGE_KEY_UNSENT_MESSAGES = `${STORAGE_KEY_PREFIX}LinkRoomUnsentMessages` // 送信したが送信できなかったメッセージをRoomIdをキーとしてArrayで保存.
// const STORAGE_KEY_LOGGIN_STATUS = "LinkRoomLogginStatus" // ユーザーはログインしているか. true, false
const STORAGE_KEY_SCHEDULE_TYPE = `${STORAGE_KEY_PREFIX}LinkRoomScheduleType` // ユーザーはログインしているか. true, false
const STORAGE_KEY_NOT_FIX_START_TIME = `${STORAGE_KEY_PREFIX}LinkRoomNotFixStartTime` // 未確定の今回のfixしたい日程が入っている.
const STORAGE_KEY_OPEN_MESSAGE_WINDOW = `${STORAGE_KEY_PREFIX}LinkRoomNotFixStartTime` // 調整ページで窓を開いておくかどうか.
// const STORAGE_KEY_OPEN_MESSAGE_WINDOW_STATUS = `${STORAGE_KEY_PREFIX}LinkRoomOpenMessageWindowStatus` //タブを開いているか（未読のカウントアップに利用.）
const STORAGE_KEY_CURRENT_ROOMS_TAB = `${STORAGE_KEY_PREFIX}LinkRoomCurrentRoomsTabWithWho` // 調整ページ一覧で現在のタブを記憶.（自分/全体も一緒に）
const STORAGE_KEY_SUMMARY_TAB = `${STORAGE_KEY_PREFIX}LinkRoomSummaryTab` // 調整ページ一覧で現在のタブを記憶.（自分/全体も一緒に）
const STORAGE_KEY_CURRENT_PROGRESS_USER = `${STORAGE_KEY_PREFIX}LinkRoomCurrentProgressUser` // 進捗管理画面で現在のユーザー名.
const STORAGE_KEY_CURRENT_PROGRESS_FILTER = `${STORAGE_KEY_PREFIX}LinkRoomCurrentProgressFilter` // 進捗管理画面で現在のフィルター名
const STORAGE_KEY_REFRESHER = `${STORAGE_KEY_PREFIX}LinkRoomRefresher` // リフレッシュする内容を保存.
const STORAGE_KEY_OPEN_REPORT_DETAIL = `${STORAGE_KEY_PREFIX}LinkRoomReportOpenReportDetail` // レポートで開いているユーザーを保存
const STORAGE_KEY_SUGGESTED_ROOM_QUESTIONS = `${STORAGE_KEY_PREFIX}LinkRoomSuggestedRoomQuestions` // 投票ページ固定している質問項目をルームごとに保存
const STORAGE_KEY_SEARCH_ROOMS_DIC = `${STORAGE_KEY_PREFIX}LinkRoomSearchRoomsDic` // 検索かけた情報を保存

export default class RoomStorage {
    constructor(
        // デフォルト引数でローカルストレージを DI
        private storage: Storable = Util && Util.isEmbeds() ? null : localStorage
    ) {}

    public resetAll() {
        this.storage.clear()
    }

    // 現在の調整ページを保存する
    public save(room: Room) {
        if (Util.isEmbeds()) return null
        try {
            this.storage.setItem(STORAGE_KEY, JSON.stringify(room))
        } catch {}
    }

    // プレビュー用のルームを保存します。
    public saveRoomPreview(room: Room) {
        try {
            this.storage.setItem(STORAGE_KEY_PREVIEW, JSON.stringify(room))
        } catch (e) {
            // ストレージ容量超過時のエラーハンドリング
            Logger(`ストレージ保存エラー: ${e.message}`)
            // ストレージがいっぱいの場合、一度クリアしてから再試行
            try {
                // プレビューデータのみを削除
                this.storage.removeItem(STORAGE_KEY_PREVIEW)
                // 再度保存を試みる
                this.storage.setItem(STORAGE_KEY_PREVIEW, JSON.stringify(room))
            } catch (retryError) {
                // それでも失敗した場合
                Logger(`ストレージ再保存エラー: ${retryError.message}`)
            }
        }
    }

    // 現在の調整ページを取得する
    public fetchRoom(): Room {
        if (Util.isEmbeds()) return null
        const room: Room = JSON.parse(this.storage.getItem(STORAGE_KEY) || "{}")
        return room
    }

    // プレビュー用のルームを取得します。
    public fetchRoomPreview(): Room {
        const room: Room = JSON.parse(this.storage.getItem(STORAGE_KEY_PREVIEW) || "{}")
        return room
    }

    // 現在の調整ページ作成時のアクションを保存します。 copy, edit, new
    public saveAction(str: string) {
        if (Util.isEmbeds()) return null
        this.storage.setItem(STORAGE_KEY_STATUS, str)
    }

    // 現在の調整ページ作成時のアクションを保存します。
    public fetchAction(): string {
        if (Util.isEmbeds()) return ""
        return this.storage.getItem(STORAGE_KEY_STATUS) || ""
    }

    // すべてのルーム情報を削除します。
    public deleteRoomInfo() {
        this.storage.setItem(STORAGE_KEY_STATUS, null)
        this.storage.setItem(STORAGE_KEY, null)
    }

    /**
     * 現在のAstagの状態を保存します。 copy, edit, new
     * @param astag [AvailableScheduleTag]
     */
    public saveAstag(astag: AvailableScheduleTag) {
        this.storage.setItem(STORAGE_KEY_ASTAG, JSON.stringify(astag))
    }

    /**
     * 現在のAstagを取得します。
     */
    public fetchAstag(): AvailableScheduleTag {
        if (Util.isEmbeds()) return null
        const _astag = JSON.parse(this.storage.getItem(STORAGE_KEY_ASTAG) || "{}") as AvailableScheduleTag
        if (!_astag || !_astag.systemUpdatedAt) return _astag
        Logger(`fetchAstag parse後: ${Util.output(_astag)}`)
        const astag = AvailableScheduleTag.fetchFromJson([_astag])[0]
        Logger(`fetchAstag: 戻します::`)

        return astag
    }

    public saveLastAstagId(astagId: string) {
        this.storage.setItem(STORAGE_KEY_LAST_ASTAG_ID, astagId)
    }

    public fetchLastAstagId(): string {
        let _id = this.storage.getItem(STORAGE_KEY_LAST_ASTAG_ID)
        return _id && _id.length > 0 ? _id : null
    }

    /**
     * どのページから/available_scheduleに飛んできたか(用途含む)を保存します。 copy, edit, new
     * @param str
     */
    public saveAstagFrom(str: string) {
        this.storage.setItem(STORAGE_KEY_ASTAG_FROM, str)
    }

    /**
     * どのページから/available_scheduleに飛んできたかを取得します。
     */
    public fetchAstagFrom(): string {
        return this.storage.getItem(STORAGE_KEY_ASTAG_FROM) || ""
    }

    /**
     * 現在のAppointmentの状態を保存します。
     * @param astag [AvailableScheduleTag]
     */
    public saveAppointment(appo: Appointment) {
        if (Util.isEmbeds()) return
        Logger(`appoをストレージに保存: ${Util.output(appo)}`)
        this.storage.setItem(STORAGE_KEY_APPOINTMENT, JSON.stringify(appo))
    }

    /**
     * 現在のAppointmentを取得します。
     */
    public fetchAppointment(): Appointment {
        if (Util.isEmbeds()) return null
        try {
            const astag: Appointment = JSON.parse(this.storage.getItem(STORAGE_KEY_APPOINTMENT) || null)
            return astag
        } catch {
            return null
        }
    }

    /**
     * 現在のプレビュー用のAppointmentの状態を保存します。
     * @param astag [AvailableScheduleTag]
     */
    public saveAppointmentPreview(appo: Appointment) {
        Logger(`preview用appoをストレージに保存: ${Util.output(appo)}`)
        this.storage.setItem(STORAGE_KEY_APPOINTMENT_PREVIEW, JSON.stringify(appo))
    }

    /**
     * 現在のプレビュー用のAppointmentを取得します。
     */
    public fetchAppointmentPreview(): Appointment {
        try {
            const astag: Appointment = JSON.parse(this.storage.getItem(STORAGE_KEY_APPOINTMENT_PREVIEW) || null)
            return astag
        } catch {
            return null
        }
    }

    /**
     * 選択中のルームIDを保存します。
     * @param str
     */
    public saveSelectedRoomKey(str: string) {
        this.storage.setItem(STORAGE_KEY_SELECTED_ROOM_ID, str)
    }

    /**
     * 選択中のルームIDを取得します。
     */
    public fetchSelectedRoomKey(): string {
        if (Util.isEmbeds()) return ""
        return this.storage.getItem(STORAGE_KEY_SELECTED_ROOM_ID) || ""
    }

    /**
     * 選択中のルームIDを保存します。
     * @param str
     */
    public saveSelectedParentRoomId(str: string) {
        this.storage.setItem(STORAGE_KEY_SELECTED_PARENT_ROOM_ID, str)
    }

    /**
     * 選択中のルームIDを取得します。
     */
    public fetchSelectedParentRoomId(): string {
        try {
            return this.storage.getItem(STORAGE_KEY_SELECTED_PARENT_ROOM_ID)
        } catch {
            return ""
        }
    }

    /**
     * 送信していないメッセージ入力をRoomIdをキーとして保存します。
     * @param roomId [string] ルームID
     * @param message [RoomMessage] メッセージ
     */
    public saveInputMessage(roomId: string, message: RoomMessage) {
        if (Util.isEmbeds()) return
        let dic = this.fetchInputMessages()
        dic[roomId] = message
        this.storage.setItem(STORAGE_KEY_INPUT_MESSAGES, JSON.stringify(dic))
    }

    /**
     * 送信していないメッセージ入力を取得します。
     * @param roomId [string]
     * @return message [RoomMessage]
     */
    public fetchInputMessage(roomId: string): RoomMessage {
        if (Util.isEmbeds()) return
        const inputMessageDic = this.fetchInputMessages()
        return inputMessageDic[roomId] as RoomMessage
    }

    /**
     * 送信していないメッセージDicを取得（タイプ欄にあるもの）します。（key: RoomId, value: 入力途中のmessage）
     */
    public fetchInputMessages(): any {
        if (Util.isEmbeds()) return
        const inputMessageDic = JSON.parse(this.storage.getItem(STORAGE_KEY_INPUT_MESSAGES) || "{}")
        return inputMessageDic
    }

    // 送信ボタンを押下したあとの、送信できなかった未送信メッセージを保存
    public saveUnsentMessages(roomId: string, message: RoomMessage) {
        if (Util.isEmbeds()) return
        // let dic = this.fetchUnsentMessages() || {}
        let dic = {}
        let messages: RoomMessage[] = dic[roomId] || []
        dic[roomId] = messages.push(message)
        this.storage.setItem(STORAGE_KEY_UNSENT_MESSAGES, JSON.stringify(dic))
    }

    //
    // public fetchUnsentMessages() {
    //     const unsentMessageDic = JSON.parse(this.storage.getItem(STORAGE_KEY_UNSENT_MESSAGES) || "{}")
    //     return unsentMessageDic
    // }

    // ページを離れるときにすべてのみ送信のメッセージを削除します。
    public deleteUnsentMessagesAll() {
        if (Util.isEmbeds()) return
        this.storage.setItem(STORAGE_KEY_UNSENT_MESSAGES, null)
    }

    /**
     * 送信していないメッセージ入力をRoomIdをキーとして保存します。
     * @param type [string]
     */
    public saveScheduleType(type: string) {
        if (Util.isEmbeds()) return
        this.storage.setItem(STORAGE_KEY_SCHEDULE_TYPE, JSON.stringify(type))
    }

    /**
     * 送信していないメッセージ入力を取得します。
     * @return type [string]
     */
    public fetchScheduleType(): string {
        if (Util.isEmbeds()) return ""
        const type = JSON.parse(this.storage.getItem(STORAGE_KEY_SCHEDULE_TYPE) || "")
        return type
    }

    /**
     * 送信していないメッセージ入力をRoomIdをキーとして保存します。
     * @param start_time [string]
     */
    public saveScheduleStartTime(start_time: number) {
        if (Util.isEmbeds()) return
        this.storage.setItem(STORAGE_KEY_NOT_FIX_START_TIME, JSON.stringify(start_time))
    }

    /**
     * 送信していないメッセージ入力を取得します。
     * @return start_time [string]
     */
    public fetchScheduleStartTime(): number {
        if (Util.isEmbeds()) return 0
        const start_time = JSON.parse(this.storage.getItem(STORAGE_KEY_NOT_FIX_START_TIME) || "0")
        return start_time
    }

    // メッセージの開閉をルームごとに記憶.
    public saveOpenMessageWindow(roomId: string, open: boolean): void {
        if (Util.isEmbeds()) return
        let dic = {}
        try {
            dic = this.getOpenMessageWindowDic()
            dic[roomId] = open
        } catch {
            dic = {}
            dic[roomId] = open
        }

        this.storage.setItem(STORAGE_KEY_OPEN_MESSAGE_WINDOW, JSON.stringify(dic))
    }

    // メッセージの開閉情報を取得.
    public fetchOpenMessageWindow(roomId: string): boolean {
        if (Util.isEmbeds()) return
        const dic = this.getOpenMessageWindowDic()
        return dic[roomId]
    }

    // メッセージの開閉情報のDictionaryを取得.
    public getOpenMessageWindowDic(): any {
        if (Util.isEmbeds()) return

        let dic = {}
        try {
            dic = JSON.parse(this.storage.getItem(STORAGE_KEY_OPEN_MESSAGE_WINDOW) || "{}")
        } catch {
            dic = {}
        }
        // Logger(`room storage dic: ${Util.output(dic)}`)
        return dic
    }

    // 現在の調整ページを保存する
    public saveNotification(noti: Notification) {
        if (Util.isEmbeds()) return
        this.storage.setItem(STORAGE_KEY, JSON.stringify(noti))
    }

    // 現在の調整ページを取得する
    public fetchNotification(): Notification {
        if (Util.isEmbeds()) return
        const n: Notification = JSON.parse(this.storage.getItem(STORAGE_KEY) || "{}")
        return n
    }

    /**
     * 現在の調整ページ一覧のタブ情報を保存.
     * @param tabName categoryHoge名
     * @param whose 今の時点では all/self 全体/自分
     */
    public saveCurrentRoomsTab(tabName: string, whose: string) {
        if (Util.isEmbeds()) return
        this.storage.setItem(STORAGE_KEY_CURRENT_ROOMS_TAB, `${tabName} ${whose}`)
    }

    // 現在の調整ページを取得する
    public fetchCurrentRoomsTab(): string[] {
        if (Util.isEmbeds()) return []
        const n: string = this.storage.getItem(STORAGE_KEY_CURRENT_ROOMS_TAB) || "public self"
        return n.split(" ")
    }

    /**
     * サマリータブ情報を保存.
     * @param tabName categoryHoge名
     */
    public saveSummaryTab(tabName: string) {
        this.storage.setItem(STORAGE_KEY_SUMMARY_TAB, `${tabName}`)
    }

    // サマリータブ情報を取得.
    public fetchSummaryTab(): string {
        if (Util.isEmbeds()) return "pv"
        const n: string = this.storage.getItem(STORAGE_KEY_SUMMARY_TAB) || "pv"
        return n
    }

    /**
     * 進捗管理画面 現在の選択ユーザー名を保存.
     * @param tabName categoryHoge名
     * @param whose 今の時点では all/self 全体/自分
     */
    public saveCurrentProgressUserName(userName: string) {
        if (Util.isEmbeds()) return
        this.storage.setItem(STORAGE_KEY_CURRENT_PROGRESS_USER, `${userName}`)
    }

    // 進捗管理画面 現在の選択ユーザー名を取得する
    public fetchCurrentProgressUserName(): string {
        if (Util.isEmbeds()) return "すべて"
        const n: string = this.storage.getItem(STORAGE_KEY_CURRENT_PROGRESS_USER) || "すべて"
        return n
    }

    /**
     * 進捗管理画面 現在の選択ユーザー名を保存.
     * @param tabName categoryHoge名
     * @param whose 今の時点では all/self 全体/自分
     */
    public saveCurrentProgressFilterName(filterName: string) {
        if (Util.isEmbeds()) return
        this.storage.setItem(STORAGE_KEY_CURRENT_PROGRESS_FILTER, `${filterName}`)
    }

    // 進捗管理画面 現在の選択ユーザー名を取得する
    public fetchCurrentProgressFilterName(): string {
        if (Util.isEmbeds()) return "すべて"
        const n: string = this.storage.getItem(STORAGE_KEY_CURRENT_PROGRESS_FILTER) || "すべて"
        return n
    }

    public saveRefresher(refresher: string) {
        if (Util.isEmbeds()) return
        let n = this.fetchRefresher()
        n.push(refresher)
        this.storage.setItem(STORAGE_KEY_REFRESHER, JSON.stringify(n))
    }

    public removeRefresher() {
        if (Util.isEmbeds()) return
        this.storage.setItem(STORAGE_KEY_REFRESHER, JSON.stringify([]))
    }

    public fetchRefresher(): string[] {
        if (Util.isEmbeds()) return []
        const n: string[] = JSON.parse(this.storage.getItem(STORAGE_KEY_REFRESHER)) || []
        return n
    }

    public saveOpenReportUserIDs(userId: string) {
        let n = this.fetchOpenReportUserIDs()
        n.push(userId)
        this.storage.setItem(STORAGE_KEY_OPEN_REPORT_DETAIL, JSON.stringify(n))
        return n
    }

    public removeOpenReportUserIDs(userId: string) {
        let n = this.fetchOpenReportUserIDs()
        n = n.filter(_id => _id != userId)
        this.storage.setItem(STORAGE_KEY_OPEN_REPORT_DETAIL, JSON.stringify(n))
        return n
    }

    public fetchOpenReportUserIDs(): string[] {
        const n: string[] = JSON.parse(this.storage.getItem(STORAGE_KEY_OPEN_REPORT_DETAIL)) || []
        return n
    }

    public saveDisplayFieldsInPublicRoom(fs: FormField[], roomId: string) {
        let dic = this.fetchDisplayFieldsInPublicRoom()
        dic[roomId] = fs
        this.storage.setItem(STORAGE_KEY_SUGGESTED_ROOM_QUESTIONS, JSON.stringify(dic))
        return dic
    }

    public fetchDisplayFieldsInPublicRoom(roomId: string = null) {
        const dic: any = JSON.parse(this.storage.getItem(STORAGE_KEY_SUGGESTED_ROOM_QUESTIONS)) || {}
        if (roomId) {
            return dic[roomId] || []
        } else {
            return dic || {}
        }
    }

    /**
     * 現在のSearchedRoomsInfoの状態を保存します。
     * @param astag [AvailableScheduleTag]
     */
    public saveSearchedRoomsInfo(info: SearchedRoomsInfo) {
        Logger(`appoをストレージに保存: ${Util.output(info)}`)
        this.storage.setItem(STORAGE_KEY_SEARCH_ROOMS_DIC, JSON.stringify(info))
    }

    /**
     * 現在のSearchedRoomsInfoを取得します。
     */
    public fetchSearchedRoomsInfo(): SearchedRoomsInfo {
        const info: SearchedRoomsInfo = JSON.parse(this.storage.getItem(STORAGE_KEY_SEARCH_ROOMS_DIC) || null)
        return info
    }
}
