
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger, funcName, sleep } from "packs/common"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import FormFieldContent from "packs/pages/link/parts/room_settings/FormFieldContent.vue"
import UrlWithQRcodeContent from "packs/components/common/UrlWithQRcodeContent.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import AddCommentToMail from "packs/pages/link/parts/room_settings/AddCommentToMail.vue"
import SendMailContent from "packs/pages/link/parts/available_schedule/SendMailContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"
import TemplatesContent from "packs/pages/link/parts/room_settings/TemplatesContent.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import ChipButton from "packs/components/buttons/ChipButton.vue"
import PargSectionsContent from "packs/pages/link/parts/room_summary/PargSectionsContent.vue"
import LeftPanelSettingsContent from "packs/pages/link/parts/room_summary/LeftPanelSettingsContent.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
import FormField from "packs/models/FormField"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomTab from "packs/models/RoomTab"
import MailFlow from "packs/models/MailFlow"
import Notice from "packs/models/Notice"
import ContactListGroup from "packs/models/ContactListGroup"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

@Options({
    components: {
        SelectTag,
        FormFieldContent,
        UrlWithQRcodeContent,
        AddressBookContent,
        UserIcon,
        AddCommentToMail,
        SendMailContent,
        VueFinalModal,
        MdProgressBar,
        MdProgressSpinner,
        TemplatesContent,
        TextfieldTag,
        ChipButton,
        PargSectionsContent,
        LeftPanelSettingsContent,
    },
})
export default class CreateFeedbackModal extends Vue {
    @Prop()
    room: Room

    @Prop()
    member: RoomMember

    // data
    rm = RoomManager
    rcm = RoomCreateManager

    loading = false
    isMe = false

    results = null
    Util = Util
    // currentRoom: Room = null

    rooms: Room[] = null
    pubRoomsArray: any[] = []
    publics: string[] = null

    /**
     * ボタンに設定している公開ページ
     * ParentRoomのユーザー（member）を指定してきた場合のみ入ります.
     */
    defaultPubName = null
    selectedPubRoom: Room = null
    fields: FormField[] = null
    systemUpdatedAt = Util.getSec()

    additionalParams = {}
    generatedUrl = null

    step = 0

    currentMember: RoomMember = null
    editOrNewForm = ``
    members: RoomMember[] = []
    UserPermissionsOption = UserPermissionsOption
    titles = TemplateUtil.getTitles()
    mailsDic: { [key: string]: MailFlow } = {} // {key: user.email, value: {subject:, content:, cc_emails:, use_template: false}}
    currentMail: MailFlow = null
    selectedClg: ContactListGroup = null

    viewType = `form` // form, mail, parg
    tmp: MessageTemplate = null

    // 作成されたルーム情報 Array<{room: Room, room_link: string}>
    generatedRoomsDic: any[] = null

    showCc = false
    currentMemCcEmails = ``

    openModal() {
        Logger(`CreateFeedbackModal:openModal room_id:${this.room ? this.room.id : null}`)
        this.rcm.viewType = `addressbook`
        this.updateRoom()
        this.updateUserList()
    }

    @Watch("room", { deep: true })
    updateRoom() {
        if (Util.isBlank(this.room)) return
        // if (this.selectedPubRoom) return
        Logger(`CreateFeedbackModal#updateRoom`)

        if (this.room && this.room.room_type == `public_room`) {
            this.selectedPubRoom = Room.copy(this.room)
            this.publics = []
            this.selectPubRoomEnd()
        }
        // else {
        //     this.setPublicRooms()
        // }
    }

    hide() {
        if (confirm(`閉じるとリセットされますが、よろしいですか？`)) {
            this.resetRooms()
            this.selectedPubRoom = null
            this.$vfm.close(`CreateFeedbackModal`)
        }
    }

    // PublicRoomの選択をしたら次のステップへ行きます.
    selectPubRoomEnd() {
        Logger(`CreateFeedbackModal#selectPubRoomEnd`)
        if (this.fields) return
        // let fields = this.selectedPubRoom.form_fields ? [...this.selectedPubRoom.form_fields] : []

        // this.additionalParams = {
        //     name: this.member ? this.member.name : null,
        //     email: this.member ? this.member.email : null,
        //     company_name: this.member ? this.member.company_name : null,
        // }
        // let _fields = FormFieldCreator.setValueFromAdditionalParams(fields, this.additionalParams)

        // this.fields = [...(_fields || [])]
        // this.fields = fields
        this.step = 1
        this.rcm.setNew(
            this.selectedPubRoom,
            this.selectedPubRoom.current_appointment,
            null,
            null,
            this.rm.userInfo,
            this.rm.user_setting,
            `new`
        )
        this.fields = this.rcm.realFields()
    }

    updateFromChild(field: FormField) {
        Logger(`update field val: value_attr:${field.value_attr}, selected_values: ${field.selected_values}`)
        // let _f = field
        if (Util.isBlank(this.rcm.currentSendMem)) {
            Logger(`isBlank currentSendMem`)
            let mem = RoomMember.createDefaultClient()
            mem.answer_results = {}
            this.members = [mem]
            this.rcm.currentSendMem = mem
        } else {
            Logger(`すでにあります。this.rcm.currentSendMem: ${Util.output(this.rcm.currentSendMem)}`)
        }
        if (field.param_key == `attendee1_name`) {
            this.rcm.currentSendMem.name = field.value_attr
        } else if (field.param_key == `attendee1_email`) {
            this.rcm.currentSendMem.email = field.value_attr
        } else if (field.param_key == `attendee1_company_name`) {
            this.rcm.currentSendMem.company_name = field.value_attr
        }

        // let _f = this.fields.find(_f => _f.param_key == field.param_key)
        // _f = Object.assign(_f, field)
        let value = null
        let _f = this.fields.find(f => f.param_key == field.param_key)
        if (Util.isPresent(field.value_attr)) {
            value = field.value_attr
            if (Util.isPresent(_f)) _f.value_attr = value
        } else {
            value = field.selected_values || []
            if (Util.isPresent(_f)) _f.selected_values = field.selected_values
        }
        if (Util.isPresent(value)) {
            this.rcm.currentSendMem.answer_results[field.param_key] = value
        }
    }

    generateUrl() {
        let additionalParams = {
            hide_filled_fields: `true`,
            skip_form: `true`,
        }
        // let url = `${this.selectedPubRoom.page_url}?`
        let paramString = ``
        for (let [_key, _value] of Object.entries(this.rcm.currentSendMem.answer_results)) {
            if (Util.isPresent(_value)) {
                paramString += `${_key}=${_value}&`
            }
        }
        for (let [_key, _value] of Object.entries(additionalParams)) {
            if (Util.isPresent(_value)) {
                paramString += `${_key}=${_value}&`
            }
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        Room.generateShortUrl(this.selectedPubRoom, paramString).then(url => {
            this.loading = false
            this.rm.endProgress()
            if (url) {
                Logger(`url : ${url} ? params: ${Util.output(this.additionalParams)}`)
                Logger(`encode: ${encodeURI(url)}`)
                this.generatedUrl = encodeURI(url)
                this.step = 2
            }
        })
    }

    selectConnectPage(e) {
        Logger(`selectConnectPage : ${e}`)
        let _dic = this.pubRoomsArray.find(dic => dic.name == e)
        let _room = this.rooms.find(r => r.id == _dic.type)
        this.defaultPubName = e
        this.selectedPubRoom = { ..._room }
    }

    public deselect() {
        Logger(`選択した公開ページFEEDBACKをはずします.`)
        this.defaultPubName = null
        this.selectedPubRoom = null
    }

    public restart() {
        this.additionalParams = {}
        if (this.defaultPubName) {
            // 公開ページから選択
            this.selectedPubRoom = null
            this.defaultPubName = null
            this.fields = null

            this.step = 0
        } else {
            // step==1から開始
            this.step = 1
        }
    }

    @Watch("rcm.userList", { deep: true })
    updateUserList() {
        Logger(`${funcName()} userList:${this.rcm.userList.length}`)
        this.members = this.rcm.userList
    }

    public addAttendee(member: RoomMember) {
        Logger(`${funcName()} member:${Util.output(member)}`)
        gtagClick(`出席者追加`, `${member.name}`)
        // if (member.email == this.rm.userInfo.email) return

        let _mem = this.members.find(m => m.email == member.email)

        if (this.rcm.addressbookType) {
            if ([ParentRoomGroup.FIXER, ParentRoomGroup.APPROVAL].includes(this.rcm.addressbookType)) {
                // 1人のみ指定可能なため、いったん削除します.
                // this.members = this.members.filter(m => m.block_type != this.rcm.addressbookType)
                if ([ParentRoomGroup.FIXER, ParentRoomGroup.APPROVAL].includes(this.rcm.addressbookType)) {
                    let mem = RoomMember.copy(member)
                    mem.line_parent_room ||= {}
                    mem = RoomMember.setAnswerResultsFromMember(mem)
                    mem.line_parent_room.block_type = this.rcm.addressbookType
                    if (this.rcm.addressbookType == ParentRoomGroup.FIXER) {
                        this.rcm.fixers = [mem]
                    } else {
                        this.rcm.approvers = [mem]
                    }

                    this.rcm.addressbookType = `block_1`
                    this.rcm.viewType = `fixer`
                    return
                }
            }
        }

        if (Util.isBlank(_mem)) {
            // answer_resultsの内容を作成します.
            member = RoomMember.setAnswerResultsFromMember(member)
            Logger(`addAttendee:member: ${Util.output(member)}`)
            this.members.push(member)
        } else {
            Object.assign(_mem, member)
        }

        if (this.members.length == 1) {
            this.selectSendUser(member)
        }
        this.rcm.updateUserList(this.members)
        Logger(`${funcName()}  members:${this.members.length}`)
        this.currentMember = null
        // this.rcm.updateMembers(this.members)
        this.editOrNewForm = ``
        if ([ParentRoomGroup.FIXER, ParentRoomGroup.APPROVAL].includes(this.rcm.addressbookType)) {
            // 前の画面に戻ります.
            this.rcm.addressbookType = `block_1`
            this.rcm.viewType = `fixer`
        }
    }

    selectContactListGroup(clg: ContactListGroup) {
        if (clg.total_num >= 10) {
            this.selectedClg = clg
            // Notice.message = `グループに登録されている人数が多いため、表示できません。`
            return
        }
        if (clg.is_fetched) {
            this.addAttendeesFromGroup(clg)
        } else {
            if (this.loading) return
            this.loading = true

            ContactListGroup.fetch(clg.id).then(res => {
                this.loading = false
                if (res) {
                    let _clg = res.contact_list_group
                    this.addAttendeesFromGroup(_clg)

                    // this.edittingGroup = { ...clg }
                    // let __clg = this.clgs.find(clg => clg.id == res.contact_list_group.id)
                    // _clg.is_fetched = true
                    // _clg.contact_lists = clg.contact_lists
                }
            })
        }
    }

    addAttendeesFromGroup(clg: ContactListGroup) {
        let cls = [...clg.contact_lists]
        let mems = [...(this.members || [])]
        let userIds = mems.map(cl => cl.user_id)

        if ([ParentRoomGroup.FIXER, ParentRoomGroup.APPROVAL].includes(this.rcm.addressbookType)) {
            Notice.message = `日程決定者と日程承認者は、グループから追加できません。`
            return
        }

        for (let _mem of cls) {
            if (!userIds.includes(_mem.user_id)) {
                _mem = RoomMember.setAnswerResultsFromMember(_mem)
                if (this.rcm.addressbookType) {
                    _mem.line_parent_room ||= {}
                    _mem.line_parent_room.block_type = this.rcm.addressbookType
                }
                mems.push(_mem)
            }
        }

        if ((this.members || []).length == 0 && mems.length > 0) {
            this.selectSendUser(mems[0])
        }
        this.members = mems
        this.rcm.updateUserList(this.members)
        Logger(`${funcName()} members:${this.members.length}`)
        this.currentMember = null
        // this.rcm.updateMembers(this.members)
        this.editOrNewForm = ``
    }

    deleteSendUser(mem: RoomMember) {
        this.members = this.members.filter(m => m != mem)
        if (mem.email == this.rcm.currentSendMem.email) {
            if (Util.isPresent(this.members)) {
                // this.currentMember = this.members[0]
                this.selectSendUser(this.members[0])
            } else {
                // 送信先がない場合は、アドレス帳画面に戻る.
                this.fields = FormFieldCreator.resetFieldValues(this.fields)
                this.viewType = `form`
            }
        }
    }

    selectSendUser(mem: RoomMember) {
        Logger(`${funcName()} member.email:${mem.email} mem:${Util.output(this.rcm.realFields())}`)
        // フィールドに穴埋めします.
        if (this.viewType == `form`) {
            this.rcm.currentSendMem = mem
            // let fields = this.selectedPubRoom.form_fields ? [...this.selectedPubRoom.form_fields] : []
            let fields = this.rcm.realFields()
            fields = FormFieldCreator.resetFieldValues(fields)

            let _fields = FormFieldCreator.setValueFromAdditionalParams(fields, mem.answer_results)

            this.fields = Util.isPresent(_fields) ? [..._fields] : []
            Logger(`selectSendUser: セットされたフィールド: ${Util.output(fields)}`)
            this.systemUpdatedAt = Util.getSec()
        } else if (this.viewType == `mail`) {
            //

            let mailDic: MailFlow = { ...this.mailsDic[mem.email] }
            Logger(`切り替え後: mailDic[${mem.email}]: ${Util.output(mailDic)}`)
            this.currentMail = mailDic
            this.rcm.currentSendMem = mem
        }
    }

    backToSelectMember() {
        this.viewType = `form`
    }

    public editAttendee(member) {
        this.currentMember = member
        this.editOrNewForm = `edit`
        gtagClick(`出席者を編集`, `出席者:${this.members.length}`)
        // this.$vfm.open("AttendeesModal");
        // this.saveForPreview()
    }

    createParentRoom(sendMail: boolean) {
        let mems = [...(this.members || [])]
        mems = mems.filter(m => Util.isPresent(m.email))

        if (Util.isBlank(this.members) && Util.isBlank(this.selectedClg)) {
            Notice.message = `調整相手の情報を入力してください。`
            return
        }
        if (Util.isBlank(mems) && Util.isBlank(this.selectedClg)) {
            Notice.message = `メールアドレスが未入力のフォームがあるため、メールを送信できません。`
            return
        }

        Logger(
            `${funcName()} mems:${mems.length}, selectedClg:${this.selectedClg?.name}, selectedClg.total_num:${
                this.selectedClg?.total_num
            }, sendMail:${sendMail}`
        )

        this.rcm.room = this.selectedPubRoom
        // メール送信タイプ
        if (sendMail) {
            this.rcm.createType = `send_mail`
            if (this.rcm.room.default_message_template_id) {
                if (this.rm.templates) {
                    let tmp = this.rm.templates.find(tmp => tmp.id == this.rcm.room.default_message_template_id)
                    if (tmp) {
                        this.tmp = { ...tmp }
                    }
                    this.setMailFlowAndChangeView(mems)
                } else {
                    this.rm.getTemplates().then(res => {
                        if (res) {
                            let tmp = res.find(tmp => tmp.id == this.rcm.room.default_message_template_id)
                            if (tmp) {
                                this.tmp = { ...tmp }
                            }
                        }
                        this.setMailFlowAndChangeView(mems)
                    })
                }
            } else {
                // テンプレがない場合は、デフォルトの内容のメールを作成します.
                this.setMailFlowAndChangeView(mems)
            }
        } else {
            this.rcm.createType = `except_first_mail`
            if (this.loading) return
            this.loading = true
            this.rm.startProgress()
            // メールを送信しないため、このまま作成して発行
            this.rcm
                .createParentRoomsFromPublicRoom(
                    this.members,
                    null,
                    null,
                    null,
                    `public_rooms/create_room_from_public_room`,
                    null
                )
                .then(res => {
                    this.loading = false
                    this.rm.endProgress()
                    Logger(`createParentRoomsFromPublicRoom: return ${Util.output(res)}`)
                    if (res.isSuccess) {
                        this.generatedRoomsDic = res.generated_rooms_dic
                        this.step = 2

                        // 作成されたroomはroomtabにも追加しておきます.
                        if (Util.isPresent(this.rm.roomTabs) && Util.isPresent(this.generatedRoomsDic)) {
                            let rooms: Room[] = this.generatedRoomsDic.map(dic => dic.room)
                            RoomTab.sortAutomatically(this.rm.roomTabs, rooms, this.rm.userInfo)
                        }
                    }
                })
        }
    }

    createParentRoomGroup() {
        if (Util.isBlank(this.rcm.userList)) {
            Notice.message = `調整相手の情報を入力してください。`
            return
        }

        if (this.rcm.fixers.length == 0 && !this.rcm.parg.use_ai_fix) {
            Notice.message = `日程決定者もしくはAIが確定する必要があります。`
            return
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        this.rcm
            .createParentRoomsFromPublicRoom(this.rcm.userList, null, null, null, `parent_room_groups/create_rooms_group`, null)
            .then(res => {
                this.loading = false
                this.rm.endProgress()
                Logger(`createParentRoomGroup return ${Util.output(res)}`)
                if (res.isSuccess) {
                    // 作成されたroomはroomtabにも追加しておきます.
                    if (Util.isPresent(this.rm.roomTabs) && Util.isPresent(this.generatedRoomsDic)) {
                        let rooms: Room[] = this.generatedRoomsDic.map(dic => dic.room)
                        RoomTab.sortAutomatically(this.rm.roomTabs, rooms, this.rm.userInfo)
                    }
                    this.step = 2
                }
            })
    }

    setMailFlowAndChangeView(mems: RoomMember[]) {
        Logger(`${funcName()} mems:${mems?.map(m => m.name)}, tmp:${this.tmp?.title}`)
        // dicは、{key: user.email, value: {subject:, content:, cc_emails:, use_template: false}}
        let dic = {}
        // 設定をリセットします.
        this.currentMail = null
        this.rcm.currentSendMem = null

        let _tmp = null
        if (this.tmp) {
            _tmp = { ...this.tmp }
        }

        if (mems.length == 0 && this.selectedClg) {
            let mf = MailFlow.createDefaultForUser(null)
            if (_tmp) {
                mf.subject = _tmp.subject
                mf.content = _tmp.content
                // mf = MailFlow.filledTemplate(null, mf)
            }
            dic[this.selectedClg.id] = mf
            this.currentMail = mf
        }

        for (let mem of mems) {
            let mf = MailFlow.createDefaultForUser(mem)
            if (_tmp) {
                mf.subject = _tmp.subject
                mf.content = _tmp.content
                mf = MailFlow.filledTemplate(mem, mf)
            }

            // let mem = this.members.find(m => m.email == email)

            dic[mem.email] = mf

            if (!this.currentMail) {
                this.currentMail = dic[mem.email]
                this.rcm.currentSendMem = mem
            }
        }
        if (this.members.length != mems.length) {
            this.members = mems
        }
        this.mailsDic = dic
        this.viewType = `mail`
    }

    sendAndCreate() {
        // 最終更新.

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        // メールを送信して発行
        this.rcm
            .createParentRoomsFromPublicRoom(
                this.members,
                { ...this.mailsDic },
                null,
                null,
                `public_rooms/create_room_from_public_room`,
                null
            )
            .then(res => {
                this.loading = false
                this.rm.endProgress()
                Logger(`createParentRoomsFromPublicRoom: return ${Util.output(res)}`)
                if (res.isSuccess) {
                    this.generatedRoomsDic = res.generated_rooms_dic
                    this.step = 2

                    // 作成されたroomはroomtabにも追加しておきます.
                    if (Util.isPresent(this.rm.roomTabs) && Util.isPresent(this.generatedRoomsDic)) {
                        let rooms: Room[] = this.generatedRoomsDic.map(dic => dic.room)
                        RoomTab.sortAutomatically(this.rm.roomTabs, rooms, this.rm.userInfo)
                    }
                }
            })
    }

    // 個別に送るメール内容を編集した場合に通知がきます.
    updateMail(mf: MailFlow) {
        this.currentMail = mf
        if (this.rcm.currentSendMem) {
            this.mailsDic[this.rcm.currentSendMem.email] = mf
        } else if (this.selectedClg) {
            this.mailsDic[this.selectedClg.id] = mf
        }
    }

    inputSubject(e: string, tmpId: string) {
        Logger(`inputTemplateContent ${e}`)
        let _mf = this.rcm.selectedMailFlow

        if (_mf.template_public_id != tmpId) {
            _mf.template_public_id = tmpId
        }

        if (_mf) {
            _mf.subject = e
            this.rcm.updateMailFlow(_mf)
        }
    }

    decideTemplate(tmp: MessageTemplate) {
        Logger(`${funcName()} tmp:${Util.output(tmp)}`)
        this.tmp = tmp
        let mailsDic = { ...this.mailsDic }
        this.currentMail = null
        Object.entries(mailsDic).forEach(([email, mf]) => {
            let _tmp = { ...tmp }
            // value.template_public_id = tmp.public_id
            mf.subject = _tmp.subject
            mf.content = _tmp.content

            let _mf
            if (Util.isPresent(this.selectedClg)) {
                _mf = mf
            } else {
                let mem = this.members.find(m => m.email == email)
                _mf = MailFlow.filledTemplate(mem, mf)
            }

            mailsDic[email] = _mf

            if (!this.currentMail) {
                this.currentMail = { ..._mf }
                this.rcm.currentSendMem = this.members.find(m => m.email == email)
            }
        })
        this.mailsDic = mailsDic
    }

    public inputTemplateContent(e: string, tmpId: string) {
        Logger(`inputTemplateContent ${e}`)
        let _mf = this.rcm.selectedMailFlow

        if (_mf.template_public_id != tmpId) {
            _mf.template_public_id = tmpId
        }

        if (_mf) {
            _mf.content = e
            this.rcm.updateMailFlow(_mf)
        }
    }

    resetRooms() {
        if (Util.isPresent(this.members)) {
            for (let m of this.members) {
                m.answer_results = {}
            }
        }

        this.members = []
        this.rcm.currentSendMem = null
        this.viewType = `form`
        // this.selectedPubRoom.form_fields
        let _fs = FormFieldCreator.resetFieldValues(this.rcm.realFields())
        this.selectedPubRoom.form_fields = _fs
        this.additionalParams = {}
        this.currentMemCcEmails = ``
        this.showCc = false

        if (this.rcm) {
            this.rcm.currentSendMem = null
            this.rcm.userList = []
            this.rcm.viewType = `addressbook`
            this.rcm.parg = ParentRoomGroup.createDefault()
            this.rcm.addressbookType = `block_1`
            this.rcm.fixers = []
            this.rcm.approvers = []
            this.rcm.createType = ``
        }

        this.restart()
    }

    updateSelectedRoom(room: Room) {
        Logger(`${funcName()} room:${room?.id}`)
        this.$emit(`updateSelectedRoom`, room)
    }

    addCc() {
        if (this.showCc) {
            this.showCc = false
        } else {
            this.showCc = true
        }
    }

    enterCc() {
        Logger(`${funcName()} `)
        let email = this.currentMemCcEmails
        if (!Util.validateEmail(email)) {
            return
        }
        let ccsstring = this.rcm.currentSendMem.cc_emails
        if (Util.isBlank(ccsstring)) {
            ccsstring = email
        } else {
            let ccs = ccsstring.split(`,`)
            if (ccs.includes(email)) {
                Notice.message = `すでにCCに追加されています。`
                return
            }
            ccs.push(email)
            ccs = ccs.filter(cc => Util.validateEmail(cc))
            ccsstring = ccs.join(`,`)
        }
        this.rcm.currentSendMem.cc_emails = ccsstring
        this.currentMemCcEmails = ``
    }

    deleteCcEmail(email: string) {
        Logger(`${funcName()} email:${email}`)
        let _mem = this.rcm.currentSendMem
        if (Util.isBlank(_mem)) return
        let _ccs = _mem.cc_emails.split(`,`) || []
        _mem.cc_emails = _ccs.filter(cc => cc != email).join(`,`)
        this.rcm.currentSendMem.cc_emails = _mem.cc_emails
    }

    inputCc(e) {
        Logger(`${funcName()} e:${e}`)
        this.currentMemCcEmails = e
    }

    changeViewType(type: string) {
        Logger(`${funcName()} type:${type}`)
        if (type == `parg`) {
            this.rcm.viewType = `appoInfo`
            this.rcm.createType = ``
        } else {
            this.rcm.viewType = `addressbook`
        }
        this.viewType = type
    }
}
