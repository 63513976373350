
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, sleep, funcName } from "packs/common"
import { gtagClick, gtagHover } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import SelectableImage from "packs/components/images/SelectableImage.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import AppointmentInfoContent from "packs/pages/link/AppointmentInfoContent.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import SelectLocationsList from "packs/pages/link/parts/room_settings/SelectLocationsList.vue"
import FormFieldEditContent from "packs/pages/link/parts/room_settings/FormFieldEditContent.vue"
import FormFieldsList from "packs/pages/link/parts/room_settings/FormFieldsList.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import ThanksPageEditContent from "packs/pages/link/parts/room_settings/ThanksPageEditContent.vue"
import AttendeesTable from "packs/pages/link/parts/room_settings/AttendeesTable.vue"
import MailEditContent from "packs/pages/link/parts/room_settings/MailEditContent.vue"
import ModalTemplates from "packs/pages/link/modals/ModalTemplates.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"

import TemplateForAiEditContent from "packs/pages/link/parts/room_settings/left_panel_parts/TemplateForAiEditContent.vue"
import AppointmentCardViewSmall from "packs/pages/link/parts/available_schedule/AppointmentCardViewSmall.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import UserFile from "packs/models/UserFile"
import Room from "packs/models/Room"
import LocationTag from "packs/models/LocationTag"
import FormField from "packs/models/FormField"
import MessageTemplate from "packs/models/MessageTemplate"
import RoomSetting from "packs/models/RoomSetting"
import UserSetting from "packs/models/UserSetting"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomCreateManager from "packs/models/RoomCreateManager"
import AppealContent from "packs/models/AppealContent"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import LocationConverter from "packs/models/LocationConverter"
import FormCreateManager from "packs/models/FormCreateManager"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

@Options({
    components: {
        SelectableImage,
        AddressBookContent,
        Switcher,
        AppointmentInfoContent,
        TextareaTag,
        SelectLocationsList,
        FormFieldEditContent,
        FormFieldsList,
        TextfieldTag,
        LblueListContent,
        ThanksPageEditContent,
        AttendeesTable,
        MailEditContent,
        ModalTemplates,
        SelectTag,

        TemplateForAiEditContent,
        AppointmentCardViewSmall,
    },
})
export default class PargProgressPanel extends Vue {
    // data:

    // image: ヘッダー画像, headerContent: ヘッダーテキスト, attendees: 出席者, locations: appoの場所選択, astag: 調整カレンダー選択
    // appo: タイトル/時間, message: オーナーからのメッセージ, pptos: pp/tosの変更

    @Prop()
    isPreview: boolean

    @Prop()
    isSuspend: boolean

    @Prop() // func
    linkCal

    @Prop()
    editingField: FormField

    @Prop()
    astag: AvailableScheduleTag

    rm = RoomManager
    rcm = RoomCreateManager
    pdm = PossibleDatesManager
    fcm = FormCreateManager

    groupTitle = ""
    panelType: string = null
    parg: ParentRoomGroup = null
    isSP = isMobile(window.navigator).phone
    DateTime = DateTime

    showTip = false

    // beforeAppo: Appointment = null;
    Appointment = Appointment
    ParentRoomGroup = ParentRoomGroup

    appoStatus = Appointment.typeNew

    notice = Notice
    Util = Util
    UserPermissionsOption = UserPermissionsOption
    CalendarUtil = CalendarUtil
    saving = false

    locationName = ``
    currentAppo: Appointment = null

    systemUpdatedAt = Util.getSec()

    openSuggestedDatesMem: RoomMember = null

    mounted() {}

    public openPanel(panelType: string, parg: ParentRoomGroup) {
        gtagClick(`open PargProgressPanel`, ``)
        this.panelType = panelType
        this.parg = parg
        $(".pargProgressPanel").addClass("open")
    }

    public closePanel() {
        gtagClick(`close PargProgressPanel`, ``)
        this.panelType = null
        $(".pargProgressPanel").removeClass("open")
    }

    changeSendChannelMode() {
        if (this.rcm.showSMSField) {
            this.rcm.showSMSField = false
        } else {
            this.rcm.showSMSField = true
        }
    }

    get canSendSMS() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_send_sms`, false)
    }

    clickRemind(mem: RoomMember) {
        Logger(`${funcName()} mem:${mem.id}`)
        this.$emit(`openRemindModal`, {
            from: `parg`,
            parg: this.parg,
            mem: mem,
            lp_id: mem.line_parent_room.id,
        })
    }

    showUrl(mem: RoomMember) {
        Logger(`${funcName()} mem:${mem.id}`)

        let url = mem.line_parent_room.open_uuid_url
        copyToClipboard(url)
        this.notice.message = `調整URLをコピーしました。`
    }

    suggestedCount(block: string) {
        let totalMems = this.parg.step_rooms_dic[block]
        let suggestedMems = totalMems.filter(mem => mem.line_parent_room.last_suggested_at)
        return `${suggestedMems.length} / ${totalMems.length}`
    }

    clickRemindForAll() {
        Logger(`${funcName()}`)
        this.$emit(`openRemindModal`, {
            from: `parg`,
            parg: this.parg,
            mem: null,
            lp_id: null,
        })
    }

    showOptionalSchedules(mem: RoomMember) {
        Logger(`${funcName()} mem:${mem.id}`)
        this.openSuggestedDatesMem = mem
    }

    isShowOptionalSchedules(mem: RoomMember) {
        return this.openSuggestedDatesMem?.user_id == mem?.user_id
    }

    editEditableContent(content: string) {
        Logger(`${funcName()} content:${content}`)
    }
}
