
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, funcName, sleep } from "packs/common"
import { gtagClick, gtagHover } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import Switcher from "packs/components/forms/Switcher.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomCreateManager from "packs/models/RoomCreateManager"
import AppealContent from "packs/models/AppealContent"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import CalendarManager from "packs/models/CalendarManager"

@Options({
    components: {
        Switcher,
        CalendarIcon,
        IconHelp,
    },
})
export default class LeftRoomSettingsTotalMenu extends Vue {
    // data:

    @Prop() // func
    editEditableContent

    @Prop() // func
    selectTab

    @Prop()
    selectedTabName: string

    @Prop() // func
    clickNewAstag

    rm = RoomManager
    rcm = RoomCreateManager
    pdm = PossibleDatesManager
    cm = CalendarManager

    groupTitle = ""
    isSP = isMobile(window.navigator).phone
    fixedTypes = [`fixed`, `already`]

    attendees: RoomMember[] = null // room.attendeesと同義.
    owner: RoomMember = null
    showTip = false

    // beforeAppo: Appointment = null;
    Appointment = Appointment

    appoStatus = Appointment.typeNew
    doesChangeAppo: boolean
    notice = Notice
    Util = Util
    UserPermissionsOption = UserPermissionsOption
    CalendarUtil = CalendarUtil

    buttonArray = [
        { name: `調整可能時間・期間`, editType: `appo`, image_url: `/assets/icons/watch2.png` },
        { name: `場所`, editType: `locations`, image_url: `/assets/icons/mappin.png` },
        { name: `ヘッダー画像`, editType: `image`, image_url: `/assets/icons/select_images.png` },
        { name: `ヘッダーテキスト`, editType: `headerContent`, image_url: `/assets/icons/field_text_head.png` },
    ]

    // その他設定
    otherSettingsArray = [
        { name: `メール`, editType: `mail_flow`, image_url: `/assets/home/mail_icon.png` },
        { name: `フォーム`, editType: `form`, image_url: `/assets/icons/form_icon.png` },
        { name: `その他設定`, editType: `settings`, image_url: `/assets/icons/user_settings.png` },
    ]

    // 調整カレンダー系
    calendarsArray = [
        { name: `調整カレンダーを新規作成`, editType: `new` },
        { name: `調整カレンダーを交換`, editType: `astag` },
        // { name: `調整カレンダーを編集`, editType: `editAstag` },
    ]

    openPanel() {}

    // スグINボタンテキスト変更.
    clickInstantRoomTextSetting() {
        if (this.selectedTabName != `room`) {
            // this.selectTab(`room`)
            this.rcm.currentTab = `room`
        }
        Logger(`${funcName()} `)
        sleep(100).then(_ => {
            this.editEditableContent(`instant_room_text`)
        })
    }

    clickLi(dic: any) {
        if (this.selectedTabName != `room`) {
            // this.selectTab(`room`)
            this.rcm.currentTab = `room`
        }
        Logger(`${funcName()} dic:${Util.output(dic)}`)
        sleep(100).then(_ => {
            this.editEditableContent(dic.editType)
        })
    }

    clickSettingsLi(dic: any) {
        Logger(`${funcName()} selectedTabName:${this.selectedTabName} dic:${Util.output(dic)}`)
        // if (![`public_settings`, `parent_settings`].includes(this.selectedTabName)) {

        // }

        // if (this.rcm.room.room_type == `public_room`) {
        //     this.selectTab(`public_settings`)
        // } else {
        //     this.selectTab(`parent_settings`)
        // }

        // if (dic.editType == `buffer`) {
        //     this.rcm.currentTab = `schedule`
        // } else {
        //     this.rcm.currentTab = `public`
        // }
        if (dic.editType == `mail_flow`) {
            this.rcm.currentTab = `mail_flow`
        } else if (dic.editType == `form`) {
            this.rcm.currentTab = `form`
        } else if (dic.editType == `settings`) {
            this.rcm.currentTab = `public`
        }

        // this.editEditableContent(dic.editType)
    }

    clickEditAstag(dic: any) {
        if (dic.editType == `astag`) {
            this.clickLi(dic)
            return
        }
        if (dic.editType == `new`) {
            this.clickNewAstag()
            return
        }
        Logger(`${funcName()} 調整カレンダーの編集 を押しました.`)

        this.rm.getAstagDetail(this.rcm.astag.id).then(res => {
            if (res) {
                this.rcm.astag = res
                this.cm.setNew(this.rm.userInfo, this.rm.user_setting, this.rcm.astag, this.rcm.room_setting, true, this.rcm.appo)
                if (this.cm.astag) {
                    if (this.rm.didConnectCal) {
                        // カレンダーリストを取得.
                        Logger(`リモートからカレンダーリストを取得します。`)

                        this.cm.getCalList().then(res => {
                            // this.PreviewContent.getEvents()

                            this.getMagsEventsAndPossibleDates()
                        })
                    } else {
                        this.getMagsEventsAndPossibleDates()
                    }
                }
                $(".leftNavPanel").removeClass("open")
                this.rcm.edittingAstag = this.rcm.astag
                this.rcm.hasUnsaved = true
            }
        })
    }

    getMagsEventsAndPossibleDates() {
        Logger(`${funcName()} `)
        // magごとにイベントを取得します.
        this.cm.updateMagName(`A`)
        // if (this.cm.createType == `vote` && !this.cm.astag.can_connect_calendar) {
        //     this.cm.pdm = PossibleDatesManager
        //     let today = DateTime.local()
        //     let nextMonth = today.plus({ month: 1 })
        //     this.cm.pdm.available_months = [today.toFormat(`MMdd`), nextMonth.toFormat(`MMdd`)]
        //     return
        // }
        let selectedMagName = this.cm.displayMagName
        // 表示を初期値に戻す.
        this.cm.getMagEvents(null, selectedMagName).then(e => {
            // if (this.cm.createType == `vote` || this.cm.astag.is_only_use_optional_schedules) {
            //     this.cm.gettingEvents = false
            //     return
            // }
            this.cm.getPossibleDatesEvents()
        })
    }

    goHelpCenter() {
        window.open(`https://help.waaq.jp/page_create/5487/`)
    }
}
