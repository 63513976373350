
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import qs from "qs"
import { Logger, copyToClipboard, funcName } from "packs/common"
import Util from "packs/utils/Util"
import NotificationControl from "packs/utils/NotificationControl"
import { truncate } from "packs/models/TruncateUtil"
import Const from "packs/utils/Const"
import { DateTime } from "luxon"

// コンポーネントを読込.
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import IconStar from "packs/components/icons/IconStar.vue"
import PublicRoomStatusMenu from "packs/pages/link/parts/public_rooms/PublicRoomStatusMenu.vue"
import RoomCellEditMenu from "packs/pages/link/parts/RoomCellEditMenu.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import CopyButton from "packs/components/buttons/CopyButton.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import RoomTab from "packs/models/RoomTab"
import Notice from "packs/models/Notice"
import RoomCreateManager from "packs/models/RoomCreateManager"
import Appointment from "packs/models/Appointment"
import AppealContent from "packs/models/AppealContent"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

const roomStorage = new RoomStorage()

@Options({
    components: {
        AlertBadge,
        IconStar,
        PublicRoomStatusMenu,
        RoomCellEditMenu,
        UserIcon,
        CalendarIcon,
        CopyButton,
    },
})
export default class ParentRoomGroupCell extends Vue {
    @Prop()
    parg: ParentRoomGroup

    @Prop()
    room: Room

    @Prop()
    showRoomModal //モーダルを表示するfunction

    @Prop() // func
    showInstructionDeleteModal

    @Prop()
    canDelete: boolean

    @Prop()
    isLastCell: boolean

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone
    selectedRoom = this.rm.currentRoom
    roomKeyId = null
    appo: Appointment = null
    apc: AppealContent = null
    // companyName = null;
    displayMember: RoomMember
    showTip = false
    showAppoTip = false
    showUrlTip = false
    Room = Room
    ParentRoomGroup = ParentRoomGroup
    Util = Util
    DateTime = DateTime
    title = ``
    truncate = truncate

    currentMemIndex = null
    isHoverPrivateMemo = false
    // members_with_links: RoomMember[] = [];
    // selectedTabStatus = this.roomStatusesHash.categoryProgress;

    mounted() {
        this.getRoomTitle()
    }

    bgColor(): string {
        if ([Room.STATUS_ACTIVE_UPCOMING_FIXED, Room.STATUS_ACTIVE_PAST_FIXED].includes(this.parg?.status)) {
            return `lgreenBg`
        }
        return this.isSP ? `skyblueBg` : `lskyblueBg`
    }

    get votedBgColor() {
        if (!this.parg) return "333"
        let appo = this.parg.current_appointment
        if (!this.appo || !this.appo.id) return "666"

        let colorStr = ""
        let hash = 0
        let appoId = appo.id

        for (let i = 0; i < appoId.length; i++) {
            hash = appoId.charCodeAt(i) + ((hash << 5) - hash)
        }
        let colour = ``
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xff
            colour += ("00" + value.toString(16)).substr(-2)
        }
        return colour
    }

    getRoomTitle() {
        if (!this.parg) return

        this.appo = this.parg.current_appointment
        this.title = this.parg.title
    }

    openPargProgress() {
        this.$emit("openPargProgress", this.parg)
        // return room
    }

    // @Emit("closeRoom")
    showCloseRoomModal() {
        // return room
        this.showRoomModal(this.parg, "delete")
    }

    public copyPublicId() {
        $(this).select()
        copyToClipboard(this.parg.public_id)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`publicIDをコピー`, `rid:${this.parg.id}`)
    }

    public copyAppoText() {
        $(this).select()
        copyToClipboard(this.room.current_appointment.jp_format)
        this.showAppoTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`確定したアポ時間をコピー`, `rid:${this.room.id}`)
    }

    public copyPageUrl() {
        $(this).select()
        copyToClipboard(this.room.page_url)
        this.showUrlTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`公開ページのURLをコピー`, `rid:${this.room.id}`)
    }

    public hideTooltip(e) {
        this.showTip = false
        this.showAppoTip = false
        this.showUrlTip = false
    }

    public roomLabels(): string {
        if (Room.isParentFixed(this.parg.status)) {
            return "label label-green"
        } else {
            return "fw600 ddgrey"
        }
    }

    get roomLabelName() {
        if (Util.isBlank(this.parg)) return ``

        if (this.parg.status == Room.STATUS_ACTIVE_IN_PROGRESS) {
            return `調整中`
        }
        let statusName = this.parg.status_name
        if ([Room.STATUS_ACTIVE_PAST_FIXED, Room.STATUS_CLOSED_PAST_FIXED].includes(this.parg.status)) {
            statusName = `${statusName}(終了)`
        }

        return statusName
    }

    public copyPage() {
        // 複製してセッティングページに遷移します。
        Logger(`copyPage`)
        gtagClick(`ルーム複製`, `pid:${this.room.id} ${this.room.members.length}人`)
        this.goRoomSettings(`copy`)
    }

    // action: copy / edit
    public goRoomSettings(action: string) {
        let appo = this.room.current_appointment
        Logger(`${funcName()} action:${action} is_owner:${this.room.is_owner}, is_owner_ug:${this.room.is_owner_group}`)
        roomStorage.saveAction(action)
        let mems = [...(this.room.members || [])]
        if (this.room.room_type == `public_room`) {
            mems = []
        }

        // 共有権限を持っているか、オーナーの場合は、astagを取得して、公開ページを複製します。
        if (this.room.is_owner) {
            Logger(`${funcName()} is_shared_chukai:${this.room.is_shared_chukai}`)

            this.rm.getAstagDetail(this.room.owner_avaialble_schedule_tag_id).then(res => {
                if (res) {
                    let astag = res
                    this.rcm.setNew(this.room, appo, mems, astag, this.rm.userInfo, this.rm.user_setting, action)
                    roomStorage.saveRoomPreview(this.room)
                    roomStorage.saveAppointmentPreview(appo)

                    this.$router.push("/room_settings")
                }
            })
            return
        }

        // 自分のデフォルトのastagを利用して、公開ページを複製します。
        let astag = this.rm.astag
        if (astag.is_shared) {
            astag = this.rm.astags.find(ast => !ast.is_shared)
        }
        if (!astag) {
            Notice.message = `作成した調整カレンダーが見つかりませんでした`
            return
        }

        this.rcm.setNew(this.room, appo, mems, astag, this.rm.userInfo, this.rm.user_setting, action)
        roomStorage.saveRoomPreview(this.room)
        roomStorage.saveAppointmentPreview(appo)

        this.$router.push("/room_settings")
    }

    // モーダル表示ボタンの表示/非表示
    public showHideCloseRoomModalButton(): boolean {
        const role = this.selectedRoom ? this.selectedRoom.role : 0 || 0
        if (
            this.selectedRoom &&
            this.selectedRoom.isParent &&
            Room.isParentActiveWithoutCanceled(this.selectedRoom.status) &&
            role == 1
        ) {
            return true
        } else {
            return false
        }
    }

    public fadeOutCloseRoom() {
        if (!this.rm.currentRoom && this.rm.openRooms) {
            this.rm.updateCurrentInfo(this.rm.openRooms[0])
        }
    }

    public selectCell(room) {
        $("tr").removeClass("selected")

        const roomKey = room.keyId
        $(`.${roomKey}`).addClass("selected")

        // 最後に参照したルームIDを保存しておきます。
        this.roomKeyId = roomKey
        Logger(`room key: ${this.roomKeyId} id: ${room.id}`)
        roomStorage.saveSelectedRoomKey(this.roomKeyId)

        // current系を最新の情報にアップデートします。
        RoomManager.updateCurrentInfo(room)
    }

    public pushStar() {
        let action = ``
        if (this.room.starred) {
            // はずす
            action = `remove`
        } else {
            // スター
            action = `add`
        }
        gtagClick(`fav room`, `${action}`)
        this.rm.startProgress()
        Room.saveFav(this.room.id, action, this.room.room_type).then(e => {
            this.rm.endProgress()
            if (!e) return
            if (action == `add`) {
                this.room.starred = true
                let favtab: RoomTab = this.rm.roomTabs[`all`][`favorite`]
                if (favtab && favtab.rooms) {
                    favtab.rooms.push(this.room)
                }
            } else {
                this.room.starred = false
                let favtab: RoomTab = this.rm.roomTabs[`all`][`favorite`]
                if (favtab && favtab.rooms) {
                    favtab.rooms = favtab.rooms.filter(r => r.id != this.room.id)
                }
            }
            this.rm.updateFav(this.room)
        })
    }

    /**
     * 公開ページの公開ステータスを変更します.
     * @param status [string] 変更したいステータス = open, suspend, delete
     */
    public changeStatus(status: string) {
        Logger(`${funcName()} status:${this.parg.status}, name:${this.parg.status_name}  -> ${status}`)
        //
        if (status == `delete`) {
            if ([`suspended`, `draft`].includes(this.parg.status_name)) {
                this.showRoomModal(this.parg, "delete")
            } else {
                // 削除するためには停止中に変更してください。のモーダルを表示します.
                this.showInstructionDeleteModal()
            }
        } else {
            ParentRoomGroup.changeStatus(this.parg, status).then(isSuccess => {
                if (isSuccess) {
                    this.parg.status_name = {
                        draft: `下書き`,
                        publishing: `公開中`,
                        suspended: `公開停止中`,
                        hidden: `削除済み`,
                    }[status]
                    this.room.public_status = status
                }
            })
        }
    }

    get hasImage(): boolean {
        if (!this.apc) return false
        return this.apc.image_url ? true : false
    }

    // 投票形式で確定した予定をキャンセルします。（モーダルを表示）
    cancelVoted(room: Room) {
        this.$emit(`cancelVoted`, room)
    }

    cancelAppo(room: Room) {
        Logger(`${funcName()} room:${room.id}`)
        this.$emit(`cancelAppo`, room)
    }

    createFeedback(room: Room) {
        this.$emit(`createFeedback`, room)
    }

    clickEditMenu() {
        Logger(`${funcName()} isLastCell:${this.isLastCell}`)
        gtagClick(`roomCell`, `EditMenu: isLast:${this.isLastCell}`)
        if (this.isLastCell) {
            // 一番したのセルだったら場所をずらします.
            $(`#roomEditMenu ul.dropdown-menu`).css({ "margin-top": "-110px" })
        } else {
            $(`#roomEditMenu ul.dropdown-menu`).css({ "margin-top": "auto" })
        }
    }

    // 終了したページを戻します.
    changeActive(room: Room) {
        this.$emit(`changeActive`, room)
    }

    get selectedMagTeamName() {
        if (!this.appo || !this.appo.selected_mag_dic) return
        let dic = Object.values(this.appo.selected_mag_dic)[0]
        if (!dic) return null
        // team_nameは自分で設定した名前、なければ name: A, Bで選択されたものを表示.
        let name = dic.team_name || dic.name
        return name || null
    }

    searchByMember(mem: RoomMember) {
        gtagClick(`セル検索 メンバー`, `${mem.user_id}`)
        this.$emit(`searchByMember`, mem)
    }

    mouseoverMember(mem: RoomMember, memindex: number) {
        Logger(`mouseoverMember: mem: ${mem.email}, memindex: ${memindex}`)
        if (Const.chukaiFreePlan(this.rm)) return
        this.currentMemIndex = memindex
    }

    mouseleaveMember() {
        this.currentMemIndex = null
    }

    searchByMag() {
        let mag: MeetingAttendeesGroup = Object.values(this.appo.selected_mag_dic)[0]
        gtagClick(`セル検索 担当`, `${mag.team_name}`)
        this.$emit(`searchByMag`, mag)
    }

    clickFormMember(mem: RoomMember) {
        // フォーム確認
        this.$emit(`clickFormMember`, mem, this.room)
    }

    editPrivateMemo() {
        this.$emit(`editPrivateMemo`, this.room)
    }

    mouseoverPrivateMemo() {
        this.isHoverPrivateMemo = true
    }

    mouseleavePrivateMemo() {
        this.isHoverPrivateMemo = false
    }

    showShareAuthModal(room: Room) {
        // オーナー権限を付与するモーダルを表示.
        this.$emit(`showShareAuthModal`, room)
    }

    get boardUrl() {
        if (!this.room) return
        let url = this.room.page_url
        return (url || ``).replace(`/p/`, `/p/board/`)
    }

    copyBoardUrl() {
        $(this).select()
        copyToClipboard(this.boardUrl)
        this.showUrlTip = true
        setTimeout(this.hideTooltip, 2000)
    }
}
