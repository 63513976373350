import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger, onlyUnique, funcName } from "packs/common"

import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import GroupTag from "packs/models/GroupTag"
import ParentRoomGroup from "packs/models/ParentRoomGroup"

export default class RoomTab {
    constructor(
        public rooms: Room[],
        public statuses: number[],
        public tab_name: string,
        public last_ids: string[], // 最後のparent_room_id
        public last_search_at: number,
        public has_next: boolean,
        public total: number,
        public next_page: number,
        public user_filter: string,

        // 公開ページの詳細で利用
        public page: number,
        public total_page: number,

        // 調整ページの詳細で利用
        public pargs: ParentRoomGroup[],

        //
        public groupTag: GroupTag
    ) {}

    // 表示タブ情報
    static readonly DISPLAY_TAB_NAMES = [
        `public`,
        // `all`,
        `active_in_progress`,
        `upcoming_fixed`,
        `past_fixed`,
        `unread`,
        `favorite`,
        `closed`,
        `draft`,
        `shared`,
    ]

    static readonly PARENT_ROOM_TAB_NAMES = [`active_in_progress`, `upcoming_fixed`, `past_fixed`, `unread`, `closed`, `draft`]

    static copy(t: RoomTab): RoomTab {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(t)), t)
        return clone
    }

    /**
     *
     * @param filterName [string] all / self
     * @param tabName [string] all / active_in_progress / upcoming_fixed / past_fixed / closed / unread / favorite
     */
    static createDefault(filterName: string, tabName: string) {
        // タブネーム毎の表示対象のルームステータスの定義
        const statuses_tab_name_dic = {
            // all: Room.ALL_STATUSES,
            favorite: Room.ALL_STATUSES,
            active_in_progress: [Room.STATUS_ACTIVE_IN_PROGRESS],
            upcoming_fixed: [Room.STATUS_ACTIVE_UPCOMING_FIXED],
            past_fixed: [Room.STATUS_ACTIVE_PAST_FIXED],
            closed: [
                Room.STATUS_CLOSED_NOT_FIX,
                Room.STATUS_CLOSED_UPCOMING_FIXED,
                Room.STATUS_CLOSED_PAST_FIXED,
                Room.STATUS_CLOSED_CANCELED,
                Room.STATUS_CLOSED_OTHER,
            ],
            unread: Room.ALL_STATUSES,
        }
        return new RoomTab(
            null,
            statuses_tab_name_dic[tabName],
            tabName,
            null,
            null,
            true,
            -1,
            1,
            filterName,
            null,
            null,
            null,
            null
        )
    }

    // ルームステータスに応じて更新対象のタブを定義します。
    static select_updating_tab_names_by_status(status): string[] {
        switch (status) {
            case Room.STATUS_ACTIVE_IN_PROGRESS:
                return [`active_in_progress`]
            case Room.STATUS_ACTIVE_UPCOMING_FIXED:
                return [`upcoming_fixed`]
            case Room.STATUS_ACTIVE_PAST_FIXED:
                return [`past_fixed`]
            case Room.STATUS_ACTIVE_CANCELED:
                return [`active_in_progress`]
            case Room.STATUS_ACTIVE_OTHER:
                return []
            case Room.STATUS_CLOSED_NOT_FIX:
                return [`closed`]
            case Room.STATUS_CLOSED_UPCOMING_FIXED:
                return [`closed`]
            case Room.STATUS_CLOSED_PAST_FIXED:
                return [`closed`]
            case Room.STATUS_CLOSED_CANCELED:
                return [`closed`]
            case Room.STATUS_CLOSED_OTHER:
                return []
            default:
                return []
        }
    }

    /**
     * self,allのどちらのスイッチにあるタブもすべて一度に作成します。
     * tabs = {all: , self: {all:, active:, active_in_progress: ,...}}
     */
    static createAllTabs(customTabGroups: GroupTag[] = null): any {
        const tabNames = RoomTab.DISPLAY_TAB_NAMES
        const switchNames = [`self`, `all`]
        let tabs = {}
        for (let filterName of switchNames) {
            if (!tabs[filterName]) tabs[filterName] = {}

            for (let tabName of tabNames) {
                tabs[filterName][tabName] = this.createDefault(filterName, tabName)
            }
        }

        // タブに表示するグループタブ.
        if (Util.isPresent(customTabGroups)) {
            tabs = this.mergeGroupTags(tabs, customTabGroups)
        }
        return tabs
    }

    static mergeGroupTags(tabsDic, customTabGroups: GroupTag[] = null) {
        if (Util.isBlank(customTabGroups)) return tabsDic

        for (let grTag of customTabGroups) {
            let rtab = new RoomTab(null, null, `group_tag`, null, null, true, -1, 1, `all`, null, null, null, grTag)
            tabsDic[`all`][grTag.id] = rtab
        }

        return tabsDic
    }

    /**
     * リモートから取得してきたデータをタブにセットします。
     * @param tab 選択しているタブ.
     * @param data リモートからもらってきたデータ.
     * @param userInfo 自分のユーザー情報
     */
    static updateCurrentTab(tab: RoomTab, data: any, userInfo): RoomTab {
        const roomsData = data.rooms
        const rooms = Room.fetchFromJson(roomsData, userInfo.user_id)

        // 既存のタブにあるルームと、取得してきたルームをマージします。
        tab.rooms = Room.mergeRooms(tab.rooms, rooms, this.getSortFuncByTabName(data.tab_name))
        tab.has_next = data.has_next
        tab.total = data.total
        tab.last_search_at = data.last_search_at
        let beforeLastIds = tab.last_ids || []
        let newLastIds = data.last_ids || []
        Array.prototype.push.apply(beforeLastIds, newLastIds)
        tab.last_ids = beforeLastIds
        tab.tab_name = data.tab_name
        tab.next_page = data.next_page
        Logger(`RoomTab.updateCurrentTab 選択しているタブ情報を更新しました: ${Util.output(tab)}`)
        return tab
    }

    /**
     * 選択しているタブから、リモートから取得するためのパラメータを作成します。
     * @param tab [RoomTab]
     */
    static getCurrentTabParams(tab: RoomTab) {
        let params = { tab: tab.tab_name, user_filter: tab.user_filter }
        if (tab.last_ids) {
            params[`last_ids`] = tab.last_ids
        }
        if (tab.last_search_at) {
            params[`last_search_at`] = tab.last_search_at
        }
        if (tab.next_page) {
            params[`page`] = tab.next_page
        }
        if (Util.isPresent(tab.groupTag)) {
            let grTag = tab.groupTag
            params[`group_tag_id`] = grTag.id
        }
        return params
    }

    // デフォルト(更新日時 降順)のソート関数を返却します。
    static defaultSortFunc() {
        // Logger(`RoomTab.defaultSortFunc`)
        return (a: Room, b: Room) => {
            const a_at = a.updated_at || 0
            const b_at = b.updated_at || 0
            return b_at - a_at
        }
    }

    // tabName に対応するソート関数を返却します。
    static getSortFuncByTabName(tabName: string) {
        // アポ終了日時 昇順ソート
        const sortByEndTimeAsc = (a: Room, b: Room) => {
            const a_at = a.current_appointment.start_time || 0
            const b_at = b.current_appointment.start_time || 0
            return a_at - b_at
        }
        // アポ終了日時 降順ソート
        const sortByEndTimeDesc = (a: Room, b: Room) => {
            const a_at = a.current_appointment.end_time || 0
            const b_at = b.current_appointment.end_time || 0
            return b_at - a_at
        }
        switch (tabName) {
            case "upcoming_fixed":
                return sortByEndTimeAsc
            case "past_fixed":
                return sortByEndTimeDesc
            default:
                return this.defaultSortFunc()
        }
    }

    static sortRoomsWithTabName(rooms: Room[], tabname: string) {
        rooms = [...(rooms || [])]
        Logger(`${funcName()} tabname:${tabname} length:${rooms.length}`)
        let sortFunc = this.getSortFuncByTabName(tabname)
        let retRooms = rooms.sort(sortFunc || RoomTab.defaultSortFunc())
        return retRooms
    }

    /**
     * 取得してきたroomsをステータスに従ってそれぞれのタブに良しなに振り分けます。
     * favとunreadとdraftは別
     *
     * @param roomTabs {all: , self: {all:, active_in_progress:, ...}}
     * @param rooms [Room[]]
     * @param userInfo [RoomMember] 自分のuser
     * @return tabs {all: , self: {all:, active_in_progress:, ...}}
     */
    static sortAutomatically(roomTabs: any, rooms: Room[], userInfo: RoomMember = null): any {
        let tabs = this.createAllTabs()
        if (Util.isBlank(roomTabs)) roomTabs = this.createAllTabs()
        for (let _room of rooms) {
            if (Room.isParentDraft(_room.status)) continue
            let tabNames = this.select_updating_tab_names_by_status(_room.status)

            // 自分 x すべて のタブを更新（Allタブ廃止のため.）
            // if (_room.is_owner) {
            //     let tab: RoomTab = tabs[`self`][`all`] || {}
            //     let beforeTab = roomTabs[`self`][`all`] || {}
            //     tab.rooms = Room.mergeRooms(tab.rooms || [], [_room])
            //     tab = this.copyInfo(tab, beforeTab)
            // }

            // 未読 タブを更新
            if (_room.unread_num > 0) {
                let tab: RoomTab = tabs[`all`][`unread`] || {}
                let beforeTab = roomTabs[`all`][`unread`] || {}
                tab.rooms = Room.mergeRooms(tab.rooms || [], [_room])
                tab = this.copyInfo(tab, beforeTab)
            }

            // ステータスに紐づく関連タブを更新
            for (let tabName of tabNames) {
                // ステータスに応じてSort関数を設定
                let sortFunc = this.getSortFuncByTabName(tabName)
                // オーナーと、自分のctagが含まれる担当者もアップデートします.
                let appo = _room.current_appointment
                let mag = appo && appo.selectedMags ? appo.selectedMags[0] : null
                let assignedUser = mag ? mag.instant_room_assigned_user : null
                let isMyMag = assignedUser ? assignedUser.user.id == userInfo.user_id : false
                if (_room.is_owner || isMyMag) {
                    let tab: RoomTab = tabs[`self`][tabName] || {}
                    let beforeTab = roomTabs[`self`][tabName] || {}
                    tab.rooms = Room.mergeRooms(tab.rooms || [], [_room], sortFunc)
                    tab = this.copyInfo(tab, beforeTab)
                }
                let tab: RoomTab = tabs[`all`][tabName] || {}
                let beforeTab = roomTabs[`all`][tabName] || {}
                tab.rooms = Room.mergeRooms(tab.rooms || [], [_room], sortFunc)
                tab = this.copyInfo(tab, beforeTab)
            }

            // お気に入り タブを更新
            if (_room.starred) {
                let tab: RoomTab = tabs[`all`].favorite || {}
                let beforeTab = roomTabs[`all`].favorite || {}
                tab.rooms = Room.mergeRooms(tab.rooms || [], [_room])
                tab = this.copyInfo(tab, beforeTab)
            }
        }
        // UG x すべて タブを更新（Allタブ廃止のため.）
        // let tab: RoomTab = tabs[`all`][`all`] || {}
        // let beforeTab = roomTabs[`all`][`all`] || {}
        // tab.rooms = Room.mergeRooms(tab.rooms || [], rooms)
        // tab = this.copyInfo(tab, beforeTab)
        return tabs
    }

    /**
     * ルーム以外のタブ情報を引き継ぎます。
     */
    static copyInfo(tab, beforeTab): RoomTab {
        tab.has_next = beforeTab.has_next
        tab.total = beforeTab.total
        tab.last_search_at = beforeTab.last_search_at
        tab.last_ids = beforeTab.last_ids
        return tab
    }
}
